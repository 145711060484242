import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Tippy from "@tippyjs/react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
const Addreview = (props) => {
  const [ratings, setRatings] = useState({});
  const [id, setID] = useState([]);
  const ref = useRef(null);
  const refClose = useRef(null);
  const [sending, setSending] = useState(false);
  const [address, setAddress] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setstate] = useState([]);
  const [CaptchaDone, setCaptchaDone] = useState(false);
  const [files, setFiles] = useState([]);

  let navigate = useNavigate();

  const [credentials, setCredentials] = useState({ name: "", email: "" });
  const [form_credentials, setform_credentials] = useState({ text_review: "" });
  const [recommendation, setRecommendation] = useState("");
  const [New, setNew] = useState(false);
  const captcha_key=process.env.REACT_APP_CAPTCHA_KEY


  const [thumbnails, setThumbnails] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const remainingSpace = 4 - files.length;

    // Check if the number of selected files exceeds the remaining space
    if (selectedFiles.length > remainingSpace) {
      props.showAlert(`You can only upload maximum 4 files.`, "error");
      return;
    }

    // Convert FileList to array and update state
    const newFiles = Array.from(selectedFiles);
    setFiles([...files, ...newFiles]);

    // Read each selected file and generate thumbnails
    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnails((prevThumbnails) => [...prevThumbnails, e.target.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCancel = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const updatedThumbnails = [...thumbnails];
    updatedThumbnails.splice(index, 1);
    setThumbnails(updatedThumbnails);
  };

  const fileInputRef = useRef(null);

 

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleRecommendationChange = (event) => {
    setRecommendation(event.target.value);
  };

  const [reviewCategories, setReviewCategories] = useState([]);
  const [hoverRating, setHoverRating] = useState({});
  const popup = () => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/add_review");
        } 
      }); 
    } else {
      ref.current.click();
    }
  };

  let property_id=null;
  useEffect(() => {
    // if(localStorage.getItem('token')){
    //   console.log(333)
    //   props.fetchNotifications();
    //   //it
    //   }
    console.log(333)
    const retrieveStoredData = () => {
      const storedData = localStorage.getItem("review_data");

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const firstDataItem = parsedData.data[0];

        setID(firstDataItem.ID);
        setAddress(firstDataItem.Address);
        setCity(firstDataItem.CNAME);
        setstate(firstDataItem.SNAME);
        property_id=firstDataItem.ID
      }
    };
    const fetchPropertyReviewsLength = async () => {
      
      try {
       
        const response = await fetch(
          `${props.API_URL}/api/search/property_reviews_length/${property_id}`
        );
        const data = await response.json();
        const results = data.data;
        if (results=== 0) {
          setNew(true);
        }
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
      }
    };
  



    const fetchReviewCategories = async () => {
      try {
        const response = await fetch(
          `${props.API_URL}/api/form/review_cfg`
        );
        const data = await response.json();
        setReviewCategories(data.data);
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
      }
    };

    retrieveStoredData();
    fetchPropertyReviewsLength();
    fetchReviewCategories();
    // eslint-disable-next-line
  }, []);
     



  const handleSubmit = async (e) => {
    e.preventDefault();

    setSending(true);

    try {
      const response = await fetch(`${props.API_URL}/api/contact/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
        }),
      });

      const json = await response.json();
      setSending(false);

      if (json.status === "success") {
        props.showAlert(json.message, json.status);
      } else {
        props.showAlert(json.message, json.status);
      }
    } catch (error) {
      console.error("Internal Server Error", "error");
      setSending(false);
    }
    refClose.current.click();

  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleStarClick = (rating, category) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: rating,
    }));
  };

  const handleStarMouseEnter = (rating, category) => {
    setHoverRating((prevHoverRating) => ({
      ...prevHoverRating,
      [category]: rating,
    }));
  };

  const handleStarMouseLeave = (category) => {
    setHoverRating((prevHoverRating) => ({
      ...prevHoverRating,
      [category]: 0,
    }));
  };

  const handleCaptchaVerify = (response) => {
    setCaptchaDone(true);
  };

  const handleform = async (e) => {
    e.preventDefault();
    let selectedFiles = [];

    if (files) {
      try {
        for (const file of files) {
        const { url } = await fetch(
          `${props.API_URL}/AddReview/s3Url`
        ).then((res) => res.json());

        // Post the image directly to the S3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });

        const imageUrl = url.split("?")[0];
        selectedFiles.push(imageUrl);
      }} catch (error) {
        console.error("Error fetching S3 URL:", error);
      }
    }

   

    const reviewData = {};

    for (const category of reviewCategories) {
      const rating = ratings[category.title];
      console.log(rating);

      if (rating === null || rating === undefined) {
        props.showAlert("Please fill all fields", "warning");
        return;
      }

      reviewData[category.title.toLowerCase()] = rating;
    }

    reviewData.recommend = recommendation;
    reviewData.text_review = form_credentials.text_review;
    reviewData.images = selectedFiles;

    setSending(true);

    const apiEndpoint = localStorage.getItem("token")
      ? `${props.API_URL}/api/add_review/add_review/${id}`
      : `${props.API_URL}/api/add_review/add_review/unauthenticated/${id}`;

    const headers = {
      "Content-Type": "application/json",
    };

    if (localStorage.getItem("token")) {
      headers["auth-token"] = localStorage.getItem("token");
    }

    try {
      const data = await fetch(apiEndpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(reviewData),
      });

      const json = await data.json();
      setSending(false);

      if (json.status === "success") {
        navigate("/thanks");
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      setSending(false);
      props.showAlert("Internal Server Error", "error");
    }
  };

  const formonChange = (e) => {
    setform_credentials({
      ...form_credentials,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Add Review' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="pb-5 pt-3">
        <div className=" search_results_top_div ml-1 mr-1  " style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="row pl-4 pr-2">
            <div className=" col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9 mt-4 mb-2">
              <h4 className="search_results pt-2">Add Review</h4>
            </div>
            <div className=" col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mt-2 mb-2">
              <img src="/congrats.png" width={"100%"} alt="" />
            </div>
          </div>
          <div className="row pl-4 pr-2" style={{marginTop:"-6px"}}>
            <div className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-1 mb-2">
              {New ?(
                <div>
                  <p className="congrats" style={{marginTop:"-6px"}}>Congrats!</p>
                  <p className="add_review_intro">
                    Since there are no reviews yet for this property, you will
                    be the first! Woo-hoo! Ratings form can be found below.
                  </p>
                </div>
              )
            :
            (
              <div>
                <p className="add_review_intro">Ready to share your experience? Your review will help others make informed decisions about their housing. Review form can be found below!</p>
              </div>
            )}
            </div>
          </div>
        </div>
        
      </div>
      <form onSubmit={handleform}>
        <div className="anonymous_review_div  mt-3">
          <div className="row ml-2 mb-0 ">
            <div className="col-lg-9 col-md-9  col-11 mt-lg-5 mt-md-5 ">
              <h6 className="add_review_heading">
                {localStorage.getItem("token")
                  ? ""
                  : "You are anonymously reviewing:"}
              </h6>
              <p className="review_address">
                {address}
                <br />
                {city}, {" "} {state}
                
              </p>
              <p className="know_someone">
                Know someone who lives here or wants to share something about
                this property? <b onClick={popup} style={{color:"#1E81C4",cursor:"pointer",textDecoration:"underline"}}>Invite them to ManorLane</b> 
              </p>
            </div>


            <div className="col-lg-3 col-md-3 d-lg-block d-md-block d-none" > 
              <img
                src="/high_five.png"
                className="img-fluid"
                width={"120px"}
                alt=""
                style={{marginTop:"90px"}}
              />
            </div>
          </div>

        
          <br />
          <div className="add_review_div2 ml-2 mr-2">
          

            <div className="row ml-lg-2 mr-2" id="anonymous_review">

              <div className="col-lg-4 col-md-4 d-lg-block d-md-block d-none col-12 ml-lg-2 mr-lg-2 ml-1 mr-1 pt-3">
                <img
                  src="/talk.png"
                  className="img-fluid pl-lg-0 pl-md-0 pl-2 pt-4"
                  style={{ marginTop: "-20px" }}
                  alt=""
                />
              </div>
              <div
                className="col-lg-7 col-md-7 col-12 ml-2 pb-2 "
                id="review_note"
              >
                <h4 className="rate_property pt-2 pb-lg-2 pb-md-2 mt-4" >Rate this Property</h4> 
                Note this is a forum for information in all it’s forms, so
                praise and constructive feedback are equally welcomed.
              </div>
            </div>
            <hr style={{ marginTop: "-20px" }} />
              {localStorage.getItem("token") ? "":(
          <p id="review_note" className="text-center px-2">If you'd like to track and save your reviews, {" "}
          <Link to="/login" className="login_here fw-bold" style={{textDecoration:"underline"}}>
          login in here
          </Link>
            .</p>
              )}
           
            {(reviewCategories && reviewCategories.length>0) && reviewCategories.map((category, index) => (
              <div key={index} className="row ml-2 mr-2" id="anonymous_review">
                <div
                  className={`d-flex col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 mt-2`}
                >
                  <h6 className="ml-3 mt-2" id="reviews_heading">
                    {category?.title}
                  </h6>
                  <div className="tooltip_container d-lg-block d-md-block ">
                    <Tippy
                      interactive={true}
                      content={
                        <div id="tooltip">
                          <h6 >
                            {category?.tooltip}
                          </h6>
                        </div>
                      }
                    >
                      <img
                        src="info.png"
                        width="10px"
                        height="10px"
                        style={{ cursor: "pointer" }}
                        alt=""
                      />
                    </Tippy>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 ml-2 ml-lg-0 ml-md-0 mt-2 mb-3">
                  {[...Array(5)].map((star, index) => {
                    const currentRate = index + 1;

                    return (
                      <label key={currentRate}>
                        <input
                          
                          type="radio"
                          style={{ display: "none" }}
                          name={`${category.title.replace(/\s+/g, "_")}Rating`}
                          value={currentRate}
                          onClick={() =>
                            handleStarClick(currentRate, category?.title)
                          }
                        />
                        <FaStar
                          className="ml-2"
                          size={20}
                          color={
                            currentRate <=
                            (hoverRating[category?.title] ||
                              ratings[category?.title])
                              ? "rgb(247, 187, 38)"
                              : "lightgrey"
                          }
                          onMouseEnter={() =>
                            handleStarMouseEnter(currentRate, category?.title)
                          }
                          onMouseLeave={() =>
                            handleStarMouseLeave(category?.title)
                          }
                        />
                      </label>
                    );
                  })}
                </div>
              </div>
            ))}
            <hr
              style={{
                marginLeft: "70px",
                marginTop: "-12px",
                background: "rgba(0, 0, 0, 0.20)",
              }}
            />
            <div className="row ml-4 d-flex">
            {thumbnails.map((thumbnail, index) => (
        <div className="mb-2 mt-2" key={index} style={{maxWidth:"20%"}}>
          <img
            src={thumbnail}
            alt={`Thumbnail ${index}`}
            style={{ maxWidth: '80%', maxHeight: '100px', borderRadius: '10px', position: 'relative' }}
          />
          <button
            style={{
              position: 'absolute',
              width: '20px',
              border: 'none',
              fontSize: '25px',
              color: 'red',
              fontWeight: 'bold',
              marginTop: '-10px',
              backgroundColor: 'white',
              cursor: 'pointer'
            }}
            onClick={() => handleCancel(index)}
          >
            &times;
          </button>
        </div>
      ))}
            </div>
         

            <div className="row pl-5 pr-3" style={{width:"100%"}}>
              
              <div className="col-12  mt-1 mb-3 ">
              <input
                    className="custom-file-input"
                    id="validatedInputGroupCustomFile"
                    type="file"
                    multiple  // Allow multiple file selection
                    accept="image/*"  // Accept only image files
                    onChange={handleFileChange}
                    disabled={files.length === 4}
                    
                    style={{cursor:"pointer"}}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedInputGroupCustomFile"
                    style={{ fontSize: "14px" }}
                  >
                    {files.length < 4 ? 'Post a photo...' : 'Maximum photos uploaded'}
                  </label>
              </div>
            </div>

            <div className="row ml-3 mr-2" id="prefer_friend">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-2 col-12 mt-3 ">
                <p className=" recommend_friend ">
                  Would you like to recommend this property to your friend?
                </p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                <div className="d-flex">
                  <input
                    required
                    type="radio"
                    id="recommendYes"
                    name="recommend"
                    value="yes"
                    style={{ width: "15px" }}
                    onChange={handleRecommendationChange}
                    checked={recommendation === "yes"}
                  />
                  <label htmlFor="recommendYes" className="recommend ml-2 mt-2">
                    Yes
                  </label>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 recommend_div">
                <div className="d-flex">
                  <input
                    type="radio"
                    id="recommendNo"
                    name="recommend"
                    value="no"
                    style={{ width: "15px" }}
                    onChange={handleRecommendationChange}
                    checked={recommendation === "no"}
                  />
                  <label htmlFor="recommendNo" className="recommend ml-2 mt-2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 recommend_div">
                <div className="d-flex">
                  <input
                    type="radio"
                    id="recommendPreferNottoSay"
                    name="recommend"
                    value="prefer not to say"
                    style={{ width: "15px" }}
                    onChange={handleRecommendationChange}
                    checked={recommendation === "prefer not to say"}
                  />
                  <label
                    htmlFor="recommendPreferNottoSay"
                    className="recommend ml-2 mt-2"
                  >
                    Prefer Not to Say
                  </label>
                </div>
              </div>
            </div>
            <div className="row ml-2 mr-2" id="anonymous_review">
              <div className="col-12 ml-1 mt-3 mb-3">
                <p className="provide_review ">
                  Provide detailed review. This section should include any
                  details or narrative to describe your experiences. Please be
                  fair and honest in your review. <br />
                </p>
                <p className="website_hashtag">
                  <img src="/hashtag.svg" alt="" />
                  Our website accepts hashtags for search indexing, so feel free
                  to hashtag key terms. Eg, #noise, #sanfrancisco, #repairs,
                  #lease.
                </p>
                <textarea
                  className="review_textarea"
                  name="text_review"
                  rows={"4"}
                  type="text"
                  value={form_credentials.text_review} // Add this line to set the initial value
                  onChange={formonChange}
                  required
                  placeholder="Enter written review here"
                />{" "}
                <br />
                <p className="review_note ml-1">
                  Note: Any malicious or defamatory language may result in the
                  review being edited or removed. <br />
                </p>
              </div>
            </div>
          </div>
          <div className="row ml-2 
          " >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-3 pr-5  d-flex">
              <input
                required
                type="checkbox"
                width={"22px"}
                id="myCheckbox"
                className="custom-checkbox "
              />
              <p className="review_check  ml-2">
                I understand that my posting on ManorLane is public and may be
                viewed by my landlord, HOA or property management company
              </p>
            </div>
          </div>
          <div className="row ml-2 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 pr-5   d-flex">
              <input
                required
                type="checkbox"
                width={"22px"}
                id="myCheckbox"
                className="custom-checkbox "
              />
              <p className="review_check  ml-2">
                I agree to the <Link to="/terms_of_services">Terms of Service</Link> and{" "}
                <Link to="/privacy_policy">Privacy Policy</Link> and understand
                that ManorLane is not responsible for any consequences that
                occur as a result my review.
              </p>
            </div>
          </div>
          <div className="row ml-2 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex pr-5">
              <input
                required
                type="checkbox"
                width={"22px"}
                id="myCheckbox"
                className="custom-checkbox "
              />
              <p className="review_check   ml-2">
                I attest that I am or have been a resident of this property AND
                I am not a landlord, property owner or property manager
                of this address.
              </p>
            </div>
          </div>

          <div className="row ml-2 mr-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 ">
              <ReCAPTCHA
                sitekey={captcha_key}
                onChange={handleCaptchaVerify}
              />
            </div>
          </div>

          <div className="row ml-2 mr-2  " id="anonymous_review">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 "></div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12  mt-5 mb-5 d-flex ">
              <button
                className="review_reset_btn btn pl-lg-4 pr-lg-4 pl-md-4 pr-md-4"
                type="reset"
              >
                Reset
              </button>
              <button
                type="submit"
                className="review_submit_btn btn pl-lg-4 pr-lg-4 pl-md-4 pr-md-4"
                disabled={!CaptchaDone}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      <button
        type="button"
        ref={ref}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Invite Friend
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    minLength={3}
                    value={credentials.name}
                    onChange={onChange}
                    placeholder="Enter the name of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                    placeholder="Enter the email address of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refClose}
                className="btn cancel_button_popup"
                data-dismiss="modal"
                // disabled={sending}

              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn submit_button_popup"
                disabled={sending}
              >
                {sending ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addreview;

import React, { useState, useEffect } from 'react';
import TopBar from "./TopNavbar";
import { Link } from "react-router-dom";

function Preloader() {
  return (
  <>
  <div className='d-flex justify_content-center' style={{marginLeft:"45%"}}>
     <div className="spinner-border" role="status">
  <span className="sr-only"></span>
</div>
</div>
</>
  )


  
}
function FAQ(props) {
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(null);

  useEffect(() => {
    fetch(`${props.API_URL}/api/faq/faqs`)
      .then((response) => response.json())
      .then((data) => {
        setFaqData(data.data);
        setLoading(false);
        
 
      })
      .catch((error) => {
        props.showAlert("Internal Server Error", "error");
        setLoading(false); 
      });
  }, []);

  const toggleQuestion = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };
  const staticFAQ = {
    ID: '2000', // Provide a unique ID for the FAQ item
    question: 'I have some resources. How can I share with Manorlane?',
    answer: 'We would love this! We are stronger because of our community. <a href="mailto:hello@manorlane.co">Email us</a> directly with the subject line: “ManorLane Learning”. Send the article or resource, and whether you would like to be credited/cited for the resource. You can also email us through our <a href="https://manorlane.co/contact">Contact Us</a> page.',
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'FAQ' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
    <TopBar API_URL={props.API_URL}  showAlert={props.showAlert}  />
    <div  style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}>
    <div className="container pt-4 mb-3" >
      <div className="faq_div ml-1 mr-1 mt-4 pb-4">
        <h6 className="faq_heading pt-2 pl-4 ml-1">Frequently Asked Questions:</h6>
        <hr />
        {loading ?( <Preloader />)
        :
        (
          
        <div className="accordion pl-4 pr-4" id="faqAccordion">
          {faqData.map((faqItem, index) => (
            <div
              className="card mb-4"
              key={faqItem.ID}
              style={{
                borderRadius: "10px",
                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                background: "#FFF",
              }}
            >
              <div className="card-header" id={`heading${faqItem.ID}`}>
                <h2 className="mb-0">
                  <button
                    className={`faq_question btn btn-block text-left ${index === activeQuestion ? 'active' : ''}`}
                    type="button"
                    onClick={() => toggleQuestion(index)}
                    style={{ textDecoration: 'none' }}
                  >
                    {faqItem.question.charAt(0).toUpperCase() + faqItem.question.slice(1)}
                  </button>
                </h2>
              </div>
              <div
                className={`collapse ${index === activeQuestion ? 'show' : ''}`}
              >
               {faqItem.question === "I love this idea! How can I help spread the word?" ? (
  <div className="faq_answer card-body ml-3">
    {faqItem.answer.charAt(0).toUpperCase() + faqItem.answer.slice(1)} Send them our way <Link to="/contact">here</Link>.
  </div>
) : (
  faqItem.question === "I have some resources to share or an article to write. How can I share with the ManorLane Community?" ? (
    <div className="faq_answer card-body ml-3">
      We would love this! We are stronger because of our community. <a href="mailto:hello@manorlane.co" target='blank'>Email us</a> directly with the subject line: “ManorLane Learning”. Send the article or resource, and whether you would like to be credited/cited for the resource. You can also email us through our <a href="https://manorlane.co/contact" target='blank'>Contact Us</a> page.
    </div>
  ) : (
    <div className="faq_answer card-body ml-3">
      {faqItem.answer.charAt(0).toUpperCase() + faqItem.answer.slice(1)}
    </div>
  )
)}

                
              </div>
            </div>
          ))}
        </div>
        )
        }
      </div>
    </div>
    <br/>
    </div>
   
    </>
  );
}

export default FAQ;

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from './NotificationsContext'; 
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
const TopNavbar = (props) => {
  let navigate = useNavigate();
  const ref = useRef(null);
  const refClose = useRef(null);
  const [credentials, setCredentials] = useState({ name: "", email: "" });
  const [sending, setSending] = useState(false);
  const { notifications } = useNotifications();

  const notificationsCount = notifications.length;


  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  };
  const popup = () => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/communities");
        } 
      }); 

    } else {
      ref.current.click();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(credentials.name==="" || credentials.email===""){
      props.showAlert("Please Fill fields first.","warning");
      return;
    }

    setSending(true);
    const response = await fetch(`${props.API_URL}/api/contact/invite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
      }),
    });
    const json = await response.json();
    setSending(false);
    refClose.current.click();
    if (json.status === "success") {
      props.showAlert(json.message, json.status);
    } else {
      props.showAlert(json.message, "error");
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  function generateURL() {
    const baseURL = 'https://manorlane.co/learn/';
    const token = localStorage.getItem("token"); 
    
    if (localStorage.getItem("token")) {
        // If the user is logged in, append the token as a URL parameter
        return `${baseURL}?token=${token}`;
    } else {
        return baseURL;
    }
}

 

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const location = useLocation();
  
  return (
    <>
      <nav
        className="navbar fixed-top  navbar-expand-lg navbar-light "
        style={{
          marginTop: "0px",
          backgroundColor: "white",
          paddingTop: "16px",
        }}
      >
        <Link className="navbar-brand" to="/">
          <img
            className="logoartboard-1-copy-3 pb-1 ml-3"
            alt=""
            src="/manor_logo.png"
          />
        </Link>

        <button
          className="navbar-toggler mr-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav1"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
       
              <Link to="/search_reviews">
                  <button
                    type="submit"
                    className="add_review_topbar2 btn ml-3 pl-lg-3  pl-md-3 pr-lg-3 pr-md-3"
                  >
                    Review a property
                  </button>
              </Link>
              <button
                   
                   className="btn  invite_friend_topbar d-lg-none d-md-none d-block"
                   
                   onClick={popup}
                 >
                   <i className="fa fa-person"></i> {" "}
                   Invite a friend
                 </button>
               
                  
              
             
           

        <div className="collapse navbar-collapse mt-1 pb-2" id="navbarNav1">
          <ul className="navbar-nav ml-auto   mr-3">

            <li className="d-none d-lg-block d-md-block">
              <Link to="/search_reviews">
                  <button
                    type="submit"
                    className="add_review_topbar btn pl-lg-3  pl-md-3 pr-lg-3 pr-md-3"
                  >
                    Review a property
                  </button>
              </Link>
             
            </li>

            <li
              className={`nav-item ${
                location.pathname === "/communities" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/communities" ? "active" : ""
                }`}
                to="/communities"
              >
                Communities
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/learn" ? "active" : ""
              }`}
            >
              <a
                className="nav-link"
                href={generateURL()}
                target="blank"
              >
                Learn
              </a>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/faq" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/faq" ? "active" : ""
                }`}
                to="/faq"
              >
                FAQ
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
                to="/about"
              >
                About
              </Link>
            </li>
            {!localStorage.getItem("token") ? (
              <>
                <li
                  className={`nav-item ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/login" ? "active" : ""
                    }`}
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/signup" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/signup" ? "active" : ""
                    }`}
                    to="/signup"
                  >
                    Sign up
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li
                  className={`d-lg-block d-md-block d-none nav-item ${
                    location.pathname === "/my_notifications" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/my_notifications" ? "active" : ""
                    }`}
                    to="/my_notifications"
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ fontSize: "22px" }}
                    />
                  </Link>
                  {notificationsCount > 0 && (
              <div className="notification-badge">{notificationsCount}</div>
            )}
                </li>

                <li>
                  <img
                    className="top-banner-xx-item  mb-2 ml-3 mr-3"
                    alt=""
                    src="/group-37023.svg"
                    onClick={handleDropdownToggle}
                    style={{ cursor: "pointer" }}
                  />
                </li>
                {dropdownVisible && (
                  <div className="menu">
                    <Link to="/profile">
                      {" "}
                      <span className="dropdown-item">Profile</span>
                    </Link>
                    <Link to="/my_reviews">
                      {" "}
                      <span className="dropdown-item">My Reviews</span>
                    </Link>
                    <Link to="/bookmarks">
                      {" "}
                      <span className="dropdown-item">Bookmarks</span>
                    </Link>
                   
                    <span className="dropdown-item" onClick={popup}>Invite Friend</span>
                    <span className="dropdown-item" onClick={handleLogout}>
                      Logout
                    </span>
                  </div>
                )}
              </>
            )}
          </ul>
        </div>
      </nav>
      <div className="container-fluid">
        <div
          className="row "
          style={{ marginTop: "70px", marginBottom: "-10px" }}
        >
          <div className="col-12 mt-1">
            <img
              className="top-banner-xx-child img-fluid "
              style={{ width: "100%" }}
              alt=""
              src="/line-51.svg"
            />
          </div>
        </div>
      </div>
      <button
type="button"
ref={ref}
className="btn btn-primary d-none"
data-toggle="modal"
data-target="#exampleModal"
>
Launch demo modal
</button>

<div
className="modal fade"
id="exampleModal"
tabIndex="-1"
role="dialog"
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel" >
        Invite Friend
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <form action="">
        <div className="form-group">
          <label htmlFor="etitle"
            className="modal_label"
          >Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
            minLength={3}
            value={credentials.name}
            onChange={onChange}
            placeholder="Enter the name of the person you're inviting"
            style={{
              borderRadius: "4px",
              border: "1px solid #E4E4E4",
              background: "#F1F1F1",
              fontSize:"12px"

            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="etitle"
      className="modal_label"          
        >Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
            value={credentials.email}
            onChange={onChange}
            placeholder="Enter the email address of the person you're inviting"
            style={{
              borderRadius: "4px",
              border: "1px solid #E4E4E4",
              background: "#F1F1F1",
              fontSize:"12px"
            }}
          />
        </div>
      </form>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        ref={refClose}
        className="btn btn-outline cancel_button_popup"
        data-dismiss="modal"
      >
        Cancel
      </button>
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn submit_button_popup"
      >
        {sending ? "Sending..." : "Send"}
      </button>
    </div>
  </div>
</div>
</div>
    </>
  );
};

export default TopNavbar;

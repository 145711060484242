import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";

// Define your custom button component
const MyCustomButton = ({ onClick, children }) => {
  return (
    <button class="google-login-button py-0  d-flex align-items-center justify-content-center" onClick={onClick}>
      <img src="google.png" style={iconStyle} alt="Google Icon" />
      {children}
    </button>
  );
};



const iconStyle = {
  width: "25px",
  marginLeft:"-16px"

};

const GoogleLoginButton = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();

  const handleGoogleOAuth = async (name, email, given_name, id) => {
    try {
      const response = await fetch(`${API_URL}/api/user/signup_login_google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          full_name: name,
          username: given_name,
          email: email,
          password: id,
        }),
      });

      const json = await response.json();

      if (json.status === "success") {
        localStorage.setItem("token", json.token);
        const userId = json.userId.user;
        localStorage.setItem("userId", userId.toString());
        const queryParams = new URLSearchParams(window.location.search);

        // Get the value of a specific query parameter
        const redirectQueryParam = queryParams.get('redirect');
        if(redirectQueryParam){
          
          navigate(redirectQueryParam);
        }
        else{
          navigate("/my_reviews");
        }
        
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  // Use the useGoogleLogin hook to handle Google OAuth login
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      try {
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user information');
        }
        
        const data = await userInfoResponse.json();
        console.log(data);
        
        const { name, email, given_name, id } = data;
        await handleGoogleOAuth(name, email, given_name, id);
      } catch (error) {
        console.error('Error fetching or handling user information:', error);
        props.showAlert("Failed to sign in with Google", "error");
      }
    },
  });

  return (
    <MyCustomButton  onClick={login}>
      <span className='google-button'> Continue with Google </span>
    </MyCustomButton>
  );
};

export default GoogleLoginButton;

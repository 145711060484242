import React from "react";
import TopBar from "./TopNavbar";
import RightBar from "./RightSidebar";
import Sidebar from "./SideBar";
import Communities from "./Communities";

const CommunitiesTab = (props) => {
  return (
    <>
      <TopBar API_URL={props.API_URL}  showAlert={props.showAlert}  />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}
      >
        <div className="row m-0 p-0 " style={{ width: "100%" }}>
          <div className="col-lg-3 col-md-3 m-0 p-0" style={{ width: "100%" }}>
            <Sidebar API_URL={props.API_URL} showAlert={props.showAlert} />
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 m-0 p-0"
            style={{ width: "100%" }}
          >
            <Communities API_URL={props.API_URL} showAlert={props.showAlert}  />
          </div>
          <div className="col-lg-3 col-md-3 ">
            <RightBar API_URL={props.API_URL} showAlert={props.showAlert} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunitiesTab;

import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import { NotificationsProvider } from './Components/NotificationsContext';
// import Navbar from './Components/Navbar';
import Home from "./Components/Home";
import About from "./Components/About";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ForgotPassword from "./Components/ForgotPassword";
import PasswordReset from "./Components/ResetPassword";
import ReviewsTab from "./Components/ReviewsTab";
import BookMarksTab from "./Components/BookMarksTab";
import NotificationsTab from "./Components/NotificationsTab";
import MessagesTab from "./Components/MessagesTab";
import CommunitiesTab from "./Components/CommunitiesTab";
import Footer from "./Components/Footer";
import SearchReviews from "./Components/SearchReviews";
import TOS from "./Components/TOS";
// eslint-disable-next-line
import PrivacyPolicy from "./Components/PrivacyPolicy";
import FAQ from "./Components/FAQ";
import SeacrhResultsTab from "./Components/SearchResultsTab";
import Alert from "./Components/Alert";
import Contact from "./Components/Contact";
import ResultsTab from "./Components/ResultsTab";
import CommunityPageTab from "./Components/CommunityPageTab";
import NoResultsTab from "./Components/NoResultsTab";
import AddReviewTab from "./Components/AddReviewTab";
import ScrollToTop from "./Components/ScrollToTop";
import ThanksTab from "./Components/ThanksTab";
import ProfileTab from "./Components/ProfileTab";
import MyCommunitiestab from "./Components/MyCommunitiestab";
import BottomBar from "./Components/BottomBar";
import ReviewDetailTab from "./Components/ReviewDetailTab";
import Post from "./Components/Post";
import PostTab from "./Components/PostTab";

function App() {
  const [alert, setAlert] = useState(null);
  // const [notifications, setNotifications] = useState([]);
  const API_URL=process.env.REACT_APP_API_URL;
 
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };


  
  
 
  return (
    <div className="App" >
        {/* <div id="google_translate_element"  className=" d-flex  justify-content-center align-items-center"></div> */}

      <Router>
      <NotificationsProvider>
        <Alert alert={alert} />
        <ScrollToTop />

        <Routes>
          <Route  path="/" element={<Home API_URL={API_URL} showAlert={showAlert} />} />
          <Route  path="/about" element={<About API_URL={API_URL} showAlert={showAlert}  />} />
          <Route exact path="/login" element={<Login API_URL={API_URL} showAlert={showAlert}/>} />
          <Route exact path="/signup" element={<Signup API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/forgot_pass" element={<ForgotPassword API_URL={API_URL} showAlert={showAlert} />} />
          <Route eaxct path="/reset_pass/:userId/:token" element={<PasswordReset showAlert={showAlert} API_URL={API_URL} />}/>
          <Route exact path="/my_reviews" element={<ReviewsTab showAlert={showAlert} API_URL={API_URL}  />} />
          <Route exact path="/bookmarks" element={<BookMarksTab showAlert={showAlert} API_URL={API_URL}  />} />
          <Route exact path="/my_notifications" element={<NotificationsTab showAlert={showAlert} API_URL={API_URL}/>}/>
          <Route exact path="/messages" element={<MessagesTab showAlert={showAlert} API_URL={API_URL}  />} />
          <Route exact path="/communities" element={<CommunitiesTab showAlert={showAlert} API_URL={API_URL} />} />
          <Route exact path="/search_reviews" element={<SearchReviews API_URL={API_URL} showAlert={showAlert}  />} />
          <Route exact path="/terms_of_services" element={<TOS showAlert={showAlert} API_URL={API_URL}  />} />          
          <Route exact path="/privacy_policy"  element={<PrivacyPolicy showAlert={showAlert} API_URL={API_URL} />} />
          <Route exact path="/search_results" element={<SeacrhResultsTab API_URL={API_URL} showAlert={showAlert}  />} />
          <Route exact path="/results" element={<ResultsTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/faq" element={<FAQ API_URL={API_URL} showAlert={showAlert}  />} />
          <Route exact path="/add_review" element={<AddReviewTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/contact" element={<Contact API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/community_page/:id" element={<CommunityPageTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/no_results" element={<NoResultsTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/thanks" element={<ThanksTab API_URL={API_URL} showAlert={showAlert}  />} />
          <Route exact path="/profile" element={<ProfileTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/my_communities" element={<MyCommunitiestab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/review_detail/:R_id" element={<ReviewDetailTab API_URL={API_URL} showAlert={showAlert} />} />
          <Route exact path="/post/:p_id" element={<PostTab API_URL={API_URL} showAlert={showAlert} />} />
         </Routes>
         <BottomBar />
        <Footer />
        </NotificationsProvider>
      </Router>
    </div>
  );
}

export default App;

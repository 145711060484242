import React, { useState, useEffect } from "react";
import TopNavbar from "./TopNavbar";
import { Link } from "react-router-dom";

const Privacy_policy = (props) => {
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Privacy Policy' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}>
      <div
        className="container pt-4 mb-3"
       
      >
        <div
          className="ml-1 mr-1 mt-4"
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            background: "#FFF",
          }}
        >
          <div className="row mb-3 px-0 mx-0 ">
            <div className="col-12 p-5">
              <h6 className="privacy_policy_top_heading">Privacy Policy</h6>

              <p className="privacy_policy_para">
                Last Updated: December 2023 <br /> <br />
                ManorLane ("we," "us," or "our") is committed to protecting your
                privacy. This Privacy Policy is designed to help you understand
                how we collect, use, and safeguard your information when you use
                our website and services. By accessing or using our website, you
                consent to the practices described in this Privacy Policy.
              </p>
              
              <h6 className="privacy_policy_subheading">
                1. Information We Collect:
              </h6>

              <p className="privacy_policy_para">
                We collect the following types of information from users of our
                website: <br />
                </p>
                <p className="privacy_policy_para pl-4">
                a. Personal Information: When you register for an account, we
                may collect personal information such as your name, email
                address, and any other information you choose to provide. <br /> <br />
                b. Reviews and Content: Users may submit reviews and other
                content related to landlord-owned properties and property
                managers, which may include personal experiences, opinions, and
                ratings. <br /> <br />

                c. Usage Data: We collect information about how you use our
                website, including your interactions with content, messages, and
                other users. <br /> <br />
                d. Device Information: We collect information about the devices
                you use to access our website, such as your IP address, browser
                type, and operating system.
              </p>
              <h6 className="privacy_policy_subheading">
                2. How We Use Your Information:
              </h6>

              <p className="privacy_policy_para">
                We use the information we collect for the following purposes:{" "}
                <br /> 

                </p>
                <p className="privacy_policy_para pl-4">
                a. Providing Services: We use your personal information to
                create and manage your account, and to provide you with access
                to our services, including the ability to submit reviews,
                communicate with other users, and access content. <br /> <br/>
                b. Communications: We may use your email address to send you
                updates, notifications, and important information related to our
                services. <br /> <br />
                c. Improving and Customizing Services: We use your information
                to understand how our website is used and to improve the user
                experience. This may include personalizing content and features.{" "}
                <br /> <br />
                d. Safety and Security: We may use your information to verify
                your identity, monitor and prevent fraudulent or illegal
                activities, and ensure the safety and security of our community.
              </p>
              <h6 className="privacy_policy_subheading">
                3. Sharing Your Information:
              </h6>

              <p className="privacy_policy_para">
                We may share your information with the following parties: <br />
                </p>
                <p className="privacy_policy_para pl-4">
                a. Other Users: Certain information, such as your reviews,
                username, and profile picture, may be visible to other users of
                our website. <br /> <br />
                b. Service Providers: We may share your information with
                third-party service providers who assist us in providing our
                services, including hosting, analytics, and customer support.{" "}
                <br /><br />
                c. Legal Compliance: We may share your information in response
                to legal requests or as required by law to protect our rights,
                privacy, safety, or property. <br /><br />
                d. Business Transfers: In the event of a merger, acquisition, or
                sale of all or a portion of our assets, your information may be
                transferred as part of the transaction.
              </p>
              <h6 className="privacy_policy_subheading">4. Your Choices:</h6>

              <p className="privacy_policy_para">
                You have the following choices regarding your information:{" "}
                </p>
                <p className="privacy_policy_para pl-4">
                a. Account Information: You can access and update your account
                information by logging into your account settings. 
                <br /><br />
                
                b. Communications: You can opt out of receiving certain email
                communications by following the instructions in those emails.{" "}
                <br /><br />
                c. Deactivation: You may deactivate your account by contacting
                us. Please note that certain information may be retained in our
                archives.
              </p>
              <h6 className="privacy_policy_subheading">5. Data Security:</h6>

              <p className="privacy_policy_para">
                We take reasonable measures to protect your information, but no
                method of transmission over the internet or electronic storage
                is 100% secure. We cannot guarantee the security of your
                information.
              </p>
              <h6 className="privacy_policy_subheading">
                6. Changes to this Privacy Policy:
              </h6>

              <p className="privacy_policy_para">
                We may update this Privacy Policy to reflect changes to our
                practices or for other operational, legal, or regulatory
                reasons. If we make material changes, we will provide notice on
                our website or through other means.
              </p>
              <h6 className="privacy_policy_subheading">7. Contact Us:</h6>

              <p className="privacy_policy_para">
                If you have any questions, concerns, or feedback about this
                Privacy Policy, please {" "}
                <Link
                  to="/contact"
                  style={{
                    color: "#1E81C4",
                    fontFamily: "'Lato', sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "206.682%",
                    textDecoration: "underline",
                  }}
                >
                  Contact us.
                </Link>
              </p>
              <h6 className="privacy_policy_subheading">
                8. Consent to Privacy Policy:
              </h6>

              <p className="privacy_policy_para">
                By using our website, you agree to the terms and conditions of
                this Privacy Policy. If you do not agree to this Privacy Policy,
                please do not use our website. <br />
                <br />
                Thank you for using ManorLane. We value your trust and are
                committed to protecting your privacy. <br />
                ManorLane, LLC
              </p>
            </div>
          </div>
        </div>
      </div>
        <br/>
</div>
      
    </>
  );
};

export default Privacy_policy;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Thanks = (props) => {
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Thanks' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="notifications_comp mt-4">
        <div class="container-fluid" style={{textAlign:"center"}}>
            <img src="/thanks.png" className="img-fluid"  alt="" />
            <br />
          <b className="thanks_heading"> Huzzah! Thank you for your review. Your words and insights will help others make more informed decisions about their housing. </b>
          <div className="row d-flex mt-4 text-align-center justify-content-center align-items-center">
            <Link to="/my_reviews">
            <button className="create_community_button btn mb-5" >
                My reviews
              </button> 
            </Link>
        
                       </div>
        </div>
      </div>
    </>
  );
};

export default Thanks;

import React from 'react'

const MyBookmarkedArticles = ({loading, bookmarks}) => {
  return (
    <div>
        {!loading && Array.isArray(bookmarks) && bookmarks.length > 0
            ? bookmarks.map((bookmark, index) => (
              <div className='row'>
<div class="col-12 mt-1 pb-3" key={index}>
                  <div class="card">
                    {bookmark.Image && (
                      <img
                        src={bookmark.Image}
                        class="card-img-top img-fluid"
                        alt=""
                      />
                    )}
                    <div class="card-body">
                      <h4>{bookmark.Title}</h4>
                      <br />
                      <br />
                      <p class="card-text">
                        {bookmark.Description &&
                          bookmark.Description.slice(0, 500)}
                        ....
                      </p>
                      <a
                        href={bookmark.URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-primary"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                </div>
                
              ))
            : !loading && (
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ width: "100%" }}
                >
                  <h5
                    className="pt-4"
                    style={{
                      fontFamily: "'Lato',sans-serif",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    No Bookmarks available
                  </h5>
                </div>
              )}
    </div>
  )
}

export default MyBookmarkedArticles;

import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { io } from "socket.io-client";
import Conversation from "./Conversation";
import ChatBox from "./ChatBox";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
const Messages = (props) => {
  const socket = useRef();
  const [chats, setChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to access this page, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/messages");
        } else {
          navigate("/communities");
        }
      });
    }
    const getChats = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(`${props.API_URL}/api/chats/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch chats");
        }

        const data = await response.json();
        setLoading(false);

        setChats(data);
      } catch (error) {
        console.error(error);
      }
    };

    getChats();
  }, [receivedMessage,sendMessage]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");

    if (storedUserId) {
      socket.current = io(process.env.REACT_APP_SOCKET_SERVER);
      socket.current.emit("new-user-add", storedUserId);
      socket.current.on("get-users", (users) => {
        setOnlineUsers(users);
      });
    }
  }, []);

  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("recieve-message", (data) => {
        
        setReceivedMessage(data);
      });
    }
  }, []);

  const checkOnlineStatus = (chat) => {
    const id = localStorage.getItem("userId");
    const chatMember =
      chat?.member1Id.toString() === id?.toString()
        ? chat?.member2Id.toString()
        : chat?.member1Id.toString();
    const online = onlineUsers.find((user) => user.userId === chatMember);
    return online ? true : false;
  };

  const handleChatClick = (chat) => {
    setCurrentChat(chat);
    setChatOpen(true);
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Messages' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <div className="messages_comp">
    <div className=" ml-1 mr-1 mt-4 pt-4"  style={{
      borderRadius: "10px",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      backgroundColor:"white"
    }}>
      <h6 className="my_messages mb-4  pl-4 pr-2">Chats</h6>
      <p className="msgs_intro pl-4 pr-2">
        Wanna message someone in your community? Start a chat with your private
        Community members only. Create a private Community{" "}
        <Link to="/communities">here.</Link>{" "}
      </p>

      <div className="row ">
        {!chatOpen && (
          loading ? (
            <div
              className="spinner-border "
              role="status"
              style={{ marginLeft: "45%" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          ):(
            <div className="col-12">
            <div className="messages_links mb-5">
              <div className="mt-2">
                {(chats && chats.length>0)?(
                  <div className="Chat-list">
                  {chats.map((chat) => (
                    <div
                      style={{ cursor: "pointer" }}
                      key={chat.ID}
                      onClick={() => handleChatClick(chat)}
                    >
                      <Conversation
                        chatData={chat}
                        currentUser={localStorage.getItem("userId")}
                        online={checkOnlineStatus(chat)}
                        API_URL={props.API_URL}
                      />
                    </div>
                  ))}
                  </div>
                ):(
                  
                  <div className="d-flex justify-content-center align-items-center text-center" style={{marginTop:"30%"}}>
                  <div>
                  <img src="chats.png" style={{width:"60px"}}/>
                  <br/>
                  <b>No chats found</b>
                    <p>
                    This is where you'll see your chats with members of your private communities.
                    </p>
                  </div>
                  
                  </div>
                )}
               
              </div>
            </div>
          </div>
          )
          
        )}
         {chatOpen && (
        <div className="col-12" >
          <div
            className="d-flex flex-column justify-content-center align-items-center mb-5"
            id="msg"
            style={{ height: "500px" }}
          >
           
              <ChatBox
                chat={currentChat}
                currentUser={localStorage.getItem("userId")}
                setSendMessage={setSendMessage}
                receivedMessage={receivedMessage}
                setChatOpen={setChatOpen}
                API_URL={props.API_URL}
              />
           
          </div>
        </div>
         )}
      </div>
    </div>
    </div>
    
  );
};

export default Messages;

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { format } from "timeago.js";
import { faComment } from "@fortawesome/free-regular-svg-icons";

import "sweetalert2/dist/sweetalert2.css";

function Preloader() {
  return (
    <>
      <div
        className="d-flex justify_content-center"
        style={{ marginLeft: "45%" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
}
const Post = (props) => {
  const [communityPosts, setcommunityPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { c_id,p_id } = useParams();
 
  const [comments, setComments] = useState([]);
  const commentInputRef = useRef(null);
  const [postComments, setPostComments] = useState({});
  const [display, setDisplay] = useState({});
 
  const [likes, setLikes] = useState([]);
 


 
 

  const [displayedComments, setDisplayedComments] = useState(5);

  const handleViewMore = () => {
    setDisplayedComments(displayedComments + 5);
  };

  let navigate = useNavigate();



 

  const addLike = async (postId,post_text) => {
    try {
      if (!localStorage.getItem("token")) {
        navigate("/login");

        return;
      }
      const response = await fetch(`${props.API_URL}/api/post/like_post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          postId: postId,
          post_text:post_text
        }),
      });
      const json = await response.json();
      if (json.status === "success") {
        if (!likes.includes(postId)) {
          setLikes([...likes, postId]);
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count + 1 }
                : post
            )
          );
        } else {
          setLikes(likes.filter((id) => id !== postId));
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count - 1 }
                : post
            )
          );
        }
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentKeyPress = async (e, PID, index,post_text) => {
    if (e.key !== "Enter") {
      return;
    }

    e.preventDefault();

    if (!localStorage.getItem("token")) {
      navigate("/login");

      return;
    }

    if (comments[index] === "") {
      props.showAlert("Invalid input.", "warning");
      return;
    }

    try {
      const response = await fetch(
        `${props.API_URL}/api/post/add_comment/${PID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            comment_text: comments[index],
            post_text:post_text
          }),
        }
      );

      const json = await response.json();

      if (json.status === "success") {
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: "none",
        }));
        await fetchCommentsForPost(PID);
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: !prevDisplay[PID] ? "block" : "none",
        }));
        setcommunityPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.ID === PID
              ? { ...post, comments_count: post.comments_count + 1 }
              : post
          )
        );
      } else {
        props.showAlert(json.message, "error");
      }

      const updatedComments = [...comments];
      updatedComments[index] = "";
      setComments(updatedComments);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentChange = (e, index) => {
    const updatedComments = [...comments];
    updatedComments[index] = e.target.value;
    setComments(updatedComments);
  };

  const fetchCommentsForPost = async (postId) => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/post/fetch_comments/${postId}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setPostComments((prevpostComments) => ({
          ...prevpostComments,
          [postId]: data.data,
        }));
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [postId]: !prevDisplay[postId],
        }));
        setDisplayedComments(5);
      } else {
        return [];
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
      return [];
    }
  };

 

 
 

  
 

  
  const fetchPosts = async () => {
    try {
      // Fetch community posts
      const responsePosts = await fetch(
        `${props.API_URL}/api/community/fetch__specific_post/${p_id}`
      );
      setLoading(true);
      const dataPosts = await responsePosts.json();
      if (dataPosts.status === "success") {
        const dataa = dataPosts.data;
       
        setcommunityPosts(dataa);
        setLoading(false);
         
        
      }
      setLoading(false);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  

  const likesData = async () => {
    try {
      if (localStorage.getItem("token")) {
        const likeResponse = await fetch(
          `${props.API_URL}/api/post/fetch_liked_posts/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        const likedData = await likeResponse.json();

        if (likedData.status === "success") {
          const postIds = likedData.data.map((item) => item.community_post_id);
          setLikes(postIds);
        }
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  useEffect(() => {
    fetchPosts();
    likesData();
  }, []);

 
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Post' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  

  return (
    <>
      <div className="communities_comp mb-5 mt-3">
        
        
        {(Array.isArray(communityPosts) && communityPosts.length > 0) ||
        loading ? (
          communityPosts.map((post, index) => (
            <div
              id="comm_feed_posts"
              className="row mb-3 ml-1 mr-1"
              key={post.ID}
            >
              <div className="col-10">
                <div className="d-flex mt-3 mb-3">
                  <div className="profile_img_div_community_posts">
                    <img
                      className="profile_img_community_posts"
                      src={post?.profile}
                      alt=""
                    />
                  </div>
                  <div>
                    <h6
                      className="ml-2 mt-1"
                      style={{
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "700",
                      }}
                    >
                      {post?.author}
                    </h6>
                    <p
                      className="ml-2"
                      style={{
                        marginTop: "-10px",
                        color: "#999",
                        fontSize: "12px",
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "700",
                      }}
                    >
                      {post?.community}
                    </p>
                  </div>
                </div>
              </div>
             

              <div className="comm_feed_post_content ml-2 col-12">
                <p>{post?.Text}</p>
                <br />
              </div>
              {post?.Image && (
                <div className="col-12">
                  <img src={`${post.Image}`} alt="" style={{ width: "100%" }} />
                  <br />
                  <hr />
                </div>
              )}

              <div className="post-actions col-12 d-flex">
                <div
                  className="col-lg-3 col-md-3 col-6 d-flex"
                  style={{ marginLeft: "-5px" }}
                >
                  <FontAwesomeIcon
                    icon={!likes.includes(post.ID) ? regularHeart : solidHeart}
                    className="love-icon"
                    onClick={() => addLike(post.ID,post.Text)}
                    style={{
                      cursor: "pointer",
                    }}
                  />{" "}
                  <p className="ml-1" style={{ fontSize: "12px" }}>
                    {post.likes_count === 0
                      ? "Likes"
                      : post.likes_count === 1
                      ? "1 Like"
                      : `${post.likes_count} Likes`}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-6 ml-1 d-flex">
                  <FontAwesomeIcon icon={faComment} />
                  <p
                    onClick={() => fetchCommentsForPost(post.ID)}
                    style={{ cursor: "pointer", right: "0", fontSize: "12px" }}
                  >
                    {" "}
                    &nbsp;{" "}
                    {post.comments_count === 0
                      ? "Comments"
                      : post.comments_count === 1
                      ? "1 Comment"
                      : `${post?.comments_count} Comments`}
                  </p>
                </div>
              </div>
              <div>
                <div
                  className="col-12 pl-4 pr-4"
                  style={{ display: display[post.ID] ? "block" : "none" }}
                >
                  {Array.isArray(postComments[post.ID]) &&
                  postComments[post.ID].length > 0 ? (
                    <>
                      {postComments[post.ID]
                        .slice(0, displayedComments)
                        .map((comment, index) => (
                          <div key={index}>
                            <div className="d-flex">
                              <div className="user_profile_comments">
                                <img src={comment?.profile} alt="" />
                              </div>
                              <div>
                                <b style={{ marginLeft: "10px" }}>
                                  {comment?.username}:
                                </b>
                                <br />

                                <p style={{ marginLeft: "10px" }}>
                                  {comment?.Text}
                                </p>
                                <p
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "-16px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {format(comment.Created_at)}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      {displayedComments < postComments[post.ID].length && (
                        <p
                          style={{
                            color: "#1E81C4",
                            fontSize: "14px",
                            marginLeft: "10px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleViewMore}
                        >
                          View More
                        </p>
                      )}
                    </>
                  ) : (
                    <small>No comments</small>
                  )}
                </div>
              </div>
              <form action="" style={{ width: "100%" }}>
                <input
                  ref={commentInputRef}
                  type="text"
                  className="leave_comment ml-4"
                  placeholder="Leave a comment"
                  required
                  minLength={3}
                  style={{
                    color: "rgba(0, 0, 0, 0.50)",
                    fontFamily: "'Inter',sans-serif",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "101.023%",
                    width: "70%",
                  }}
                  value={comments[index] || ""}
                  onChange={(e) => handleCommentChange(e, index)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      const inputValue = e.target.value.trim();
                      if (inputValue) {
                        handleCommentKeyPress(e, post.ID, index,post.Text);
                      }
                    }
                  }}
                />
              </form>
            </div>
          ))
        ) : (
          <h5 className="text-center mt-5">No posts available</h5>
        )}

       

      </div>

      

     
      
    </>
  );
};

export default Post;

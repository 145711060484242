import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const PasswordReset = (props) => {
  const { userId, token } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let navigate = useNavigate();
  const [processing, setProcessing]=useState(false)


  const handlePasswordReset = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      props.showAlert("Passwords do not match.", "error");
      return;
    }
  
    try {
      setProcessing(true);

      const response = await fetch(
        `${props.API_URL}/api/user/reset_pass/${userId}/${token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );
  
      const data = await response.json();
      setProcessing(false);

  
      if (data.status === "success") {
        props.showAlert(data.message, data.status);
        setTimeout(() => {
          navigate("/login");
        }, 4000);
      } else {
        props.showAlert(data.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");

    }
  };
  
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Reset Password' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="row  " style={{ width: "100%" }} id="login_row">
      <div
          className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column align-items-center "
          style={{ position: "relative" }}
        >
          <div style={{ position: "relative" }}>
            <img
              className="img-fluid"
              src="/footer.png"
              style={{
                width: "400px",
                paddingBottom: "140px",
                paddingTop: "80px",
                marginTop: "50px",
              }}
              alt=""
            />
          </div>
          <Link to="/">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url('/login_bg.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: "0.2",
              }}
            />
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <div
            className="login_form ml-lg-5 mr-lg-5 ml-md-5 mr-md-5 rounded ml-5 "
            style={{ marginTop: "100px" }}
          >
            <h4 className="reset_pass_heading">
              Reset password
              <br />
              <br />
            </h4>
            <form onSubmit={handlePasswordReset}>
              <div className="form-group mr-4">
                <label className="reset_pass_label" htmlFor="email">
                  New password
                </label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  id="password"
                  name="password"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group mr-4">
                <label className="reset_pass_label"  htmlFor="email">
                  Confirm password
                </label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  id="cpassword"
                  name="cpassword"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>

              <button type="submit" className="reset_pass_btn btn" disabled={processing}>
                {processing ?"Processing...":"Reset password"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";

function Contact(props) {
  const [credentials, setCredentials] = useState({ name:"", email: "", type: "", message:"" });
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Contact' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setLoading(true);
  
      const response = await fetch(`${props.API_URL}/api/contact/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          message: credentials.message,
          type: credentials.type,
        }),
      });
  
      const json = await response.json();
  
      if (json.status === "success") {
        
        props.showAlert(json.message, json.status);
        navigate("/")
        
      } else {
        props.showAlert(json.message, "error");
       
      }
    } catch (error) {
       
      props.showAlert("Internal Server Error", "error");
    } finally {
      setLoading(false);
    }
  };
  
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
    {/* <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} /> */}
      <div className="row ml-1 mr-1" style={{ width: "100%" }} id="login_row">
        <div
          className="col-lg-7 col-md-7 col-sm-12 col-xs-12 d-flex flex-column align-items-center "
          style={{ position: "relative" }}
        >
          <div style={{ position: "relative" }}>
            <img
              className="img-fluid"
              src="/footer.png"
              style={{
                width: "400px",
                paddingBottom: "140px",
                paddingTop: "80px",
                marginTop: "50px",
              }}
              alt=""
            />
          </div>
          <Link to="/">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url('/login_bg.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: "0.2",
              }}
            />
          </Link>
        </div>

        <div className="col-lg-5  col-md-5 col-sm-12 col-xs-12">
          <div className="login_form ml-lg-5 mr-lg-5 ml-md-5 mr-md-5   p-4 rounded">
            <br />
            <form onSubmit={handleSubmit}>
              <h4 className="contact_heading" style={{}}>
                Contact us
              </h4>
              <br />

              <div className="form-group">
                <label
                  className="contact_form_label"
                  htmlFor="exampleInputEmail1"
                >
                  Name{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={credentials.name}
                  required
                  onChange={onChange}
                  id="name"
                  name="name"
                  minLength={3}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>

              <div className="form-group">
                <label
                  className="contact_form_label"
                  htmlFor="exampleInputEmail1"
                >
                  Email{" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={credentials.email}
                  required
                  onChange={onChange}
                  id="exampleInputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                  <label className="contact_form_label" >
                    Category
                  </label>
                  <br />
                  <select
                    className="contact_form_dropdown"
                    required
                    id="type"
                    name="type"
                    value={credentials.type}
                    onChange={onChange}
                    title="Category"
                   
                  >
                    <option value="" disabled selected>
                      Select Category
                    </option>
                    <option value="feedback">Feedback</option>
                    <option value="suggestion">Suggestion</option>
                    <option value="query">Problem/Query</option>
                    <option value="article idea">Article idea</option>

                    



                    
                  </select>
                </div>
              <div className="form-group">
                <label
                  className="contact_form_label"
                  htmlFor="exampleInputPassword1"
                >
                  Message
                </label>
                <textarea
                  type="message"
                  value={credentials.message}
                  required
                  onChange={onChange}
                  name="message"
                  className="form-control"
                  id="exampleInputPassword1"
                  minLength={15}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="d-flex">
                <div className="col-8" style={{ marginLeft: "-15px" }}>
                  <button
                    type="submit"
                    className="contact_button btn btn-primary pl-4 pr-4"
                    disabled={loading} 
                  >
                     {loading ? "Sending..." : "Send"}
                  </button>
                  <br /><br /><br /><br /><br /><br /> <br />


                </div>
                
              </div>
            </form>
           

            
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

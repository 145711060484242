import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation(); // Access useLocation hook

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/fetch_notifications`, {
        headers: {
          "auth-token": `${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setNotifications(data.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if(localStorage.getItem('token')){
      fetchNotifications();
    }
  }, [location]);

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/read_notification`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ notification_id: notificationId }),
      });
      
      if (response.ok) {
        // Notification marked as read successfully
        console.log("Notification marked as read:", notificationId);
        setNotifications(prevNotifications =>
          prevNotifications.filter(notification => notification.notification_id !== notificationId)
        );
      } else {
        throw new Error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <NotificationsContext.Provider value={{ notifications, markNotificationAsRead }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);

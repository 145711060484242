import { useCallback, useRef, useState } from "react";
import "../App.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUsers, faMessage, faBell, faBookmark, faLightbulb  } from '@fortawesome/free-solid-svg-icons';

import { useNotifications } from './NotificationsContext'; 
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
const SideBar = (props) => {
  const location = useLocation();
  const ref = useRef(null);
  const refClose = useRef(null);
  const [sending, setSending] = useState(false);
  let navigate = useNavigate();
  const { notifications } = useNotifications();

  const notificationsCount = notifications.length;

  const [credentials, setCredentials] = useState({ name: "", email: "" });

  const style = {
    fontSize: '18',
    paddingLeft:"6px",
    marginRight:"-7px",
    marginTop:"1px"

  };
  const style2={
    fontSize: '18',
    paddingLeft:"6px",
    marginRight:"-1px",
    marginTop:"1px",
    color:"black",
    textDecoration:"none"

  }
  const style3={
    fontSize: '16',
    paddingLeft:"6px",
    marginRight:"-8px",
    marginTop:"1px"

  }
  const style4={
    fontSize: '16',
    paddingLeft:"6px",
    marginRight:"-4px",
    marginTop:"1px"

  }
  const style5={
    fontSize: '18',
    paddingLeft:"6px",
    marginRight:"-4px",
    marginTop:"1px"

  }
  const style6={
    fontSize: '16',
    paddingLeft:"7px",
    marginRight:"-1px",
    marginTop:"1px"

  }
  function generateURL() {
    const baseURL = 'https://manorlane.co/learn/';
    const token = localStorage.getItem("token"); 
    
    if (localStorage.getItem("token")) {
        // If the user is logged in, append the token as a URL parameter
        return `${baseURL}?token=${token}`;
    } else {
        // If the user is not logged in, simply return the base URL without the token parameter
        return baseURL;
    }
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(credentials.name==="" || credentials.email===""){
      props.showAlert("Please Fill fields first.","warning");
      return;
    }

    setSending(true);
    const response = await fetch(`${props.API_URL}/api/contact/invite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
      }),
    });
    const json = await response.json();
    setSending(false);
    refClose.current.click();
    if (json.status === "success") {
      props.showAlert(json.message, json.status);
    } else {
      props.showAlert(json.message, "error");
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const popup = () => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/communities");
        } 
      }); 

    } else {
      ref.current.click();
    }
  };

  const onReviewsContainerClick = useCallback(() => {
    // Please sync "Review Results" to the project
  }, []);

  const onCommunitiesContainerClick = useCallback(() => {
    // Please sync "Communities" to the project
  }, []);

  const onMessagesContainerClick = useCallback(() => {
    // Please sync "Messages" to the project
  }, []);

  const onNotificationsContainerClick = useCallback(() => {
    // Please sync "Messages" to the project
  }, []);

  const onGroupContainer1Click = useCallback(() => {
    // Please sync "Bookmarks" to the project
  }, []);

  // const onResourcesContainerClick = useCallback(() => {
  //   // Please sync "Resources" to the project
  // }, []);

  const onReviewSearchBtnContainerClick = useCallback(() => {
    // Please sync "Search - Form" to the project
  }, []);

  return (
    <>
    <div className="container-fluid ">
      <div className="row mr-2">
        <div className="col-12">
          <div className="sidebar d-none d-xl-block d-md-block d-lg-block" style={{maxWidth:"100%"}}>
            <div className="rectangle-parent mt-3 pb-2 ml-lg-2 ml-md-2 ">
                
                <button className="invite_friend_btn pt-2 pb-2 pl-lg-3 pr-lg-3 " onClick={popup}>
                  <img src="/invite.png" className="img-fluid" alt="" width={"16px"} />+ Invite a
                  friend or neighbor
                </button>
            
            </div>
            <div
              className=" mb-3 mt-2"
              style={{
                borderRadius: "10px",
                // boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FFF",
              }}
            >
              <div
                className={`reviews  mt-1 d-flex side-link align-items-center  ${
                  location.pathname === "/my_reviews" ? "active" : ""
                }`}
                onClick={onReviewsContainerClick}
              >
                 <FontAwesomeIcon icon={faStar } style={style} />

                <Link to="/my_reviews" style={{ textDecoration: "none" }}>
                  <div
                    className={`my-reviews    side-link nav-item ${
                      location.pathname === "/my_reviews" ? "active" : ""
                    }`}
                  >
                    My Reviews
                  </div>
                </Link>
              </div>
              <div
                className={`communities mt-1  d-flex side-link align-items-center  ${
                  location.pathname === "/my_communities" ? "active" : ""
                }`}
                onClick={onCommunitiesContainerClick}
              >
                 <FontAwesomeIcon icon={ faUsers }  style={style3}  />
                <Link to="/my_communities" style={{ textDecoration: "none" }}>
                  {" "}
                  <div
                    className={`communities1    side-link nav-item ${
                      location.pathname === "/my_communities" ? "active" : ""
                    }`}
                  >
                    My Communities
                  </div>
                </Link>
              </div>
              <div
                className={`messages mt-1 d-flex side-link align-items-center  ${
                  location.pathname === "/messages" ? "active" : ""
                }`}
                onClick={onMessagesContainerClick}
              >
                <FontAwesomeIcon icon={ faMessage } style={style4}  />

                <Link to="/messages" style={{ textDecoration: "none", marginTop:"-3px" }}>
                  {" "}
                  <div
                    className={`messages1 py-1  side-link nav-item ${
                      location.pathname === "/messages" ? "active" : ""
                    }`}
                  >
                    Messages
                  </div>{" "}
                </Link>
              </div>
              <div
                className={`notifications_navbar mt-1  d-flex side-link align-items-center ${
                  location.pathname === "/my_notifications" ? "active" : ""
                }`}
                onClick={onNotificationsContainerClick}
              >
                <FontAwesomeIcon icon={ faBell }  style={style5}  />

                <Link to="/my_notifications" style={{ textDecoration: "none" }}>
                  <div
                    className={`notifications1   side-link nav-item ${
                      location.pathname === "/my_notifications" ? "active" : ""
                    }`}
                  >
                    Notifications
                  </div>
                </Link>
                {notificationsCount > 0 && localStorage.getItem('token') && (
              <div className="notification-badge3">{notificationsCount}</div>
            )}
              </div>
              <div
                className={`bookmarks-parent mt-1  d-flex side-link align-items-center  ${
                  location.pathname === "/bookmarks" ? "active" : ""
                }`}
                onClick={onGroupContainer1Click}
              >
                 <FontAwesomeIcon icon={ faBookmark }  style={style6}  />

                <Link to="/bookmarks" style={{ textDecoration: "none" }}>
                  <div
                    className={`bookmarks_navbar   side-link nav-item ${
                      location.pathname === "/bookmarks" ? "active" : ""
                    }`}
                  >
                    Bookmarks
                  </div>
                </Link>
              </div>
              <a
                className="learn_link"
                href={generateURL()}
                target="blank"
              >
              <div
                className={`resources mt-1 d-flex side-link align-items-center ${
                  location.pathname === "/learn" ? "active" : ""
                }`}
                onClick={onGroupContainer1Click}
              >
                
                 <FontAwesomeIcon icon={ faLightbulb } style={style2}  />
               
                  <div
                    className={`bookmarks_navbar   side-link nav-item ${
                      location.pathname === "/learn" ? "active" : ""
                    }`}
                  >
                    Learn
                  </div>
                
              </div>
              </a>
              <div
                className="review-search-btn d-flex"
                onClick={onReviewSearchBtnContainerClick}
              >
                <div className="rectangle-parent mt-4 ml-4">
                  <Link to="/search_reviews">
                    {" "}
                    <button className="write_review_btn btn">
                      Write a review{" "}
                      <img src="/right_arrow.png" className="ml-2" alt="" />
                    </button>
                  </Link>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<button
type="button"
ref={ref}
className="btn btn-primary d-none"
data-toggle="modal"
data-target="#exampleModal"
>
Launch demo modal
</button>

<div
className="modal fade"
id="exampleModal"
tabindex="-1"
role="dialog"
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel" >
        Invite Friend
      </h5>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <form action="">
        <div className="form-group">
          <label htmlFor="etitle"
            className="modal_label"
          >Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
            minLength={3}
            value={credentials.name}
            onChange={onChange}
            placeholder="Enter the name of the person you're inviting"
            style={{
              borderRadius: "4px",
              border: "1px solid #E4E4E4",
              background: "#F1F1F1",
              fontSize:"12px"

            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="etitle"
      className="modal_label"          
        >Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
            value={credentials.email}
            onChange={onChange}
            placeholder="Enter the email address of the person you're inviting"
            style={{
              borderRadius: "4px",
              border: "1px solid #E4E4E4",
              background: "#F1F1F1",
              fontSize:"12px"
            }}
          />
        </div>
      </form>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        ref={refClose}
        className="btn btn-outline cancel_button_popup"
        data-dismiss="modal"
      >
        Cancel
      </button>
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn submit_button_popup"
      >
        {sending ? "Sending..." : "Send"}
      </button>
    </div>
  </div>
</div>
</div>

</>


  );
};

export default SideBar;

import React, { useState, useEffect } from "react";
import TopNavbar from "./TopNavbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const SearchReviews = (props) => {
  const [management_cfg, setManagement_cfg] = useState([]);
  const [state_cfg, setState_cfg] = useState([]);
  const [credentials, setCredentials] = useState({
    address: null,
    city: null,
    state: null,
    zipCode: null,
    managementType: null,
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none', 
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black', 
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#7b7b7b', // Adjust the color as needed
      fontWeight:"400"
     
    }),
    
  };
  let navigate = useNavigate();

  const handleReview = async (e) => {
    try {
      e.preventDefault();
      if (credentials.state === null || credentials.address === null || credentials.city === null || credentials.managementType === null) {
        props.showAlert("Please fill in all required fields", "warning");
        return;
      }
      const response = await fetch(
        `${props.API_URL}/api/search/search_property_reviews`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: credentials.address,
            city: credentials.city,
            state: credentials.state,
            zipCode: credentials.zipCode,
            managementType: credentials.managementType,
          }),
        }
      );

      const json = await response.json();

      if (json.status === "success") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        console.log("local", localStorage.getItem("review_data"));
        navigate("/search_results");
      }
      else if(json.status==="new_added") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        navigate("/add_review");

      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error","error" );
    }
  };

  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      if (credentials.state === null || credentials.address === null || credentials.city === null || credentials.managementType === null) {
        props.showAlert("Please fill in all required fields", "warning");
        return;
      }

      const response = await fetch(
        `${props.API_URL}/api/search/search_property_reviews`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: credentials.address,
            city: credentials.city,
            state: credentials.state,
            zipCode: credentials.zipCode,
            managementType: credentials.managementType,
          }),
        }
      );

      const json = await response.json();
      console.log(json);

      if (json.status === "success") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        console.log("local", localStorage.getItem("review_data"));
        navigate("/search_results");
      }
      else if(json.status==="new_added") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        navigate("/add_review");

      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error","error" );
    }
  };

  useEffect(() => {
    fetch(`${props.API_URL}/api/form/management_cfg`)
      .then((response) => response.json())
      .then((data) => {
        setManagement_cfg(data.data);
      })
      .catch((error) => {
        props.showAlert("Internal Server Error","error" );
      });
  }, []);

  useEffect(() => {
    fetch(`${props.API_URL}/api/form/state_cfg`)
      .then((response) => response.json())
      .then((data) => {
        setState_cfg(data.data);
      })
      .catch((error) => {
        props.showAlert("Internal Server Error","error" );
      });
  }, []);

  const onChange = (name, value) => {
    setCredentials({ ...credentials, [name]: value });
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Search Reviews' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} />
  <div className="container-fluid mt-5" style={{overflowX:"hidden"}}>
        <div id="search_reviews_div">
          <div className="row ml-lg-5 ml-md-5 ml-3 mr-3 ">
            <div className="col-12 mt-5">
              <h6 id="search_reviews_heading">Search or Review a Property</h6>{" "}
              <br />
            </div>
          </div>
          <div className="row ml-lg-5 ml-md-5 ml-3 mr-3">
            <div className="col-lg-3 col-md-3 col-sm-12 mr-lg-3 mr-md-3  col-xs-12 col-12 ">
              <div className="form-group">
                <label htmlFor="address" className="search_form_label">
                  Address*{" "}
                </label>
                <input
                  type="text"
                  className="form-control search_form_placeholder"
                  required
                  id="address"
                  name="address"
                  value={credentials.address}
                  onChange={(e) => onChange("address", e.target.value)}
                  placeholder="Type in the address of your residence"
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ml-lg-5   mr-lg-3 mr-md-3 ml-md-5 col-sm-12 col-xs-12 col-12">
              <div className="form-group ">
                <label htmlFor="city" className="search_form_label">
                  City*{" "}
                </label>
                <img width={"34px"}  style={{marginLeft:"60%", marginTop:"-6px"}} src="/city.png" alt="" />

                <input
                  type="text"
                  className="form-control search_form_placeholder"
                  required
                  id="city"
                  name="city"
                  value={credentials.city}
                  onChange={(e) => onChange("city", e.target.value)}
                  placeholder="City"
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ml-lg-5 ml-md-5  mr-lg-5 mr-md-5 col-sm-6 col-xs-6 col-6">
              <div className="form-group" id="search_form_dropdown_state">
                <label htmlFor="state" className="search_form_label">
                  State*
                </label>
                <br />
                <Select
                  className="state-select search_form_dropdowns"
                  isSearchable
                  placeholder="Select State"
                  value={credentials.state ? { label: credentials.state, value: credentials.state } : null}
                  options={state_cfg.map((option) => ({ value: option.Name, label: option.Name }))}
                  onChange={(selectedOption) => onChange('state', selectedOption.value)}
                  styles={customStyles} 
                />
                 
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6 col-6 ml-lg-5 ml-md-5  mr-lg-3 mr-md-3">
              <div className="form-group">
                <label htmlFor="zip" className="search_form_label">
                  Zip{" "}
                </label>
                <input
                  type="text"
                  className="form-control search_form_placeholder"
                  required
                  id="zipCode"
                  name="zipCode"
                  value={credentials.zipCode}
                  onChange={(e) => onChange("zipCode", e.target.value)}
                  placeholder="Zip Code"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-lg-5 ml-md-5 ml-3 mr-3">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="form-group" id="search_form_dropdown_management">
                <label htmlFor="management" className="search_form_label">
                  Management Type*
                </label>
                <Select
                  className="management-select search_form_dropdownm"
                  isSearchable
                  placeholder="Select Management Type"
                  value={credentials.managementType ? { label: credentials.managementType, value: credentials.managementType } : null}
                  options={management_cfg.map((option) => ({ value: option.Name, label: option.Name }))}
                  onChange={(selectedOption) => onChange('managementType', selectedOption.value)}
                  styles={customStyles} // Apply the custom styles
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-5 ml-lg-4 ml-md-4  col-sm-12 col-xs-12 col-12">
              <div className="form-group ml-lg-2 ml-md-2">
                <label htmlFor="city" className="search_form_label">
                  Name of PMC or HOA{" "}
                </label>
                <input
                  type="text"
                  className="form-control search_form_placeholder"
                  required
                  id="city"
                  name="city"
                  placeholder="If PMC or HOA, type in the name"
                />
              </div>
            </div>
          </div>
          <div className="row mt-4  ml-lg-5 ml-md-5 ml-3 mr-3">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <p id="transparency_text">
                At last! Transparency and accountability to Property Managers,
                HOA’s and Landlords for all residents.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-flex">
              <div className="col-lg-5 mr-lg-5 mr-md-5 ml-lg-2 ml-md-2 col-md-5 col-sm-5 col-xs-5 col-sm-5 col-5 mr-1">
                <button className="search_form_search_btn btn pl-lg-5  pl-md-5 pr-lg-5 pr-md-5"
                  onClick={handleSearch}>
                  Search
                </button>
                <p  className="see_property pl-lg-2 pl-md-2 pl-1 "> See if the property has been reviewed.</p>

              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-sm-5 col-5 ml-4 mb-5">
                <button className="search_form_review_btn btn pl-lg-5  pl-md-5 pr-lg-5 pr-md-5"
                  onClick={handleReview}
                >
                  Review
                </button>
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12"></div>
            <div
              className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 ml-lg-0 ml-md-0 ml-4"
              style={{ marginTop: "-70px" }}
            >
              <p className="save_track_reviews">
                Want to save and track your reviews? &nbsp;
                <Link to="/login">Login here.</Link>
              </p>
            </div>
          </div>
          <div className="row">
              <div className="col-lg-10 col-md-10 col-9"></div>
              <div className="col-lg-2 col-md-2 col-3">
              <img width={"75%"} className="img-fluid" style={{marginTop:"-50px"}} src="/system.png" alt="" />
              </div>
            </div>
        </div>
       
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12  mb-5 d-flex flex-column align-items-center">
            <img src="/s.png" className="img-fluid " style={{width:"45%"}} alt="" />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchReviews;

import React, { useEffect, useState, useRef } from "react";
import TopBar from "./TopNavbar";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { data } from "./source";
import { Helmet } from 'react-helmet';

const Counter = ({ value, showPlus, showPercentage, inView  }) => {
  const [count, setCount] = useState(0);
  const formatNumber = (number) => {
    const million = 1000000;
  
    if (number >= million) {
      const formattedNumber = Math.round(number / million);
      return `${formattedNumber}M`;
    }
  
    if (number >= 10000) {
      const formattedNumber = Math.round(number / 1000);
      return `${formattedNumber}K+`;
    }
  
    return number;
  };
  const formattedCount = formatNumber(count);

  useEffect(() => {
    if (inView) {
      const startCounting = () => {
        const step = value / 100;
        let currentCount = 0;
        const timer = setInterval(() => {
          if (currentCount >= value) {
            clearInterval(timer);
            currentCount = value;
          } else {
            currentCount += step;
          }
          setCount(Math.round(currentCount));
        }, 20);
      };

      startCounting();
    }
  }, [value, inView]);

  return (
    <p className="score_text">
      {formattedCount}
      {showPlus && count >= 10000 }
      {showPercentage && count < 100 && "%"}
    </p>
  );
};

const Home = (props) => {
  const [scores, setScores] = useState([
    112403870, 112403870, 112403870, 112403870,
  ]);
  const [management_cfg, setManagement_cfg] = useState([]);
  const [state_cfg, setState_cfg] = useState([]);
  const [credentials, setCredentials] = useState({
    address: '',
    city: '',
    state: '',
    zipCode: '',
    managementType: '',
  });
  let navigate = useNavigate();
 
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Only trigger once
  })
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger once
  })
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.2, // Element is considered in view when 50% visible
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none', 
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      
    }),
    
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: 'none', 
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black', 
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#7b7b7b', // Adjust the color as needed
      fontWeight:"400"
     
    }),
    
  };

  



  


  const handleReview = async (e) => {
    try {
      e.preventDefault();
      if (credentials.state === null || credentials.address === null || credentials.city === null || credentials.managementType === null) {
        props.showAlert("Please fill in all required fields", "error");
        return;
      }
      const response = await fetch(
        `${props.API_URL}/api/search/search_property_reviews`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: credentials.address,
            city: credentials.city,
            state: credentials.state,
            zipCode: credentials.zipCode,
            managementType: credentials.managementType,
          }),
        }
      );

      const json = await response.json();

      if (json.status === "success") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        console.log("local", localStorage.getItem("review_data"));
        navigate("/search_results");
      }
      else if(json.status==="new_added") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        navigate("/add_review");

      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      const errorMessage = error.message || "An error occurred while processing your request";
      props.showAlert(errorMessage, "error");
    }
  };

  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      console.log(credentials.state, credentials.address, credentials.city, credentials.managementType)
      if (credentials.state === null || credentials.address === null || credentials.city === null || credentials.managementType === null) {
        props.showAlert("Please fill in all required fields", "warning");
        return;
      }

      const response = await fetch(
        `${props.API_URL}/api/search/search_property_reviews`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: credentials.address,
            city: credentials.city,
            state: credentials.state,
            zipCode: credentials.zipCode,
            managementType: credentials.managementType,
          }),
        }
      );

      const json = await response.json();
      console.log(json);

      if (json.status === "success") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        console.log("local", localStorage.getItem("review_data"));
        navigate("/search_results");
      }
      else if(json.status==="new_added") {
        localStorage.removeItem("review_data");
        localStorage.setItem("review_data", JSON.stringify(json));
        navigate("/add_review");

      }
      
      else {
        navigate("/no_results");
      }
    } catch (error) {
      const errorMessage = error.message || "An error occurred while processing your search";
      props.showAlert(errorMessage, "error");
    }
  };

  useEffect(() => {
     // Fetch meta data from your API
     fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
     .then(response => response.json())
     .then(data => {
      const metaData = data.data[0];

      if(metaData){
        // Update title tag
        if(metaData.title){
          document.title = metaData.title + '|' + 'Home' || 'Default Title';

        }
          // Update meta description tag
      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
      }

      // Update meta keywords tag
      const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
      if (metaKeywordsTag) {
        metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
      }

      // Update canonical URL tag
      const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
      if (canonicalLinkTag) {
        canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
      }
      }

     })
     .catch(error => {
       console.error('Error fetching meta data:', error);
     });



    fetch(`${props.API_URL}/api/form/management_cfg`)
      .then((response) => response.json())
      .then((data) => {
        setManagement_cfg(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });


  }, []);

  useEffect(() => {
    fetch(`${props.API_URL}/api/form/state_cfg`)
      .then((response) => response.json())
      .then((data) => {
        setState_cfg(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  const onChange = (name, value) => {
    setCredentials({ ...credentials, [name]: value });
  };

  // let autocomplete='';
  // useEffect(() => {
  //   // Initialize Autocomplete when component mounts
  //    autocomplete = new window.google.maps.places.Autocomplete(
  //     document.getElementById('address'),
  //     {
  //       componentRestrictions: { country: ['us', 'ca'] },
  //       fields: ['address_components', 'geometry'],
  //       types: ['address'],
  //     }
  //   );

  //   autocomplete.addListener('place_changed', fillInAddress);

  //   return () => {
  //     // Clean up Autocomplete listeners
  //     window.google.maps.event.clearInstanceListeners(autocomplete);
  //   };
  // }, []);

  // const fillInAddress = () => {
  //   const place = autocomplete.getPlace();
  //   let address1 = '';
  //   let city = '';
  //   let state = '';
  //   let zipCode = '';

  //   for (const component of place.address_components) {
  //     const componentType = component.types[0];

  //     switch (componentType) {
  //       case 'street_number':
  //         address1 = `${component.long_name} ${address1}`;
  //         break;
  //       case 'route':
  //         address1 += component.short_name;
  //         break;
  //       case 'locality':
  //         city = component.long_name;
  //         break;
  //       case 'administrative_area_level_1':
  //         state = component.short_name;
  //         break;
  //       case 'postal_code':
  //         zipCode = component.long_name;
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   setCredentials({
  //     ...credentials,
  //     address: address1,
  //     city: city,
  //     state: state,
  //     zipCode: zipCode,
  //   });
  // };

  function generateURL() {
    const baseURL = 'https://manorlane.co/learn/';
    const token = localStorage.getItem("token"); 
    
    if (localStorage.getItem("token")) {
        // If the user is logged in, append the token as a URL parameter
        return `${baseURL}?token=${token}`;
    } else {
        // If the user is not logged in, simply return the base URL without the token parameter
        return baseURL;
    }
}
  return (
    <>
     
      <TopBar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div  style={{ width: "100%",overflowX:"hidden" }}>
        <div
          className="container-fluid lp_first_div"
          style={{
            
          }}
        >
          <div>
          
            <div className="row ml-3 pt-5 mr-3">
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 mt-3" >
                <h2 id="empowering">Empowering</h2>
                
                <h2 id="residents">Renters & Residents</h2>
                <p id="info_text" ref={ref1}>
                  We are a tenant-focused portal where <mark className={inView1 ? "highlight" : "mark"}>reviews</mark>,{" "}
                  <mark className={inView1 ? "highlight" : "mark"}>community</mark>, and <mark className={inView1 ? "highlight" : "mark"}>learning</mark> band
                  together for the greater good.
                </p>
                <a href="#search_reviews_div_home">
                  <div className="rectangle-parent mt-4">
                    <button className="review_search_btn btn">
                      Review and Search*
                    </button>
                    <br />
                    <br />
                  </div>
                </a>
                <p className="no_account_required">
                  *No account required and completely anonymous.
                </p>{" "}
              </div>
              <div className="col-lg-1 col-md-1 col-12s"></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1  col-12 ">
                <img
                  src="/g.gif"
                  className="img-fluid homepage_gif1 pl-lg-3"
                  style={{
                    marginTop: "-10px",
              
                  }}
                  alt=""
                />{" "}
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid"  style={{ backgroundColor: "#DFFBED",  }}>
          <div className="row  ml-3 mr-3 " >
            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12 col-12 " >
              <img
                src="/community.gif"
                
                width={"100%"}
                style={{paddingTop:"130px"}}
                

                alt=""
              />{" "}
            </div>
            <div className="col-lg-1 col-md-1 col-12"></div>
            <div  className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 mt-1">
              <h2 id="community_support" className="">
                Community & Support
              </h2>
              <p id="community_support_text"  >
                A <mark ref={ref2} className={inView2 ? "highlight" : "mark"}>
                social space*
              </mark>
              {" "}
                where Renters, HOA members and
                other residents gather for connection, mutual support and
                camaraderie.
              </p>

              <br />
              <p id="community_support_text2">
                *An account is required to participate in private Communities.
                No account required for public Communities. All free and
                confidential, of course.
              </p>
              <Link to="/communities">
                <button className="community_button_lp btn mt-lg-2 mt-md-0 mt-2 mb-3  mb-lg-0 mb-md-0 ">
                  Join a Community
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="container-fluid lp_third_div"
         
          
        >
          <div className="row ml-3 mr-3">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 mt-lg-4  mt-md-0 mt-4 "
             id="stay_informed_div"
            
             >
              <h2 id="empowering" style={{ marginTop: "20%",marginBottom:"1rem" }}>
                Stay Informed
              </h2>
              <br />
              <p id="info_text"   >
                Explore and share <mark ref={ref} className={inView ? "highlight share" : "mark explore"}>
                  resources 
                </mark>
                {" "}
                and guides  for tenants’
                and residents’ rights 
                 and advocacy.
              </p>
              <a href={generateURL()} target="blank">
                <button className="learning_blog_btn btn mt-lg-0 mt-md-0 mt-2  ">
                  Learning Forum
                </button>
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 col-12"></div>
            <div className="col-lg-6 mt-lg-0 mt-md-5 mt-0 col-md-6 col-sm-12 col-xs-12 col-12" id="stay_informed_image">
                <img
                  src="/uu.png"
                  className="img-fluid"
                  width={"700px"}
                  style={{ marginLeft: '-20px' }}
                  alt=""
                />
             
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ background: "#F9F9F9" }}>
          <div className="row pt-3 px-3 " ref={ref}>
            {data.map((score, index) => (
              
              <div
                className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 pt-2 text-center"
                id="score_div"
                key={index}
                
              >
                <img
                  src={`/g${index + 1}.png`}
                  style={{ height: "30%" }}
                  alt=""
                />
                <hr />

                        {inView && <Counter className="score" value={parseInt(score.number)} showPlus={index === 1} showPercentage={index === 3} inView={inView} />}

                <p className="score_para">
                 {score.text}
                </p>

                <p className="score_info mt-5 ">
                {score.info}
                </p>
              </div>
            ))}
          </div>
        </div>

        <form action="">
          <div className="container-fluid" id="search_reviews_div_home">
            <div className="row ml-lg-5 ml-md-5 ml-3 mr-3">
              <div className="col-12 mt-5">
                <h6 id="search_reviews_heading">Review a Property</h6>{" "}
                <br />
              </div>
            </div>
            <div className="row ml-lg-5 ml-md-5 ml-3 mr-3">
              <div className="col-lg-3 col-md-3 col-sm-12 mr-lg-3 mr-md-3  col-xs-12 col-12 ">
                <div className="form-group">
                  <label className="search_form_label" htmlFor="address">
                    Address*{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control search_form_placeholder"
                    required
                    id="address"
                    name="address"
                    value={credentials.address}
                    onChange={(e) => onChange("address", e.target.value)}
                    placeholder="Type in address of your residence"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 ml-lg-5  mr-lg-3 mr-md-3 ml-md-5 col-sm-12 col-xs-12 col-12">
                <div className="form-group">
                  <label className="search_form_label" htmlFor="city">
                    City*{" "}
                  </label>
                  <img width={"40px"} style={{marginLeft:"60%", marginTop:"-10px"}} src="/city.png" alt="" />

                  <input
                    type="text"
                    className="form-control search_form_placeholder"
                    required
                    id="city"
                    name="city"
                    value={credentials.city}
                    onChange={(e) => onChange("city", e.target.value)}
                    placeholder="City"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 ml-lg-5 ml-md-5  mr-lg-5 mr-md-5 col-sm-6 col-xs-6 col-6">
              <div className="form-group" id="search_form_dropdown_state">
                <label htmlFor="state" className="search_form_label">
                  State*
                </label>
                <br />
                <Select
                  className="state-select search_form_dropdowns"
                  isSearchable
                  placeholder="Select State"
                  value={credentials.state ? { label: credentials.state, value: credentials.state } : null}
                  options={state_cfg && state_cfg.length > 0 ? state_cfg.map((option) => ({ value: option.Name, label: option.Name })) : []}
                  onChange={(selectedOption) => onChange('state', selectedOption.value)}
                  styles={customStyles} // Apply the custom styles
                />
                 
              </div>
              </div>
              <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6 col-6 ml-lg-5 ml-md-5  mr-lg-3 mr-md-3">
                <div className="form-group">
                  <label className="search_form_label" htmlFor="zip">
                    Zip{" "}
                  </label>
                  <input
                    type="dropdown"
                    className="form-control search_form_placeholder"
                    id="zip"
                    name="zipCode"
                    value={credentials.zipCode}
                    // onChange={onChange}
                    onChange={(e) => onChange("zipCode", e.target.value)}
                    placeholder="Zip Code"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2 ml-lg-5 ml-md-5 ml-3 mr-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="form-group" id="search_form_dropdown_management">
                <label htmlFor="management" className="search_form_label">
                  Management Type*
                </label>
                <Select
                  className="management-select search_form_dropdownm"
                  isSearchable
                  placeholder="Select Management Type"
                  value={credentials.managementType ? { label: credentials.managementType, value: credentials.managementType } : null}
                  options={management_cfg && management_cfg.length > 0 ? management_cfg.map((option) => ({ value: option.Name, label: option.Name })) : []}
                                    onChange={(selectedOption) => onChange('managementType', selectedOption.value)}
                  styles={customStyles} 
                />
              </div>
              </div>

              <div className="col-lg-5 col-md-5 ml-lg-4 ml-md-4  col-sm-12 col-xs-12 col-12">
                <div className="form-group" style={{ marginTop: "2px" }}>
                  <label className="search_form_label " htmlFor="city">
                    Name of PMC or HOA{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control search_form_placeholder"
                    id="city"
                    placeholder="If PMC or HOA, type in name"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4  ml-lg-5 ml-md-5 ml-3 mr-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <p id="transparency_text">
                  At last! Transparency and accountability to Property Managers,
                  HOA’s and Landlords for all residents.
                </p>
              </div>
              <div className="col-lg-5 col-md-5 col-12 d-flex ">
                <div className="col-lg-5 mr-lg-5 mr-md-5 ml-lg-2 ml-md-2 col-md-5 col-sm-5 col-xs-5 col-sm-5 col-5 " >
                  <button
                    type="submit"
                    className="search_form_search_btn btn pl-lg-5  pl-md-5 pr-lg-5 pr-md-5"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <p  className="see_property pl-lg-2 pl-md-2 pl-1 "> See if the property has been reviewed.</p>


                </div>

                <div className="col-lg-5 col-md-5 col-sm-12 col-xs-5 col-sm-5 col-5 ml-4 mb-5">
                  <button
                    type="submit"
                    className="search_form_review_btn btn pl-lg-5  pl-md-5 pr-lg-5 pr-md-5"
                    onClick={handleReview}
                  >
                    Review
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "-30px" }}>
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12"></div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12 ml-lg-0 ml-md-0 ml-4"
                style={{ marginTop: "-60px" }}
              >
                <p className="save_track_reviews">
                  Want to save and track your reviews?&nbsp;
                  <Link to="/login" style={{cursor:"pointer"}}>Login here.</Link>
                </p>
              </div>
              
            </div>
            <div className="row">
              <div className="col-lg-10 col-md-10 col-9"></div>
              <div className="col-lg-2 col-md-2 col-3">
              <img width={"70%"} className="img-fluid" style={{marginTop:"-20px"}} src="/system.png" alt="" />
              </div>
            </div>
          </div>
        </form>
        <div className="container-fluid pt-5 "
          style={{
            backgroundImage: `url('/rb.png')`,
            backgroundSize: "100% 130%",
            marginBottom: "0px",
            marginTop: "2rem",
            paddingBottom:"90px",
            paddingTop:"50px"
          }}>
          <div className="row ml-lg-5 ml-md-5 ml-3 mr-3 pt-5">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 ml-lg-3 ml-md-3 dummy_review mt-3" style={{backgroundColor:"white"}}>
              <img src="/quote.svg" width={"90px"} alt="" style={{marginTop:"-50px",marginLeft:"-30px"}} />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <hr style={{marginLeft:"50%",marginTop:"1px"}} />
              <p className="dummy_review_text">My landlord is slow to respond to maintenance requests, and the property is not cleaned regularly. He was much more reliable when I first moved in. For the amount of rent I pay, I would expect better care and attention. Wish I knew all this before moving in.</p>
              <p className="dummy_review_author"> Alan <br /> Austin, TX</p>

            </div>
            <di className="col-1"></di>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 dummy_review mt-3" style={{backgroundColor:"white"}}>
            <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
                 <br />
              <hr style={{marginLeft:"50%"}} />
              <p className="dummy_review_text">Our HOA is constantly coming up with new rules to try to control what we do on our own property. Eg, what I can hang on my window or flowers I plant. And they keep raising our monthly fees! I am thinking about joining the HOA board to make some changes!</p>
              <p className="dummy_review_author"> Sanjit <br />  San Mateo, CA</p>



            </div>
            
            <div className="col-1"></div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 dummy_review mt-3" style={{backgroundColor:"white"}}>
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />
              <img className="pt-3 pl-2" src="/gold.png" style={{float:"right"}} width={"25px"} alt="" />

              <br />
              <hr style={{marginLeft:"50%"}} />
              <p className="dummy_review_text">I have lived in many prop management-owned buildings before and most are just faceless and apathetic. This team is really the best! They respond quickly, very organized and super friendly office staff.  And the tech they use for requests, etc. is top-notch.</p>
              <p className="dummy_review_author"> Rebecca <br /> Jacksonville, FL</p>
              <img src="/quote2.svg" width={"90px"} alt="" style={{marginTop:"-20px",float:"",marginBottom:"-45px",marginLeft:"80%"}} />



            </div>
            
          </div>
       
        </div>
        
        <div className="container-fluid" style={{ backgroundColor: "#DFFBED" }}>
          <div className="row  ml-3 mr-3" style={{ paddingTop: "15px" }}>
            <div className="col-lg-2 col-md-2 col-sm-12 colxs-12 col-12 text-center">
              <img src="/voice.png" className="voice_pic" width={"80%"}  alt="" />
            </div>
            <div
              className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12"
              style={{ textAlign: "center" }}
            >
              <h2 id="empowering" className="mt-5 pt-5 mb-5 mtewmp">
                Giving Tenants a Voice
                <br></br>
             

              </h2>
              <p id="info_text" className="px-5">
              <br></br>  Our platform strives to make property owners accountable, and
                allow residents a safe place for open dialogue.
                
                Renters, HOA members and all tenants should be able to share,
                protect and connect confidently and comfortably. <br />
                <br />
              </p>

              <br />
            </div>
            <div className="col-md-2 col-lg-2 col-sm-12 colxs-12 col-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
const data = [
    {
      number: "112403870",
      text: "Renters in the US who demand to be heard...",
      info:"*Census.gov (2022)"
    },
    {
        number: "10000+",
        text: "Lawsuits against HOA’s in the US each year...",
        info:"*Community Associations Institute (2022)"
    },
    {
        number: "48240550",
        text: "Residential homes & buildings managed by PMC’s",
        info:"*iPropertyManagement.com (2022)"
    },
    {
        number: "71%",
        text: "Renters who would pay more to rent from a property with positive reviews",
        info:"*Rent.com (2022)"
    },
  ];
  export {data};
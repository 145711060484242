import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { format } from "timeago.js";
import InputEmoji from "react-input-emoji";
import notificationsound from "../sound/ting.mp3"
import "@fortawesome/fontawesome-free/css/all.css";

const ChatBox = ({ chat, currentUser, setSendMessage, receivedMessage, API_URL, setChatOpen }) => {
  const [userData, setUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  


  const handleBackClick = () => {
    setChatOpen(false);
  };
  const handleChange = (newMessage) => {
    setNewMessage(newMessage);
  };

  // fetching data for header
  useEffect(() => {
    const otherUserId =
      chat?.member1Id?.toString() === currentUser?.toString()
        ? chat?.member2Id?.toString()
        : chat?.member1Id?.toString();

    const getUserData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/user/info/${otherUserId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const result = await response.json();

        if (result.status === "success") {
          const fetchedUserData = result.data[0];
          setUserData(fetchedUserData);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (chat !== null) getUserData();
  }, []);

  // fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(
          `${API_URL}/api/messages/fetch_messages/${chat.ID}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setMessages(data);
        console.log("data of messages are", data);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) fetchMessages();
  }, [chat, messages, API_URL]);

  const scrollContainer = useRef();
  const userScrolledUp = useRef(false);

  // Always scroll to the last message when new messages arrive
  useEffect(() => {
    if (!userScrolledUp.current && scrollContainer.current) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
    }
  }, [messages,chat]);

  const handleScroll = () => {
    const scrollThreshold = 4; // You can adjust this threshold as needed

    if (
      scrollContainer.current.scrollTop +
        scrollContainer.current.clientHeight ===
      scrollContainer.current.scrollHeight
    ) {
      userScrolledUp.current = false;
    } else {
      userScrolledUp.current = true;
    }

    // Check if the user has scrolled up more than the threshold
    if (scrollContainer.current.scrollTop <= scrollThreshold) {
      userScrolledUp.current = true;
    }
  };

  
  const handleEnter = (event, newInputValue) => {    
    console.log('Enter key pressed, value:', newInputValue);
    setNewMessage(newInputValue);

    handleSend(); //
  };
  const handleSend = async (e) => {
    if(e){
      e.preventDefault();

    }

    if (!chat) {
      // Handle the case where chat is null
      console.error("Chat is null. Cannot send a message.");
      return;
    }
    if (newMessage === null || newMessage === "") {
      return;
    }
    // Constructing Message Object
    const message = {
      senderId: currentUser,
      text: newMessage,
      chatId: chat?.ID,
    };

    // Finding Receiver ID
    const receiverId =
      chat?.member1Id?.toString() === currentUser?.toString()
        ? chat?.member2Id?.toString()
        : chat?.member1Id?.toString();

    // Sending Message to Socket Server
    setSendMessage({ ...message, receiverId });

    try {
      // Sending Message to Database using Fetch API
      const response = await fetch(
        `${API_URL}/api/messages/send_message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(message),
        }
      );
      console.log("response is ", response);
      if (!response.ok) {
        throw new Error(`Failed to add message: ${response.status}`);
      }

      // Assuming the server responds with JSON data
      const { status, result } = await response.json();

      if (status === "success") {
        // Update local state for UI responsiveness
        
        setNewMessage("");
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, result];
          console.log("Updated Messages:", updatedMessages);
          return updatedMessages;
        });
      } else {
        console.error("Failed to add message:", result);
      }
    } catch (error) {
      console.error("Error adding message:", error.message);
      console.log("Error details:", error);
    }
  };
  useEffect(() => {
    console.log("Message Arrived: ", receivedMessage);
    if (receivedMessage !== null && receivedMessage.chatId === chat?.ID) {
     
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    }
  }, [messages , chat?.ID, currentUser, receivedMessage]);



  return (
    <>
      <div className="ChatBox-container pt-1 col-12">
        {chat ? (
          <>
            {/* chat-header */}
            <div className="chat-header">
            <div className='d-flex mt-2'>
            <i className="fa fa-arrow-left px-2" style={{fontSize:"24px",cursor:"pointer"}} onClick={handleBackClick}></i>
                <div className='user_profile_messages_div'>
               
             
 
                
                <img
                  src={ userData?.profile || "user.png"}
                  alt="Profile"
                  className="followerImage mr-1"
                  
                />
                
                  </div>
                <div className="contact_name mt-2 " >
                  <span>{userData?.L_name}</span>

                </div>
              </div>



              <hr
                style={{
                  width: "95%",
                  border: "0.1px solid #ececec",
                  marginTop: "8px",
                }}
              />
            </div>
            {/* chat-body */}
            <div
              className="chat-body"
              ref={scrollContainer}
              onScroll={handleScroll}
            >{Array.isArray(messages) && messages.length > 0 ? (
              messages.map((message) => (
                <>
                <div
                  key={message.ID}
                  className={
                    message?.senderId?.toString() === currentUser?.toString()
                      ? "own"
                      : "message"
                  }
                >
                  <span className="message_text">{message?.text}</span> <br />
                </div>
                 <span className={
                    message?.senderId?.toString() === currentUser?.toString()
                      ? "owned date"
                      : "other date"
                  } >{format(message?.created_at)}</span>
                 </>
                
                 

               
              ))
            ) : (
          <div style={{backgroundColor:"rgb(173, 173, 173)",marginTop:"100%",borderRadius:"23px", height:"10%"}}>
              <p className="no_msg" style={{textAlign:"center"}}>No messages yet. Start a conversation!</p>
            </div>
            )}
            
            </div>
            <div className="chat-sender">
              <InputEmoji
                value={newMessage}
                className="input_emoji"
                onChange={handleChange}
                onEnter={handleEnter}
    
              />
             
              <div
                style={{
                  color: "blue",
                  marginLeft: "-40px",
                  cursor: "pointer",
                  fontSize: "18px",
                }}
                onClick={handleSend}
              >
                <img src="send.png" width={"25px"} alt="" />
              </div>
            </div>{" "}
          </>
        ) : (
          <div style={{marginTop:"50%",textAlign:"center"}}>
          <span className="chatbox-empty-message">
            Tap on a chat to start a conversation...
          </span>
          <img src="direction.png" width={"150px"} alt="" />

          </div>
        )}
      </div>
    </>
  );
};

export default ChatBox;

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { format } from "timeago.js";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

function Preloader() {
  return (
    <>
      <div
        className="d-flex justify_content-center"
        style={{ marginLeft: "45%" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
}
const CommunityPage = (props) => {
  const [communityPosts, setcommunityPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [joined, setJoined] = useState(false);
  const ref = useRef(null);
  const refinvite = useRef(null);
  const refcloseinvite = useRef(null);

  const refClose = useRef(null);
  const refEdit = useRef(null);
  const refClosecreatecommunity=useRef(null);
  const refCloseEdit = useRef(null);
  const [comments, setComments] = useState([]);
  const commentInputRef = useRef(null);
  const [postComments, setPostComments] = useState({});
  const [display, setDisplay] = useState({});
  const [communityInfo, setCommunityInfo] = useState([]);
  const [sending, setSending] = useState(false);
  const [invitesending, setinviteSending] = useState(false);
  const rededitcommunity = useRef(null);
  const [communityCredentials, setcommunityCredentials] = useState({
    communityName: "",
    description: "",
    image: null,
  });
  const [updated, setUpdated] = useState("");
  const [post_id, setUpdate_id] = useState("");
  const [likes, setLikes] = useState([]);
  const [file, setFile] = useState(null);
  const [isImageModified, setIsImageModified] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [invitecredentials, setinviteCredentials] = useState({
    name: "",
    email: "",
  });
  const [invite,setInvite]=useState(false);

  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const location = useLocation();


  const handleDropdownToggle = (reviewID) => {
    setVisibleDropdown(visibleDropdown === reviewID ? null : reviewID);
  };

  const style = {
    fontSize: "26px",
  };
  const [thumbnail, setThumbnail] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Read the file as a data URL and set it as the thumbnail
    if (selectedFile) {
      setFile(selectedFile);
      setIsImageModified(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        setThumbnail(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  const handlecommunityFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Read the file as a data URL and set it as the thumbnail
    if (selectedFile) {
      setcommunityCredentials(prevCredentials => ({
        ...prevCredentials,
        image: selectedFile
      }));
      setIsImageModified(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        setThumbnail(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setcommunityCredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCancel = () => {
    setThumbnail(null);
    setFile(null);
  };
  const handleCommunityCancel=()=>{
    setcommunityCredentials({image:null})
    setThumbnail(null)
  }

  const editcommunity = async (e) => {
   e.preventDefault();
   setinviteSending(true)
    let selectedfile = communityCredentials.image;
    if (isImageModified && communityCredentials.image) {
      try {
        const { url } = await fetch(`${props.API_URL}/CreateCommunity/s3Url`).then(
          (res) => res.json()
        );

        // Post the image directly to the S3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: communityCredentials.image,
        });

        const imageUrl = url.split("?")[0];
        selectedfile = imageUrl;
      } catch (error) {
        console.error("Error fetching S3 URL:", error);
      }
    }

    try {
      const url = `${props.API_URL}/api/community/edit_community/${id}`;

      const communityEditdata = {};

      communityEditdata.name = communityCredentials.communityName;
      communityEditdata.desc = communityCredentials.description;
      communityEditdata.image=selectedfile
      console.log(communityEditdata);

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(communityEditdata),
      });

      const json = await response.json();
      setinviteSending(false)
      refClosecreatecommunity.current.click()
      if (json.status === "success") {
        setCommunityInfo(prevCommunityInfo => ({
          ...prevCommunityInfo,
          Name: communityCredentials.communityName,
          Description: communityCredentials.description,
          Profile_img: selectedfile
        }));
        
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };
  const handleeditCancel=(e)=>{
    e.preventDefault()
    setFile(null)
    setIsImageModified(true)
  }
  const [displayedComments, setDisplayedComments] = useState(5);

  const handleViewMore = () => {
    setDisplayedComments(displayedComments + 5);
  };

  let navigate = useNavigate();

  const [credentials, setcredentials] = useState({
    post_text: "",
  });

  const observer = useRef(null);
  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      fetchPosts(0);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const invite_param = searchParams.get('invite');
    if(invite_param){
      setInvite(invite_param)

    }
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    const target = document.querySelector("#intersection-target");
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [hasMore]);

  const addLike = async (postId,post_text) => {
    try {
      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/post/"+postId);
          }
        }); 

        return;
      }
      const response = await fetch(`${props.API_URL}/api/post/like_post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          postId: postId,
          post_text:post_text
        }),
      });
      const json = await response.json();
      if (json.status === "success") {
        if (!likes.includes(postId)) {
          setLikes([...likes, postId]);
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count + 1 }
                : post
            )
          );
        } else {
          setLikes(likes.filter((id) => id !== postId));
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count - 1 }
                : post
            )
          );
        }
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentKeyPress = async (e, PID, index,post_text) => {
    if (e.key !== "Enter") {
      return;
    }

    e.preventDefault();

    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/post/"+PID);
        } 
      }); 

      return;
    }

    if (comments[index] === "") {
      props.showAlert("Invalid input.", "warning");
      return;
    }

    try {
      const response = await fetch(
        `${props.API_URL}/api/post/add_comment/${PID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            comment_text: comments[index],
            post_text:post_text
          }),
        }
      );

      const json = await response.json();

      if (json.status === "success") {
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: "none",
        }));
        await fetchCommentsForPost(PID);
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: !prevDisplay[PID] ? "block" : "none",
        }));
        setcommunityPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.ID === PID
              ? { ...post, comments_count: post.comments_count + 1 }
              : post
          )
        );
      } else {
        props.showAlert(json.message, "error");
      }

      const updatedComments = [...comments];
      updatedComments[index] = "";
      setComments(updatedComments);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentChange = (e, index) => {
    const updatedComments = [...comments];
    updatedComments[index] = e.target.value;
    setComments(updatedComments);
  };

  const fetchCommentsForPost = async (postId) => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/post/fetch_comments/${postId}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setPostComments((prevpostComments) => ({
          ...prevpostComments,
          [postId]: data.data,
        }));
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [postId]: !prevDisplay[postId],
        }));
        setDisplayedComments(5);
      } else {
        return [];
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
      return [];
    }
  };
  const commeditpopup = (name,desc,image) => {
    const authToken = localStorage.getItem("token");

    if (!authToken) {
      navigate("/login?redirect=/communities");
      return;
    } else {
      setcommunityCredentials({communityName: name , description : desc , image: image});
      rededitcommunity.current.click();
    }
  };

  const popup = () => {
    const authToken = localStorage.getItem("token");

    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/community_page/"+id);
        }
      }); 

      return;
    }

    if (joined) {
      ref.current.click();
    } else {
      props.showAlert(
        "Please join the community first to post something.",
        "warning"
      );
    }
  };

  const invitepopup = () => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/community_page/"+id);
        } 
      }); 
    } else {
      refinvite.current.click();
    }
  };

  const invitehandleSubmit = async (id,cname) => {
    if (invitecredentials.name === "" || invitecredentials.email === "") {
      props.showAlert("Please Fill fields first.", "warning");
      return;
    }
    console.log(cname,id,invitecredentials.name,invitecredentials.email);
    setinviteSending(true)
    const response = await fetch(`${props.API_URL}/api/contact/invite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name: invitecredentials.name,
        email: invitecredentials.email,
        cid:id,
        cname:cname,
        community_join_invite:true
      }),
    });
    const json = await response.json();
    setinviteSending(false);
    refClose.current.click();
    if (json.status === "success") {
      props.showAlert(json.message, json.status);
    } else {
      props.showAlert(json.message, "error");
    }
  
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (credentials.post_text === "") {
      props.showAlert("Please fill in the field first.", "warning");
      return;
    }
    let selectedfile = null;
    setSending(true);
    if (file) {
      try {
        const { url } = await fetch(`${props.API_URL}/AddPost/s3Url`).then(
          (res) => res.json()
        );

        // Post the image directly to the S3 bucket
        const uploadResponse = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });

        // Successful upload
        const imageUrl = url.split("?")[0];
        selectedfile = imageUrl;
        console.log(uploadResponse);
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
      }
    }

    const postData = {};

    postData.post = credentials.post_text;
    postData.image = selectedfile;

    

    try {
      const data = await fetch(
        `${props.API_URL}/api/community/add_post/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(postData),
        }
      );

      const json = await data.json();
      
      setcredentials({ post_text: "" });
      setThumbnail(null)
      setFile(null)
      if (json.status === "success") {
        console.log("post added");
        
        fetchPosts(1);
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
     
      props.showAlert("Internal Server Error", "error");
    } finally {
      setSending(false);
      refClose.current.click();
    }
  };

  const confirmDelete = (id, image) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1E81C4",
      cancelButtonColor: "#d33",
      cancelButtonText: "Close",

      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id, image);
      }
    });
  };
  const onDelete = async (id, image) => {
    console.log("hi", image);
    if (image) {
      try {
        const imageName = image.split("/").pop();
        const s3Key = `${imageName}`;
        const response = await fetch(
          `${props.API_URL}/delete/AddPost/${s3Key}/s3Url2`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        if (response.ok) {
          console.log("Image deleted successfully");
        } else {
          props.showAlert("Error in deleting Post", "error");
          return;
        }
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
        return;
      }
    }
    try {
      const url = `${props.API_URL}/api/community/delete_my_post/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });

      const json = await response.json();
      if (json.status === "success") {
        const updatedposts = communityPosts.filter((post) => post.ID !== id);
        setcommunityPosts(updatedposts);
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  // Edit a post
  const editPost = async (id, text, image, isImageModified) => {
    console.log(id, text, image);
    let selectedfile = image;
    if (isImageModified && image) {
      try {
        const { url } = await fetch(`${props.API_URL}/AddPost/s3Url`).then(
          (res) => res.json()
        );

        // Post the image directly to the S3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: image,
        });

        const imageUrl = url.split("?")[0];
        selectedfile = imageUrl;
      } catch (error) {
        console.error("Error fetching S3 URL:", error);
      }
    }

    try {
      const url = `${props.API_URL}/api/community/update_my_post/${id}`;

      const postEditData = {};

      postEditData.post = text;
      postEditData.image = selectedfile;
      console.log(postEditData);

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(postEditData),
      });

      const json = await response.json();
      if (json.status === "success") {
        const updatedPosts = communityPosts.map((post) =>
          post.ID === id ? { ...post, Text: text, Image: selectedfile } : post
        );
        setcommunityPosts(updatedPosts);
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const updatePost = (id, text, image) => {
    refEdit.current.click();
    setUpdated(text);
    setUpdate_id(id);
    setFile(image);
    setIsImageModified(false);
  };

  const createChat = async (userid) => {
    try {
      const data = await fetch(`${props.API_URL}/api/chats/create_chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          receiverId: userid,
        }),
      });

      const json = await data.json();
      if (json.status === "success") {
        console.log(json.message);
        navigate("/messages");
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      setSending(false);
      props.showAlert("Internal Server Error", "error");
    }
  };

  let pageno = 1;
  const fetchPosts = async (value) => {
    try {
      // Fetch community posts
      const responsePosts = await fetch(
        `${props.API_URL}/api/community/fetch_community_posts/${id}?page=${pageno}&value=${value}`
      );
      setLoading(true);
      const dataPosts = await responsePosts.json();
      if (dataPosts.status === "success") {
        const dataa = dataPosts.data;
        if (dataa.length === 0) {
          setHasMore(false);
          setLoading(false);
        } else {
          if(dataPosts.new){
            setcommunityPosts((communityPosts) => [...dataa, ...communityPosts]);
            console.log(22222,communityPosts)
            console.log(33333,dataa)
  
            setLoading(false);
            pageno = pageno + 1;
          }
          else{
            setcommunityPosts((communityPosts) => [...communityPosts, ...dataa]);
            console.log(22222,communityPosts)
            console.log(33333,dataa)
  
            setLoading(false);
            pageno = pageno + 1;
          }
          
        }
      }
      setLoading(false);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const fetchData = async () => {
    try {
      if (localStorage.getItem("token")) {
        // Fetch information about community membership
        const url = `${props.API_URL}/api/community/check_community_member/${id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        });

        const data = await response.json();
        console.log(data);
        if (data.member === "true") {
          setJoined(true);
          if (data.admin === "true") {
            setAdmin(true);
          }
        }
      } else {
        setLoading(false);
      }
      const responseCommunity = await fetch(
        `${props.API_URL}/api/community/community_info/${id}`
      );
      const dataCommunity = await responseCommunity.json();
      setCommunityInfo(dataCommunity.data[0]);
      if(!invite===true){
        if (communityInfo.accessability === "private" && !joined  ) {
          navigate("/communities");
        }
      }
     
    } catch (error) {
      setJoined(false);
      setLoading(false);
    }
  };

  const likesData = async () => {
    try {
      if (localStorage.getItem("token")) {
        const likeResponse = await fetch(
          `${props.API_URL}/api/post/fetch_liked_posts/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        const likedData = await likeResponse.json();

        if (likedData.status === "success") {
          const postIds = likedData.data.map((item) => item.community_post_id);
          setLikes(postIds);
        }
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  useEffect(() => {
    likesData();
    fetchData();
  }, [communityPosts]);

  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };
const inviteOnchnage=(e)=>{
  setinviteCredentials({...invitecredentials,[e.target.name]:e.target.value})
}
  const onchange = (e) => {
    setUpdated(e.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    editPost(post_id, updated, file, isImageModified);
    refCloseEdit.current.click();
  };

  const join_community = async (communityId) => {
    try {
      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/community_page/"+communityId);
          } 
        }); 

        return;
      }
      if (joined) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",

          confirmButtonText: "Yes, Leave community!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await fetch(
              `${props.API_URL}/api/community/join_leave_community/${communityId}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "auth-token": localStorage.getItem("token"),
                },
              }
            );
            const json = await response.json();
            if (json.status === "success" && json.action === "joined") {
              props.showAlert(json.message, json.status);
              setJoined(true);
            } else if (json.status === "success" && json.action === "left") {
              navigate("/communities");
              setJoined(false);
            } else {
              props.showAlert(json.message, "error");
            }
          } else {
            return;
          }
        });
      } else {
        const response = await fetch(
          `${props.API_URL}/api/community/join_leave_community/${communityId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        const json = await response.json();
        if (json.status === "success" && json.action === "joined") {
          props.showAlert(json.message, json.status);
          setJoined(true);
        } else if (json.status === "success" && json.action === "left") {
          navigate("/communities");
          setJoined(false);
        } else {
          props.showAlert(json.message, "error");
        }
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const renderPostContent = (text) => {
    // Check if the post contains any text
    if (!text) {
      return null;
    }

    // Regular expression to find URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace plain text URLs with clickable HTML links
    const formattedText = text.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

    return (
      <p dangerouslySetInnerHTML={{ __html: formattedText }} />
    );
  };

  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Community' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="communities_comp mb-5">
        <div className=" my_reviews_top_div ml-1 mr-1 mt-3" style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="row pl-4 pr-2">
            <div className="col-lg-2 col-md-2 col-12 d-flex justify-content-center align-items-center mt-lg-0 mt-md-0 mt-4">
              <div className="community_profile_pic">
                <img src={communityInfo?.Profile_img || "c1.png"} alt="" />
              </div>
            </div>
            <div
              className=" col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12 mt-4 mb-2 "
              style={{ marginLeft: "-2%" }}
            >
              <div className="">
                <div>
                  <h4 className="communities_head mt-4">
                    {communityInfo?.Name} &nbsp;
                  </h4>
                </div>
               

                {admin ? (
                  <>
                  <div className="d-flex">
                  <p
                      style={{
                        cursor: "pointer",
                        color: "#1E81C4",
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "1000",
                        fontSize: "10px",
                      }}
                      onClick={() => join_community(id)}
                    >
                      Leave Community
                    </p>
                    
                    <span className="px-1" style={{ fontSize: "20px", color: "#1E81C4",marginTop:"-7px" }}>&#8226;</span>

                    <p
                      
                      style={{
                        cursor: "pointer",
                        color: "#1E81C4",
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "1000",
                        fontSize: "10px",
                      }}
                      onClick={() => invitepopup()}
                    >
                      Invite friend
                    </p>
                    <span className="px-1" style={{ fontSize: "20px", color: "#1E81C4" ,marginTop:"-7px"}}>&#8226;</span>


                    <p
                      
                      style={{
                        cursor: "pointer",
                        color: "#1E81C4",
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "1000",
                        fontSize: "10px",
                      }}
                      onClick={() => commeditpopup(communityInfo.Name, communityInfo.Description,communityInfo.Profile_img)}
                    >
                      Edit info
                    </p>
                  </div>
                   
                   
                  </>
                ) : (
                  <p
                    
                    style={{
                      cursor: "pointer",
                      color: "#1E81C4",
                      fontFamily: "'Lato',sans-serif",
                      fontWeight: "1000",
                      fontSize: "10px",
                    }}
                    onClick={() => join_community(id)}
                  >
                    {joined ? "Leave Community" : "Join Community"}
                  </p>
                )}
              </div>

              <p className="communities_page_intro1 pr-1 ">
                {communityInfo?.Description}
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
          <div className="col-8"> 
            <h4 className="mt-5 ml-1" id="comm_feed">
          Community Feed
        </h4></div>
            <div className="col-4 d-lg-none d-md-none d-block">
            
              <button
                type="button"
                className="btn btn-light add-post-btn btn-block"
                onClick={popup}
              >
                 Add Post
              </button>
          
            </div>
           
          </div>


        </div>
       
        {(Array.isArray(communityPosts) && communityPosts.length > 0) ||
        loading ? (
          communityPosts.map((post, index) => (
            <div
              id="comm_feed_posts"
              className="row mb-3 ml-1 mr-1"
              key={post.ID}
              
            >
              <div className="col-10">
                <div className="d-flex mt-3 mb-3">
                  <div className="profile_img_div_community_posts">
                    <img
                      className="profile_img_community_posts"
                      src={post?.profile}
                      alt=""
                    />
                  </div>
                  <div>
                    <h6
                      className="ml-2 mt-1"
                      style={{
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "700",
                      }}
                    >
                      {post?.author}
                    </h6>
                    <p
                      className="ml-2"
                      style={{
                        marginTop: "-10px",
                        color: "#999",
                        fontSize: "12px",
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "700",
                      }}
                    >
                      {post?.community}
                    </p>
                  </div>
                </div>
              </div>
              {communityInfo.accessability === "private" ? (
                <div className="pl-lg-5 pl-md-5 col-2">
                  {post.UID?.toString() ===
                  localStorage.getItem("userId")?.toString() ? (
                    <div>
                      <h3
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDropdownToggle(post.ID)}
                      >
                        ...
                      </h3>
                      {visibleDropdown === post.ID && (
                        <div className="menu2">
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              updatePost(post.ID, post.Text, post.Image)
                            }
                          >
                            Edit
                          </span>

                          <span
                            className="dropdown-item"
                            onClick={() => confirmDelete(post.ID, post.Image)}
                          >
                            Delete
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <h3
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDropdownToggle(post.ID)}
                      >
                        ...
                      </h3>
                      {visibleDropdown === post.ID && (
                        <div className="menu2">
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              createChat(
                                post.UID,
                                localStorage.getItem("userId")
                              )
                            }
                          >
                            Message
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="pl-lg-5 pl-md-5 col-2">
                  {post.UID?.toString() ===
                  localStorage.getItem("userId")?.toString() ? (
                    <>
                      <div>
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDropdownToggle(post.ID)}
                        >
                          ...
                        </h3>
                        {visibleDropdown === post.ID && (
                          <div className="menu2">
                            <span
                              className="dropdown-item"
                              onClick={() =>
                                updatePost(post.ID, post.Text, post.Image)
                              }
                            >
                              Edit
                            </span>

                            <span
                              className="dropdown-item"
                              onClick={() => confirmDelete(post.ID, post.Image)}
                            >
                              Delete
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDropdownToggle(post.ID)}
                        >
                          
                        </h3>
                        {visibleDropdown === post.ID && (
                          <div className="menu2">
                            {/* <span className="dropdown-item"  onClick={() => updateNote(review.ID,review.Review_text)}>Edit</span>
                   
                    <span className="dropdown-item" onClick={() => confirmDelete(post.ID)} >
                      Delete
                    </span> */}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="comm_feed_post_content ml-2 col-12">
                <p>{renderPostContent(post?.Text)}</p>
                <br />
              </div>
              {post?.Image && (
                <div className="col-12">
                  <img src={`${post.Image}`} alt="" style={{ width: "100%" }} />
                  <br />
                  <hr />
                </div>
              )}

              <div className="post-actions col-12 d-flex">
                <div
                  className="col-lg-3 col-md-3 col-6 d-flex"
                  style={{ marginLeft: "-5px" }}
                >
                  <FontAwesomeIcon
                    icon={!likes.includes(post.ID) ? regularHeart : solidHeart}
                    className="love-icon"
                    onClick={() => addLike(post.ID,post.Text)}
                    style={{
                      cursor: "pointer",
                    }}
                  />{" "}
                  <p className="ml-1" style={{ fontSize: "12px" }}>
                    {post.likes_count === 0
                      ? "Likes"
                      : post.likes_count === 1
                      ? "1 Like"
                      : `${post.likes_count} Likes`}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-6 ml-1 d-flex">
                  <FontAwesomeIcon icon={faComment} />
                  <p
                    onClick={() => fetchCommentsForPost(post.ID)}
                    style={{ cursor: "pointer", right: "0", fontSize: "12px" }}
                  >
                    {" "}
                    &nbsp;{" "}
                    {post.comments_count === 0
                      ? "Comments"
                      : post.comments_count === 1
                      ? "1 Comment"
                      : `${post?.comments_count} Comments`}
                  </p>
                </div>
              </div>
              <div>
                <div
                  className="col-12 pl-4 pr-4"
                  style={{ display: display[post.ID] ? "block" : "none" }}
                >
                  {Array.isArray(postComments[post.ID]) &&
                  postComments[post.ID].length > 0 ? (
                    <>
                      {postComments[post.ID]
                        .slice(0, displayedComments)
                        .map((comment, index) => (
                          <div key={index}>
                            <div className="d-flex">
                              <div className="user_profile_comments">
                                <img src={comment?.profile} alt="" />
                              </div>
                              <div>
                                <b style={{ marginLeft: "10px" }}>
                                  {comment?.username}:
                                </b>
                                <br />

                                <p style={{ marginLeft: "10px" }}>
                                  {comment?.Text}
                                </p>
                                <p
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "-16px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {format(comment.Created_at)}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      {displayedComments < postComments[post.ID].length && (
                        <p
                          style={{
                            color: "#1E81C4",
                            fontSize: "14px",
                            marginLeft: "10px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleViewMore}
                        >
                          View More
                        </p>
                      )}
                    </>
                  ) : (
                    <small>No comments</small>
                  )}
                </div>
              </div>
              <form action="" style={{ width: "100%" }}>
                <input
                  ref={commentInputRef}
                  type="text"
                  className="leave_comment ml-4"
                  placeholder="Leave a comment"
                  required
                  minLength={3}
                  style={{
                    color: "rgba(0, 0, 0, 0.50)",
                    fontFamily: "'Inter',sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "101.023%",
                    width: "70%",
                  }}
                  value={comments[index] || ""}
                  onChange={(e) => handleCommentChange(e, index)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      const inputValue = e.target.value.trim();
                      if (inputValue) {
                        handleCommentKeyPress(e, post.ID, index,post.Text);
                      }
                    }
                  }}
                />
              </form>
            </div>
          ))
        ) : (
          <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ width: "100%" }}
                >
                  <h5
                    className="mt-3"
                    style={{
                      fontFamily: "'Lato',sans-serif",
                      fontSize: "16px",
                      fontWeight: "600",
                      minHeight:"100px"
                    }}
                  >
                    No posts available
                  </h5>
                </div>
        )}

        {loading && <Preloader />}
        <div id="intersection-target" style={{ height: "10px" }}></div>

        <div className="container-fluid mb-5 d-lg-block d-md-block d-none ">
          <div className="row">
            <div className="col-3"></div>
            <div className="fixed-button-container col-6  pb-1">
              <button
                type="button"
                className="btn fixed-button btn-light btn-block"
                onClick={popup}
              >
                <i className="fa fa-plus-circle mr-2"></i> Post to Community
              </button>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>

      <button
        type="button"
        ref={ref}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal3"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel3"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel3">
                Add a Post
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="etitle"></label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="name"
                    name="post_text"
                    required
                    minLength={5}
                    rows={4}
                    value={credentials.post_text}
                    onChange={onChange}
                    placeholder="Write Something...."
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "14px",
                    }}
                  />
                </div>
                {thumbnail && (
                  <div class="ml-4 mb-2 mt-2">
                    <img
                      src={thumbnail}
                      alt="Thumbnail"
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        borderRadius: "10px",
                        position: "relative",
                      }}
                    />
                    <button
                      style={{
                        position: "absolute",
                        width: "20px",
                        border: "none",
                        fontSize: "25px",
                        color: "red",
                        fontWeight: "bold",
                        marginTop: "-10px",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={handleCancel}
                    >
                      &times;
                    </button>
                  </div>
                )}
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="validatedInputGroupCustomFile"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label
                    class="custom-file-label"
                    for="validatedInputGroupCustomFile"
                    style={{ fontSize: "14px", cursor: "pointer" }}
                  >
                    Choose file...
                  </label>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refClose}
                className="btn cancel_button_popup"
                data-dismiss="modal"
                // disabled={sending}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn submit_button_popup"
                disabled={sending}
              >
                {sending ? "Posting..." : "Post"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        ref={refEdit}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal4"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal4"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabe4"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabe4">
                Edit Post
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">
                    Post
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="post"
                    name="post"
                    value={updated}
                    aria-describedby="emailHelp"
                    onChange={onchange}
                    rows={4}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
                {file && !thumbnail && (
                  <>
                <img src={file} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius:'10px',position:'relative' }} />
                <button
                style={{
                  position: 'absolute',
                 width:"20px",
                 border:"none",
                 fontSize:"25px",
                 color:'red',
                 fontWeight:'bold',
                 marginTop:'-10px',
                 backgroundColor:'white',
                 cursor:"pointer"
                }}
                onClick={handleeditCancel}
              >
                &times;
              </button>
              </>
                )}
              {thumbnail && (
        <div class="ml-4 mb-2 mt-2">
          <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius:'10px',position:'relative' }} />
          <button
            style={{
              position: 'absolute',
             width:"20px",
             border:"none",
             fontSize:"25px",
             color:'red',
             fontWeight:'bold',
             marginTop:'-10px',
             backgroundColor:'white',
             cursor:"pointer"
            }}
            onClick={handleCancel}
          >
            &times;
          </button>
        </div>
      )}
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="validatedInputGroupCustomFile"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label
                    class="custom-file-label"
                    for="validatedInputGroupCustomFile"
                    style={{ fontSize: "14px" }}
                  >
                    Choose file...
                  </label>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refCloseEdit}
                className="btn cancel_button_popup"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleClick}
                className="btn submit_button_popup"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        ref={refinvite}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#inviteModal"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="inviteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="inviteModal">
                Invite Friend
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">
                    Name (Don’t worry, they will remain anonymous as well)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    required
                    minLength={3}
                    value={invitecredentials.name}
                    onChange={inviteOnchnage}
                    placeholder="Enter the name of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                    value={invitecredentials.email}
                    onChange={inviteOnchnage}
                    placeholder="Enter the email address of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refcloseinvite}
                className="btn btn-outline cancel_button_popup"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => invitehandleSubmit(id,communityInfo?.Name)}
                className="btn submit_button_popup"
              >
                {invitesending ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </div>
       
      </div>
      <button
        type="button"
        ref={rededitcommunity}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal9"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal9"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabe9"
        aria-hidden="true"
        style={{ msOverflowY: "scroll" }}
      >
        <form action="" onSubmit={editcommunity}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabe9">
                  Edit Community
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                style={{
                  backgroundImage: 'url("/commbg.jpg")',
                  height: "180px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="modal_label">Community Name</label>
                  <input
                    type="text"
                    placeholder="Community Name"
                    name="communityName"
                    value={communityCredentials.communityName}
                    onChange={handleChange}

                    required
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="modal_label">Description</label>
                  <input
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={communityCredentials.description}
                    onChange={handleChange}
                    required
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>

               
                {communityCredentials.image && !thumbnail && (
        <div class="ml-4 mb-2 mt-2">
          <img src={communityCredentials.image} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius:'10px',position:'relative' }} />
          <button
            style={{
              position: 'absolute',
             width:"20px",
             border:"none",
             fontSize:"25px",
             color:'red',
             fontWeight:'bold',
             marginTop:'-10px',
             backgroundColor:'white',
             cursor:"pointer"
            }}
            onClick={handleCommunityCancel}
          >
            &times;
          </button>
        </div>
      )}
       {thumbnail && (
        <div class="ml-4 mb-2 mt-2">
          <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius:'10px',position:'relative' }} />
          <button
            style={{
              position: 'absolute',
             width:"20px",
             border:"none",
             fontSize:"25px",
             color:'red',
             fontWeight:'bold',
             marginTop:'-10px',
             backgroundColor:'white',
             cursor:"pointer"
            }}
            onClick={handleCommunityCancel}
          >
            &times;
          </button>
        </div>
      )}
                <div className="custom-file mt-3">
                  <input
                    className="custom-file-input"
                    id="validatedInputGroupCustomFile"
                    type="file"
                    onChange={handlecommunityFileChange}
                    
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedInputGroupCustomFile"
                    style={{ fontSize: "14px" }}
                  >
                    Upload Profile Picture...
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  ref={refClosecreatecommunity}
                  className="btn cancel_button_popup"
                  data-dismiss="modal"
                  // disabled={creating}

                >
                  Cancel
                </button>
                <button type="submit" className="btn submit_button_popup">
                  {invitesending ? "Saving...." : "Save"}
                  
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CommunityPage;

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook, faSquareXTwitter, faSquareInstagram, faSquareThreads } from '@fortawesome/free-brands-svg-icons';
import MailchimpForm from "./MailChipForm";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        className="container-fluid "
        id="footer"
        style={{ background: "#EAEAFD" }}
      >
        <div className="row pt-lg-5 pt-md-5 pt-2 ">
          <div className=" col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 d-flex flex-column align-items-center pb-5">
            <Link to="/">
              <img
                className="img-fluid mt-5 pt-5"
                width={"250px"}
                src="/footer.png"
                alt=""
              />
            </Link>
          </div>
          <div className=" col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 mt-5 d-flex flex-column justify-content-center pb-4">
            {/* <p className="subscribe_news">Subscribe to our newsletter</p> */}
            <MailchimpForm />
            {/* <div className="d-flex ">
              <input
                className="subscribe_email"
                type="email"
                placeholder="Enter your Email"
              />
              <button className="subscribe_btn" type="submit">
                SUBSCRIBE
              </button>
            </div> */}
            <div className="d-flex social-media-icons align-items-center justify-content-center  mt-4">
              <div className="d-flex social-media-icons ">
                {/* <Link to="/" className="social-icon">
                <FontAwesomeIcon icon={faFacebook} style={{fontSize:"26px"}} />
                </Link> */}
               

                <a href="https://twitter.com/Manorlane_" target="blank" className="social-icon">
                <FontAwesomeIcon icon={faSquareXTwitter}  style={{fontSize:"26px"}} />
                </a>

                {/* <a href="https://www.instagram.com/manor_lane?igsh=OGQ5ZDc2ODk2ZA==" target="blank" className="social-icon">
                <FontAwesomeIcon icon={faSquareInstagram}  style={{fontSize:"26px"}} />
                </a> */}
                <a href="https://www.threads.net/@manor_lane" target="blank" className="social-icon">
                <FontAwesomeIcon icon={faSquareThreads}  style={{fontSize:"26px"}} />
                </a>
                
              </div>
            </div>
          </div>
          <div
            className=" col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12  d-flex  justify-content-center align-items-center"
            style={{ marginTop: "-20px" }}
          >
            <div className="col-1"></div>
            <div className="col-lg-3 col-md-3 col-6 footer_links ml-lg-5 ml-md-5">
              <a href="https://manorlane.co/learn/media-press-kit/">
                {" "}
                <li style={{ listStyle: "none" }}>Press Kit</li>
              </a>
              <Link to="/about">
                {" "}
                <li style={{ listStyle: "none" }}>About</li>
              </Link>
              <Link to="/donate">
                <li style={{ listStyle: "none" }}>Donate</li>
              </Link>
            </div>
            <div className="col-lg-8 col-md-8 col-6 footer_links">
              <Link to="/contact">
                <li style={{ listStyle: "none" }}>Feedback</li>
              </Link>
              <Link to="/terms_of_services">
                <li style={{ listStyle: "none" }}>Terms of Service</li>
              </Link>
              <Link to="/privacy_policy">
                <li style={{ listStyle: "none" }}>Privacy Policy</li>
              </Link>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 mt-lg-0 mt-md-1 mt-2 mb-5  mb-lg-0 mb-md-0">
            <p className="copyright">Copyright © {currentYear}, ManorLane, LLC</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

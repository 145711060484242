import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBookmark} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
const Reviews = (props) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updated,setUpdated]=useState("")
  const [review_id, setUpdate_id]=useState("")
  const ref = useRef(null);
  const refClose = useRef(null);
  const [saving, setSaving]=useState(false)
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  let navigate=useNavigate()


  const [visibleDropdown, setVisibleDropdown] = useState(null);

  const handleDropdownToggle = (reviewID) => {
    setVisibleDropdown(visibleDropdown === reviewID ? null : reviewID);
  };


  




  // Edit 
  const editNote = async (id, text) => {
    try {
      setSaving(true)
      const url = `${props.API_URL}/api/reviews/update_my_reviews/${id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token'),
        },
        body: JSON.stringify({ "Review_text": text }),
      });
  
      const json = await response.json();
      setSaving(false)
      if (json.status === "success") {
        const updatedReviews = reviews.map((review) =>
          review.ID === id ? { ...review, Review_text: text } : review
        );
        setReviews(updatedReviews);
      } else {
        props.showAlert(json.message,"error")
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };
  

  const style={
    fontSize:"22px",
    color:"#000",
    marginLeft:"6px"
  }
  

  const updateNote = (id,text) => {
    ref.current.click();
    setUpdated(text)
    setUpdate_id(id)
    console.log(updated)
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1E81C4',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Close',

        confirmButtonText: 'Yes, Delete it!'
     
    }).then((result) => {
      if (result.isConfirmed) {
  
      onDelete(id);
    
  }
  })
  };

  const onDelete = async (id) => {
    try {
      console.log(id);
      const url = `${props.API_URL}/api/reviews/delete_my_reviews/${id}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
  
      const json = await response.json();
  
      if (json.status === "success") {
        const updatedReviews = reviews.filter((review) => review.ID !== id);
        setReviews(updatedReviews);
      } else {
        props.showAlert(json.message,"error")
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };
  let page_no=1;
  const fetchReviews = async () => {
    
    try {
      
      const response = await fetch(`${props.API_URL}/api/reviews/fetch_my_reviews?page=${page_no}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      setLoading(true);
      const result = await response.json();
      if(result.status==="success"){
        const data=result.results
        page_no=page_no+1;
        if (result.length === 0) {
          setHasMore(false);
          setLoading(false)
        } else {
          setReviews((prevReviews) => [...prevReviews, ...data]);
          setLoading(false)       
          
        }
    
      }
      

  
      setLoading(false);
    } catch (error) {
      props.showAlert(error, "error");
      setLoading(false);
    }
  };
  
  
  

  
    const handleIntersection = (entries) => {
      console.log("Intersection detected", page);
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        fetchReviews();
      }
    };
    
    
      useEffect(() => {
        if (!localStorage.getItem("token")) {
          Swal.fire({
            title: "Sign in required",
            text: " In order to access this page, you will need to create an account or login to your account first",
            imageUrl: "/popup_check.svg",
            imageWidth: 60,
            imageHeight: 60,
            imageAlt: "Custom image",
            showCancelButton: true,
            confirmButtonColor: "#1E81C4",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancel",
    
            confirmButtonText: "OK",
          }).then(async (result) => {
            if (result.isConfirmed) {
              navigate("/login?redirect=/my_reviews");
            } else {
              navigate("/communities");
            }
          });        }
        const observer = new IntersectionObserver(handleIntersection, {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        });
      
        const target = document.querySelector("#intersection-target");
        if (target) {
          observer.observe(target);
        }
      
        return () => {
          observer.disconnect();
        };
      }, []);
    
      
  

  const onchange = (e) => {
    setUpdated(e.target.value);
  };

  const handleClick=(e)=>{
    e.preventDefault();
    refClose.current.click();
    editNote(review_id,updated)
}
useEffect(() => {
  // Fetch meta data from your API
  fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
  .then(response => response.json())
  .then(data => {
   const metaData = data.data[0];

   if(metaData){
     // Update title tag
     if(metaData.title){
       document.title = metaData.title + '|' + 'My Reviews' || 'Default Title';

     }
       // Update meta description tag
   const metaDescriptionTag = document.querySelector('meta[name="description"]');
   if (metaDescriptionTag) {
     metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
   }

   // Update meta keywords tag
   const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
   if (metaKeywordsTag) {
     metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
   }

   // Update canonical URL tag
   const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
   if (canonicalLinkTag) {
     canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
   }
   }

  })
  .catch(error => {
    console.error('Error fetching meta data:', error);
  });



 


}, []);
 
  return (
    <>
      <div className="reviews-tab">
        <div className=" my_reviews_top_div ml-1 mr-1 mt-4" style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="row pl-4 pr-3">
            <div className=" col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9 mt-4 mb-2">
              <h4 className="my_reviews">My Reviews</h4>
            </div>
            <div className=" col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mt-3 mb-2">
              <img src="/noun_stars.png" alt="" />
            </div>
          </div>
          <div className="row pl-4 pr-2">
            <div className=" d-lg-block d-md-block d-none col-lg-1 col-md-1   mb-2" style={{marginLeft:"-5px"}}>
            <FontAwesomeIcon icon={ faStar } style={style} />      
            </div>
            <div className=" col-lg-11 col-md-11 col-sm-12 col-xs-12 col-12 ml-lg-1 ml-md-1  mb-2">
              <p className="my_reviews_intro1">
                My Reviews page is a collection of all your written Reviews plus
                other users’ comments on your Reviews.
              </p>
              <p className="my_reviews_intro2">
                You are free to create as many Reviews as you like, and of
                course, all are anonymous and confidential.
              </p>
            </div>
          </div>
        </div>
        <div className="ml-1 mr-1 my_reviews_top_div" style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
        <div className="row pl-4 pr-2 mt-4">
          <div className="d-flex">
            <div className="ml-1 col-lg-1 col-md-1 d-lg-block d-md-block d-none mt-3 mb-2">
            <FontAwesomeIcon icon={faBookmark} style={{fontSize:"22px"}} />
            </div>
            <div className=" col-lg-11 col-md-11 col-sm-12 col-xs-12 col-12 mt-3 mb-2 ml-lg-0 ml-md-0 ml-1">
              <p className="my_reviews_intro3 " style={{marginLeft:"-5px"}}>
                To view your saved Reviews of other users’ Reviews, visit your{" "}
                <Link to="/bookmarks" className="mr_link ">
                  Bookmarks
                </Link>{" "}
                page. Ready to start a Community with neighbors, friends or
                other people you can support or vent to? Join or start a{" "}
                <Link to="/communities" className="mr_link">
                  Community
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        </div>
        
        {
         ( 
          (Array.isArray(reviews) && reviews.length > 0) || loading  ? ( 
          reviews.map((review, index) => (
            <div className="row " key={index} >
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 mb-2">
                <div className="review-box">
                  <div className="icon">
                  {review.Name === 'Landlord' ? (
              <img
                className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                alt=""
                src="/landlord.png"
              />
            ) : review.Name === 'HOA' ? (
              <img
                className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                alt=""
                src="/c1.png"
              />
            ) : (
              // Default image if none of the conditions match
              <img
                className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                alt=""
                src="/pm.png"
              />
            )}
                    <div className="heading_review">
                    <Link to={`/review_detail/${review.ID}`} style={{ textDecoration: 'none' }}>
                      <div>
                      <p className="review_property_address">{review?.Address}</p>
                    
                    <p className="property_review_city">{review?.CNAME}</p>
                    <p className="proprty_review_management_type">
                      {review?.Name}
                    </p>

                      </div>
                      </Link>
                    
                      <div>
                        {[...Array(review.rating)].map((_, index) => (
                          <img
                            key={index}
                            src="/gold.png"
                            width={"20px"}
                            className="mr-2 mb-2 img-fluid"
                            alt="Star"
                          />
                        ))}
                      </div>

                      <p style={{ fontSize: "11px" }}>
                        Avg, rating, Click address for more details
                      </p>
                    </div>
                  
                   
                    <div>
                      
                      <h3 style={{ cursor: "pointer", marginTop: "-70px" }}  onClick={() => handleDropdownToggle(review.ID)}>
                          ...
                        </h3>
                      {visibleDropdown === review.ID && (
                  <div className="menu2">
                      <span className="dropdown-item"  onClick={() => updateNote(review.ID,review.Review_text)}>Edit</span>
                   
                    <span className="dropdown-item" onClick={() => confirmDelete(review.ID)} >
                      Delete
                    </span>
                  </div>
                )}
                    </div>
                  </div>
                  <h6 className="property_review_text ml-4">
                    {review?.Review_text}
                  </h6>
                </div>
              </div>
            </div>
          ))
        ) : (
         
          <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ width: "100%" }}
        >
          <h5
            className="mt-3"
            style={{
              fontFamily: "'Lato',sans-serif",
              fontSize: "16px",
              fontWeight: "600",
              minHeight:"500px"
            }}
          >
            No reviews available
          </h5>
        </div>
       
          
        ) )  }
        {loading && (
          <div
            className="spinner-border"
            role="status"
            style={{ marginLeft: "45%" }}
          >
          </div>
)}

       
         
      </div>
      <div id="intersection-target" style={{ height: "10px" }}></div>


      <button
        type="button"
        ref={ref}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal1"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Review
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <form action="">
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">Review</label>
                  <input
                    type="text"
                    className="form-control"
                    id="etitle"
                    name="etitle"
                    value={updated}
                    aria-describedby="emailHelp"
                    onChange={onchange}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize:"12px"
        
                    }}
                  />
                </div>
                
                

                
              </form>
            
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refClose}
                className="btn cancel_button_popup"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" onClick={handleClick} className="btn submit_button_popup">
                {saving?"Saving....":"Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Reviews;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { format } from "timeago.js";
import { faComment } from '@fortawesome/free-regular-svg-icons';
import {  faUsers,faCheck   } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
function Preloader() {
  return (
    <>
      <div
        className="d-flex justify_content-center"
        style={{ marginLeft: "45%" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
}
const Communities = (props) => {
  const [publicCommunities, setpublicCommunities] = useState([]);
  const [communityPosts, setcommunityPosts] = useState([]);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const commentInputRef = useRef(null);
  const [postComments, setPostComments] = useState({});
  const [display, setDisplay] = useState({});
  const [joinedCommunities, setJoinedCommunities] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const refcreatecommunity = useRef(null);
  const refClosecreatecommunity = useRef(null);
 
  const [thumbnail, setThumbnail] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(event.target.files[0]);

    // Read the file as a data URL and set it as the thumbnail
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setThumbnail(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  const handleCancel = () => {
    setThumbnail(null);
    setSelectedFile(null)
  };


  const [credentials, setcredentials] = useState({
    communityName: "",
    description: "",
    accessibility: "public",
  });
  const [likes, setLikes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  // const [pageno, setPage_no]=useState(1)
  let navigate = useNavigate();
  const observer = useRef(null);

  const [displayedComments, setDisplayedComments] = useState(5);

  const style={
    fontSize:"22px"
  }

  const handleViewMore = () => {
    setDisplayedComments(displayedComments + 5);
  };

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      fetchCommunityPosts();
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    const target = document.querySelector("#intersection-target");
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [hasMore]);

  const popup = () => {
    const authToken = localStorage.getItem("token");

    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/communities");
        } 
      }); 
      return;
    } else {
      refcreatecommunity.current.click();
    }
  };

  const addLike = async (postId,post_text) => {
    try {
      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/post/"+postId);
          } 
        }); 

        return;
      }
      const response = await fetch(`${props.API_URL}/api/post/like_post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          postId: postId,
          post_text:post_text
        }),
      });
      const json = await response.json();
      if (json.status === "success") {
        if (!likes.includes(postId)) {
          setLikes([...likes, postId]);
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count + 1 }
                : post
            )
          );
        } else {
          setLikes(likes.filter((id) => id !== postId));
          setcommunityPosts((prevPosts) =>
            prevPosts.map((post) =>
              post.ID === postId
                ? { ...post, likes_count: post.likes_count - 1 }
                : post
            )
          );
        }
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentKeyPress = async (e, PID, index,post_text) => {
    if (e.key !== "Enter") {
      return;
    }

    e.preventDefault();

    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/post/"+PID);
        } 
      }); 

      return;
    }

    if (comments[index] === "") {
      return;
    }

    try {
      const response = await fetch(
        `${props.API_URL}/api/post/add_comment/${PID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            comment_text: comments[index],
            post_text:post_text
          }),
        }
      );

      const json = await response.json();

      if (json.status === "success") {
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: "none",
        }));
        await fetchCommentsForPost(PID);
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [PID]: !prevDisplay[PID] ? "block" : "none",
        }));
        setcommunityPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.ID === PID
              ? { ...post, comments_count: post.comments_count + 1 }
              : post
          )
        );
      } else {
        props.showAlert(json.message, "error");
      }

      const updatedComments = [...comments];
      updatedComments[index] = "";
      setComments(updatedComments);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleCommentChange = (e, index) => {
    const updatedComments = [...comments];
    updatedComments[index] = e.target.value;
    setComments(updatedComments);
  };

  const fetchCommentsForPost = async (postId) => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/post/fetch_comments/${postId}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setPostComments((prevpostComments) => ({
          ...prevpostComments,
          [postId]: data.data,
        }));
        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [postId]: !prevDisplay[postId],
        }));
        setDisplayedComments(5);
      } else {
        return [];
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
      return [];
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setcredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchPublicCommunities = async () => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/community/fetch_public_communities`
      );
      const data = await response.json();
      setpublicCommunities(data.data);
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchJoinedCommunities = async () => {
    try {
      if (localStorage.getItem("token")) {
        const response = await fetch(
          `${props.API_URL}/api/community/fetch_joined_communities`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );
        const data = await response.json();
        if (data.status === "success") {
          const joinedCommunityIds = data.data.map(
            (community) => community.Community_id
          );
          setJoinedCommunities(joinedCommunityIds);
        }
      } else {
        setJoinedCommunities([]);
      }
    } catch (error) {
      props.showAlert("Internal Server Errorrrr", "error");
    }
  };

  let pageno = 1;
  const fetchCommunityPosts = async () => {
    let apiUrl = "";
    let headers = {};

    if (localStorage.getItem("token")) {
      apiUrl = `${props.API_URL}/api/community/fetch_communities_all_posts?page=${pageno}`;
      headers = {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      };
    } else {
      apiUrl = `${props.API_URL}/api/community/fetch_communities_posts?page=${pageno}`;
    }

    try {
      const response = await fetch(apiUrl, { headers });
      setLoading(true);
      const data = await response.json();
      if (data.status === "success") {
        const communitydata = data.data;
        if (data.length === 0) {
          setHasMore(false);
          setLoading(false);
        } else {
          setcommunityPosts((prevCommunityPosts) => [
            ...prevCommunityPosts,
            ...communitydata,
          ]);
          pageno = pageno + 1;
        }
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    } finally {
      setLoading(false);
    }
  };

  const likesData = async () => {
    try {
      if (localStorage.getItem("token")) {
        const likeResponse = await fetch(
          `${props.API_URL}/api/post/fetch_liked_posts/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
          }
        );

        const likedData = await likeResponse.json();

        if (likedData.status === "success") {
          const postIds = likedData.data.map((item) => item.community_post_id);
          setLikes(postIds);
        }
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  useEffect(() => {
    likesData();
    fetchPublicCommunities();

    fetchJoinedCommunities();
  }, [communityPosts]);

  const join_community = async (communityId) => {
    try {
      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/community_page/"+communityId);
          } 
        }); 

        return;
      }
      const response = await fetch(
        `${props.API_URL}/api/community/join_community/${communityId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      const json = await response.json();
      if (json.status === "success") {
        props.showAlert(json.message, json.status);
        navigate(`/community_page/${communityId}`);

        setJoinedCommunities((prevCommunities) => [
          ...prevCommunities,
          communityId,
        ]);
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/communities");
        } else {
          navigate("/communities");
        }
      }); 

      return;
    }
    let selectedfile = null;
    try {
      const { url } = await fetch(
        `${props.API_URL}/CreateCommunity/s3Url`
      ).then((res) => res.json());

      // Post the image directly to the S3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: selectedFile,
      });

      const imageUrl = url.split("?")[0];
      selectedfile = imageUrl;
    } catch (error) {
      console.error("Error fetching S3 URL:", error);
    }
    const communityData = {};
    communityData.name = credentials.communityName;
    communityData.description = credentials.description;
    communityData.accessibility = credentials.accessibility;
    communityData.image = selectedfile;

    setCreating(true);
    fetch(`${props.API_URL}/api/community/create_community`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(communityData),
    })
      .then((response) => response.json())
      .then((json) => {
        setCreating(false);
        console.log(json);

        if (json.status === "success") {
          props.showAlert(json.message, json.status);
          navigate(`/community_page/${json.communityId}`);
        } else {
          props.showAlert(json.message, "error");
        }
      })
      .catch((error) => {
        props.showAlert("Internal Server Error", "error");
      })
      refClosecreatecommunity.current.click();

  };
  const renderPostContent = (text) => {
    // Check if the post contains any text
    if (!text) {
      return null;
    }

    // Regular expression to find URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace plain text URLs with clickable HTML links
    const formattedText = text.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

    return (
      <p dangerouslySetInnerHTML={{ __html: formattedText }} />
    );
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Communities' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="communities_comp mb-5">
        <div className=" coomunities_top_div ml-1 mr-1 mt-4" 
         style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="row pl-4 pr-2">
            <div className=" col-lg-7 col-md-7 col-sm-5 col-xs-5 col-5 mt-4 mb-2">
              <h4 className="communities_head">Communities</h4>
            </div>
            <div className=" col-lg-5  col-md-5 col-sm-7 col-xs-7 col-7 mt-4 mb-2">
              <button className="create_community_button btn" onClick={popup}>
                Create Community
              </button>
            </div>
          </div>
          <div className="row pl-4 pr-2">
            <div className=" d-lg-block d-md-block d-none col-lg-1 col-md-1   mb-2">
            <FontAwesomeIcon icon={ faUsers } style={style} />      
             </div>
            <div className=" col-lg-11 col-md-11 col-sm-12 col-xs-12 col-12  mb-2">
              <p className="communities_intro1 pr-1">
                Communities page is a safe place for open dialogue, easy
                sharing and compassionate communication with others in your
                chosen cohort.
              </p>
            </div>
          </div>
        </div>
        <div className="mr-1 ml-1"  style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            backgroundColor:"white"
          }}>
        <div className="row pl-4 pr-2 mt-4  mb-1 "
        >
          <div className="col-12 mt-3 ">
            {/* <hr style={{ background: "rgba(0, 0, 0, 0.30)" }} /> */}

            <h5 className="public_comm_head">Some ideas for Communities:</h5>
            <ul className="comm_ideas">
              <li className="d-flex">
                <div className="col-1" style={{paddingTop:"3px"}}>
                {/* <FontAwesomeIcon icon={ faCheck } style={style} />       */}
                {/* <small  style={{fontWeight: '1000', fontFamily: 'Arial, sans-serif' }}>🗸</small> */}
                <i class="far fa-check-circle"  style={{fontSize:"14px"}}></i>

                
                {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                </div>
                <div className="col-11 comm_ideas_points" >
                Join a public community to get/share advice and insights for
                your living situation
                </div>
                
               
              </li>
              <li className="d-flex">
                <div className="col-1" style={{paddingTop:"3px"}}>
                {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                 {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                 <i class="far fa-check-circle"  style={{fontSize:"14px"}}></i>

                </div>
                <div className="col-11 comm_ideas_points" >
                Create a private community for roommates, neighbors, friends

                </div>
                
               
              </li>
              <li className="d-flex">
                <div className="col-1" style={{paddingTop:"3px"}}>
                {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                 {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                 <i class="far fa-check-circle"  style={{fontSize:"14px"}}></i>

                </div>
                <div className="col-11 comm_ideas_points" >
                Create a private community for your region

                </div>
                
               
              </li>
              <li className="d-flex">
                <div className="col-1" style={{paddingTop:"3px"}}>
                {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                 {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                 <i class="far fa-check-circle" style={{fontSize:"14px"}}></i>

                </div>
                <div className="col-11 comm_ideas_points" >
                Create a private community for a particular topic or interest

                </div>
                
               
              </li>
             
             
              
            </ul>
          </div>
        </div>

        </div>
       
        <div className="mr-1 ml-1"
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            backgroundColor:"white"
          }}
        >
          <div className="row pl-4 pr-2 mt-4">
            <div className="col-12 mt-3">
              <h2 className="public_comm_head">Public Communities</h2>
              <p className="public_comm_intro">
                These Communities are available to all visitors to the site. No
                account needed to browse.
                {localStorage.getItem("token")?(
                <>
                &nbsp;You can see your communities on the <Link to="/my_communities">My Communities page</Link>.
                </>
                ):
                (
                <> 
                {" "}If you would like to post a comment, an{" "}
                <Link to="/signup">account</Link> is needed.
                </>
                )}
                
              </p>
            </div>
          </div>
          <div className="row mx-1">
            {loading ? (
              <Preloader />
            ) : Array.isArray(publicCommunities) &&
              publicCommunities.length > 0 ? (
              publicCommunities.map((community) => (
                <>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mt-2 mb-2 d-flex" >
                <div
                  className="d-flex "
                  id="public_communities"
                  key={community.ID}
                  style={{
                    borderRadius: "10px",
                    border: "0.5px solid rgba(0, 0, 0, 0.10",
                    width:"100%"
                  }}
                >
                  <div className="public_commuity_images mr-3 ml-2">
                  <img
                    
                    src={community?.Profile_img || "c1.png"}
                    alt=""
                  />
                  </div>
                 
                  <div className="mr-1">
                    <div className="d-flex">
                    <Link to={`/community_page/${community.ID}`}>
                      <h3 className="community_heading"> {community?.Name}</h3>
                    </Link>
                    {community.pinned==1 && (
                      <i class="fas fa-thumbtack" style={{ marginLeft: "auto" }}></i>

                    )}
                    </div>
                   
                    {joinedCommunities.includes(community.ID) ? (
                      <button className="community_join_btn btn">Joined</button>
                    ) : (
                      <button
                        className="community_join_btn btn"
                        onClick={() => join_community(community.ID)}
                      >
                        Join Community
                      </button>
                    )}
                  </div>
                 
                </div>
                  </div>
                
                </>
              ))
            ) : (
              
<div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ width: "100%" }}
                >
                  <h5
                    className="mt-3"
                    style={{
                      fontFamily: "'Lato',sans-serif",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    No public communities available
                  </h5>
                </div>            )}
          </div>

          <br />
        </div>
        <div  className="ml-1 mr-1"  style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            backgroundColor:"white"
          }}>
           <h3 className="mt-3 mb-3 ml-1 mr-1 pt-3 pl-2 pb-3" id="comm_feed">
          Communities Feed
        </h3>
        </div>
       
        {(Array.isArray(communityPosts) && communityPosts.length > 0) || loading ? (
          communityPosts.map((post, index) => (
            <div
              id="comm_feed_posts"
              className="row mb-3 ml-1 mr-1"
              key={post.ID}
              
            >
              <div className="col-9">
                <div className="d-flex mt-3 mb-3">
                  <div className="profile_img_div_community_posts">
                  <img
                    className="profile_img_community_posts"
                    src={post?.profile}
                    alt=""
                  />
                  </div>
                  
                  <div>
                    <h6
                      className="ml-2 mt-1"
                      style={{
                        fontFamily: "'Lato',sans-serif",
                        fontWeight: "700",
                      }}
                    >
                      {post?.author}
                    </h6>
                    <Link to={`/community_page/${post.CID}`}>
                      <p
                        className="community_page_link ml-2"
                        style={{
                          marginTop: "-10px",
                          color: "#999",
                          fontSize: "12px",
                          textDecoration: "none",
                          fontFamily: "'Lato',sans-serif",
                          fontWeight: "700"
                        }}
                      >
                        {post?.community}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="comm_feed_post_content ml-2 col-12">
                <p>{renderPostContent(post?.Text)}</p>
              </div>
              {post?.Image && (
                <div className="col-12">
                  <img src={`${post.Image}`} alt="" style={{ width: "100%" }} />
                  <br />
                  <hr />
                </div>
              )}

              <div className="post-actions col-12 d-flex">
                <div
                  className="col-lg-3 col-md-3 col-6 d-flex"
                  style={{ marginLeft: "-5px" }}
                >
                  <FontAwesomeIcon
                    icon={!likes.includes(post.ID) ? regularHeart : solidHeart}
                    className="love-icon"
                    onClick={() => addLike(post.ID,post.Text)}
                    style={{
                      cursor: "pointer",
                    }}
                  />{" "}
                  <p className="ml-1" style={{ fontSize: "12px" }}>
                    {post.likes_count === 0
                      ? "Likes"
                      : post.likes_count === 1
                      ? "1 Like"
                      : `${post.likes_count} Likes`}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-6 ml-1 d-flex">
                <FontAwesomeIcon icon={faComment} />
                                  <p
                    onClick={() => fetchCommentsForPost(post.ID)}
                    style={{ cursor: "pointer", right: "0", fontSize: "12px" }}
                  >
                    {" "}
                    &nbsp;{" "}
                    {post.comments_count === 0
                      ? "Comments"
                      : post.comments_count === 1
                      ? "1 Comment"
                      : `${post.comments_count} Comments`}
                  </p>
                </div>
              </div>

              <div>
                <div
                  className="ml-4 mb-3"
                  style={{ display: display[post.ID] ? "block" : "none" }}
                >
                  {Array.isArray(postComments[post.ID]) &&
                  postComments[post.ID].length > 0 ? (
                    <>
                      {postComments[post.ID]
                        .slice(0, displayedComments)
                        .map((comment, index) => (
                          <div key={index}>
                            <div className="d-flex">
                              <div className="user_profile_comments">
                              <img
                                src={comment?.profile}
                               
                                alt=""
                              />
                              </div>
                              <div>

                              
                              <b style={{ marginLeft: "10px" }}>
                                {comment?.username}:
                              </b>
                              <br />
                            
                            <p style={{ marginLeft: "10px" }}>
                              {comment?.Text}
                            </p>
                            <p style={{fontSize:"10px", marginTop:"-16px", paddingLeft:"10px"}}>{format(comment?.Created_at)}</p>


                            </div>
                            </div>
                          </div>
                        ))}
                      {displayedComments < postComments[post.ID].length && (
                        <p
                          style={{
                            color: "#1E81C4",
                            fontSize: "14px",
                            marginLeft: "10px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleViewMore}
                        >
                          View More
                        </p>
                      )}
                    </>
                  ) : (
                    <small>No comments</small>
                  )}
                </div>

                
              </div>
              <form action="" style={{width:"100%"}}>
                  <input
                    ref={commentInputRef}
                    type="text"
                    className="leave_comment ml-4"
                    placeholder="Leave a comment"
                    required
                    minLength={3}
                    style={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontFamily: "'Inter',sans-serif",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "101.023%",
                    }}
                    value={comments[index] || ""}
                    onChange={(e) => handleCommentChange(e, index)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        const inputValue = e.target.value.trim();
                        if (inputValue) {
                          handleCommentKeyPress(e, post.ID, index,post.Text);
                        }
                      }
                    }}
                  />
                </form>
            </div>
          ))
        ) : (

         
          <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ width: "100%" }}
                >
                  <h5
                    className="mt-3"
                    style={{
                      fontFamily: "'Lato',sans-serif",
                      fontSize: "16px",
                      fontWeight: "600",
                      minHeight:"100px"
                    }}
                  >
                    No posts available
                  </h5>
                </div>
        )}
        {loading && (
          <div
            className="spinner-border"
            role="status"
            style={{ marginLeft: "45%" }}
          ></div>
        )}
      </div>
      <div id="intersection-target" style={{ height: "10px" }}></div>

      <button
        type="button"
        ref={refcreatecommunity}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal4"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal4"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabe4"
        aria-hidden="true"
        style={{ msOverflowY: "scroll" }}
      >
        <form action="" onSubmit={handleSubmit}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabe4">
                  Create Community
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                style={{
                  backgroundImage: 'url("/commbg.jpg")',
                  height: "180px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="modal_label">Community Name</label>
                  <input
                    type="text"
                    placeholder="Community Name"
                    name="communityName"
                    value={credentials.communityName}
                    onChange={handleChange}
                    required
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="modal_label">Description</label>
                  <input
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={credentials.description}
                    onChange={handleChange}
                    required
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize: "12px",
                    }}
                  />
                </div>

                <div className="mt-3">
                  <div className="col-12 d-flex mr-4">
                    <input
                      required
                      type="radio"
                      name="accessibility"
                      value="public"
                      checked={credentials.accessibility === "public"}
                      onChange={handleChange}
                      style={{ width: "18px" }}
                    />
                    <label
                      className=" mt-1 ml-2"
                      style={{
                        width: "15px",
                        fontFamily: "'Lato',sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Public
                    </label>
                  </div>
                  <div className="col-12 d-flex">
                    <input
                      required
                      type="radio"
                      name="accessibility"
                      value="private"
                      style={{ width: "15px" }}
                      checked={credentials.accessibility === "private"}
                      onChange={handleChange}
                    />
                    <label
                      className="mt-1 ml-2"
                      style={{
                        width: "18px",
                        fontFamily: "'Lato',sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      Private
                    </label>
                  </div>
                </div>
                {thumbnail && (
        <div class="ml-4 mb-2 mt-2">
          <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius:'10px',position:'relative' }} />
          <button
            style={{
              position: 'absolute',
             width:"20px",
             border:"none",
             fontSize:"25px",
             color:'red',
             fontWeight:'bold',
             marginTop:'-10px',
             backgroundColor:'white',
             cursor:"pointer"
            }}
            onClick={handleCancel}
          >
            &times;
          </button>
        </div>
      )}
                <div className="custom-file mt-3">
                  <input
                    className="custom-file-input"
                    id="validatedInputGroupCustomFile"
                    type="file"
                    onChange={handleFileChange}
                    
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedInputGroupCustomFile"
                    style={{ fontSize: "14px" }}
                  >
                    Choose Profile Picture...
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  ref={refClosecreatecommunity}
                  className="btn cancel_button_popup"
                  data-dismiss="modal"
                  // disabled={creating}

                >
                  Cancel
                </button>
                <button type="submit" className="btn submit_button_popup" disabled={creating}>
                  {creating ? "Creating...." : "Create"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Communities;

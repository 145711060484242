import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MyBookmarkedArticles from "./MyBookmarkedArticles";
import MyBookmarkedReviews from "./MyBookmarkedReviews";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
const BookMarks = (props) => {
  const [bookmarks, setbookmarks] = useState([]);
  const [reviews,setReviews]=useState([]);
  const [loading, setLoading] = useState(true);
  const [showArticles, setshowArticles]=useState(true);
  const style = {
    fontSize: "22px",
  };
  let navigate = useNavigate();
  const toggle=()=>{
    setshowArticles(prev=>!prev);
  }
  const fetchBookmarks = async () => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/bookmarks/fetch_bookmarks`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
      setLoading(true);
      const result = await response.json();
      console.log(result)
      if (result.status === "success") {
        const data = result.data;
        setbookmarks(data);
        setLoading(false);
      }
    } catch (error) {
      props.showAlert(error, "error");
      setLoading(false);
    }
  };
  const fetchReviews = async () => {
    try {
      // Start loading
      setLoading(true);
      
      // Fetch reviews data from API
      const response = await fetch(`${props.API_URL}/api/reviews/fetch_my_bookmarked_reviews`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
  
      const result = await response.json();
      console.log(result)
        if(result.status === "success") {
        // Set reviews data
        const data = result.results;
        setReviews(data);
      } 
      setLoading(false);
    } catch (error) {
      // Show error alert
      props.showAlert("Server error", "error");
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to access this page, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/bookmarks");
        } else {
          navigate("/communities");
        }
      });
      
    }
    fetchBookmarks();
    fetchReviews();
  }, []);
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Bookmarks' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <div className="bookmarks" >
      <div
        className=" my_reviews_top_div ml-1 mr-1 mt-4"
        style={{
          borderRadius: "10px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="row pl-4 pr-2">
          <div className=" col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9 mt-4 mb-2">
            <h4 className="communities_head">Bookmarks</h4>
          </div>
          <div className=" d-lg-none d-md-none col-sm-2 col-xs-2 col-2 mt-2 mb-2 mt-4 ">
            <FontAwesomeIcon icon={faBookmark} style={style} />
          </div>
        </div>
        <div className="row pl-4 pr-2">
          <div className=" col-lg-1 col-md-1 d-lg-block d-md-block d-none  mb-2">
            <FontAwesomeIcon icon={faBookmark} style={style} />
          </div>
          <div className=" col-lg-11 col-md-11 col-sm-12 col-xs-12 col-12  mb-2">
            <p className=" pr-1" id="bookmarks_intro">
              Bookmarks are an organization tool to help keep track of articles,
              reviews you like, and even your own reviews. Stay tuned for more
              as our platform grows. More ideas? Send them{" "}
              <Link to="/contact"> our way!</Link>
            </p>
          </div>
        </div>
      </div>
      <br />

      <div
        className="my_reviews_top_div mr-1 ml-1"
        style={{
          borderRadius: "10px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="row pl-4 pr-2 mb-1 ">
          <div className="col-12 mt-2 ">
            <h5 className="">
              <b>Come back to this page for saving:</b>{" "}
            </h5>
            <ul className="comm_ideas">
              <li className="d-flex">
                <div className="col-1" style={{ paddingTop: "3px" }}>
                  {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                  {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                  <i class="far fa-check-circle" style={{ fontSize: "14px" }}></i>
                </div>
                <div className="col-11 comm_ideas_points">
                  Reviews from others you want to save
                </div>
              </li>
              <li className="d-flex">
                <div className="col-1" style={{ paddingTop: "3px" }}>
                  {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                  {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                  <i class="far fa-check-circle" style={{ fontSize: "14px" }}></i>
                </div>
                <div className="col-11 comm_ideas_points">
                  Your own reviews you have Bookmarked
                </div>
              </li>
              <li className="d-flex">
                <div className="col-1" style={{ paddingTop: "3px" }}>
                  {/* <img
                  src="/si_Check_circle.png"
                  alt=""
                />{" "} */}
                  {/* <small style={{fontWeight:'1000'}}>🗸</small> */}
                  <i class="far fa-check-circle" style={{ fontSize: "14px" }}></i>
                </div>
                <div className="col-11 comm_ideas_points">
                  Articles you have Bookmarked from the Learn blog
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-3 mb-3 ml-1 mr-1"
       style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor:"white",
        minHeight:"500px"
      }}>
        <div
          class="row pl-4 pr-2 m-0"
         
        >
          <div className={`col-6 text-center pt-3 toggle_bookmark_links ${showArticles ? 'active' : ''}`} onClick={toggle}>
  Articles
</div>
<div className={`col-6 text-center pt-3 toggle_bookmark_links ${!showArticles ? 'active' : ''}`} onClick={toggle}>
  Reviews
</div>

        </div>
        <hr/>
        <div
          class="pl-2 pr-2 "
          // style={{backgroundColor:"red"}}
        >
          {loading && (
            <div
              className="spinner-border"
              role="status"
              style={{ marginLeft: "45%" }}
            ></div>
          )}
         
              {!loading && showArticles && (
                <MyBookmarkedArticles loading={loading} bookmarks={bookmarks} />
              )}
              {!loading && !showArticles && (
                <MyBookmarkedReviews loading={loading} reviews={reviews} />
              )}
             
        </div>
        <br />
      </div>
    </div>
  );
};

export default BookMarks;

import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import GoogleLoginButton from './GoogleLoginButton';
import "../App.css";
import { useNavigate } from "react-router-dom";

function Login(props) {
  let navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [processing, setProcessing]=useState(false);
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Login' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  // useEffect(() => {
  //   if (typeof document !== 'undefined') {
  //     loadFacebookSDK(document, 'script', 'facebook-jssdk');
  //     initFacebook();
  //   }
  // }, []);
  

//   const loadFacebookSDK = async (d, s, id) => {
//     return new Promise(resolve => {
//       if (d.getElementById(id)) {
//         resolve();
//       }
//       const js = d.createElement(s);
//       js.id = id;
//       js.src = 'https://connect.facebook.net/en_US/all.js';
//       js.onload = resolve;
//       d.getElementsByTagName('head')[0].appendChild(js);
//     });
//   };

//   const initFacebook = async () => {
//     return new Promise(resolve => {
//       window.fbAsyncInit = function () {
//         window.FB.init({
//           appId: '1392264044764806', // You will need to change this
//           cookie: true, // This is important, it's not enabled by default
//           version: 'v2.5',
//           xfbml: true,
//         });
//         resolve();
//       };
//     });
//   };

//   let facebookresponse=null;
//   const logInWithFacebook = () => {
//     window.FB.login(response => {
//         if (response.authResponse) {
//             console.log(response);
//             window.FB.api('/me', { fields: 'id, first_name, last_name, email, picture' }, userInfoResponse => {
//                 console.log('User Information:', userInfoResponse);
//                 const facebookresponse = userInfoResponse;
              
//                 // Call handlefacebookOAuth here where facebookresponse is defined
//                 handlefacebookOAuth(facebookresponse);
//             });
//         } else {
//             console.log('Authorization failed.');
//         }
//     }, { scope: 'email,public_profile' });
// };

//   const handlefacebookOAuth = async (data) => {
//     try {
//       const response = await fetch(`${props.API_URL}/api/user/signup_login_google`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           full_name: data.first_name,
//           username: data.last_name,
//           email: data.email,
//           password: data.id,
//         }),
//       });

//       const json = await response.json();

//       if (json.status === "success") {
//         localStorage.setItem("token", json.token);
//         const userId = json.userId.user;
//         localStorage.setItem("userId", userId.toString());
//         navigate("/my_reviews");
//       } else {
//         props.showAlert(json.message, "error");
//       }
//     } catch (error) {
//       props.showAlert("Internal Server Error", "error");
//     }
//   };
  

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setProcessing(true);
  
      const response = await fetch(`${props.API_URL}/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      });
  
      const json = await response.json();
      setProcessing(false);
  
      if (json.status === "success") {
        localStorage.setItem("token", json.token);
        const userId = json.userId.user;
        localStorage.setItem("userId", userId.toString());
        const queryParams = new URLSearchParams(window.location.search);

        // Get the value of a specific query parameter
        const redirectQueryParam = queryParams.get('redirect');
        if(redirectQueryParam){
          
          navigate(redirectQueryParam);
        }
        else{
          navigate("/my_reviews");
        }
        
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      setProcessing(false);
      props.showAlert("Internal Server Error", "error");
    }
  };
  
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="row ml-1 mr-1" style={{ width: "100%" }} id="login_row">
        <div
          className="col-lg-7 col-md-7 col-sm-12 col-xs-12 d-flex flex-column align-items-center "
          style={{ position: "relative" }}
        >
          <div style={{ position: "relative" }}>
            <img
              className="img-fluid"
              src="/footer.png"
              style={{
                width: "400px",
                paddingBottom: "140px",
                paddingTop: "80px",
                marginTop: "50px",
              }}
              alt=""
            />
          </div>
          <Link to="/">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url('/login_bg.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: "0.2",
              }}
            />
          </Link>
        </div>

        <div className="col-lg-5  col-md-5 col-sm-12 col-xs-12">
       
          <div className="login_form ml-lg-5 mr-lg-5 ml-md-5 mr-md-5   p-4 rounded">
            <br />
            <form onSubmit={handleSubmit}>
              <h4 className="login_heading" >
                Login
              </h4>
              <br />

              <div className="form-group">
                <label
                  className="login_form_label"
                  htmlFor="exampleInputEmail1"
                >
                  Email{" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={credentials.email}
                  required
                  onChange={onChange}
                  id="exampleInputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  className="login_form_label"
                  htmlFor="exampleInputPassword1"
                >
                  Password
                </label>
                <input
                  type="password"
                  value={credentials.password}
                  required
                  onChange={onChange}
                  name="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="d-flex">
                <div className="col-8" style={{ marginLeft: "-15px" }}>
                  <button
                    type="submit"
                    className="login_btn btn btn-primary pl-4 pr-4"
                    disabled={processing}
                  >
                     {processing? "Processing...":"Login"}
                  </button>
                </div>
                <div className="col-6 ml-lg-3 ">
                  <Link to="/forgot_pass" id="forgot_pass_btn">
                    {" "}
                    Forgot password?
                  </Link>
                </div>
              </div>
            </form>
            <br />

            <div className="pt-3">
              <small className="text-muted">
                <span className="ask_account">Don't have an account?</span>
                <Link className="signup_link ml-1" to="/signup">
                  Sign up.
                </Link>
              </small>
              <br />
              <hr />
              <p>or</p>
              <br />
              {/* <div className="col-12" >
                <div >
               
       <button className="facebook-login-button d-flex align-items-center justify-content-center" onClick={logInWithFacebook}>
        <img src="facebook.png" style={{width: "18px",marginRight:"6px"}}/>
        Continue with Facebook
      </button>
                </div>
              </div> */}

              <div className="col-12"  >
              <GoogleOAuthProvider clientId="102818848537-ge0s7qpe3cb8219ik61hls07qd2p1tsu.apps.googleusercontent.com">
     
      <GoogleLoginButton showAlert={props.showAlert} />
    </GoogleOAuthProvider>
              </div>
              <br/>

              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

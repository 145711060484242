import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotifications } from './NotificationsContext'; 

import {
  faStar,
  faUsers,
  faMessage,
  faBell,
  faBookmark,
  faGraduationCap,
  faHouse
} from "@fortawesome/free-solid-svg-icons";
const BottomBar = (props) => {
  const location = useLocation();
  const { notifications } = useNotifications();

const notificationsCount = notifications.length;
  const style={
    color: "black",
    fontSize:"20px"
  }
  const circleStyle = {
    backgroundColor: 'purple',
    borderRadius: '50%',
    width: '37px', 
    height: '37px', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingTop:"5px"
  };
  return (
    <div
      className="container-fluid fixed-bottom pt-3 pb-2 bottom-bar"
      style={{ backgroundColor: "white", overflowX:"hidden" }}
    >
      <div className="row">
       
        {/* <div
          className={`col-3 d-flex justify-content-center align-items-center bottom_menu ${
            location.pathname === "/" ? "active" : ""
          }`}
        >
            <Link to="/">
                <FontAwesomeIcon icon={faHouse} style={style} />
            </Link>
          
        </div> */}
        <div
          className="bottom_icons text-center justify-content-center align-items-center bottom_menu "
        >
          <div className={`bottom_link  d-flex justify-content-center align-items-center ${location.pathname === "/my_reviews" ? "active" : ""}`} style={{width:"80%"}}>
            <Link to="/my_reviews" >
                <FontAwesomeIcon icon={faStar} style={style} />
            </Link>

            </div>
            <small style={{fontSize:"9px",fontWeight:"bold", marginLeft:"-14px"}}>Reviews</small>

          
        </div>
        {/* <div
          className={`col-3 d-flex justify-content-center align-items-center bottom_menu ${
            location.pathname === "/bookmarks" ? "active" : ""
          }`}
        >
            <Link to="/bookmarks">
                <FontAwesomeIcon icon={faBookmark} style={style} />
            </Link>
          
        </div> */}
        <div
          className="bottom_icons justify-content-center text-center align-items-center bottom_menu "
        >
          <div className={`bottom_link  d-flex justify-content-center align-items-center ${location.pathname === "/my_communities" ? "active" : ""}`} style={{width:"80%"}}>
            <Link to="/my_communities">
                <FontAwesomeIcon icon={faUsers} style={style} />

            </Link>
            </div>
            <small style={{fontSize:"9px",fontWeight:"bold",marginLeft:"-14px"}}>My Communities</small>

        </div>

        <div
          className="bottom_icons d-flex justify-content-center align-items-center bottom_menu "
        >
          <div className={`bottom_link  d-flex justify-content-center align-items-center`} style={{width:"80%"}}>
            <Link to="/search_reviews" style={{textDecoration:"none"}}>
                <div style={circleStyle}>
                  <h1>+</h1>
                </div>

            </Link>
            </div>
        </div>
        <div
        className="bottom_icons  justify-content-center text-center align-items-center bottom_menu "
        >
          <div className={`bottom_link  d-flex justify-content-center align-items-center ${location.pathname === "/messages" ? "active" : ""}`} style={{width:"80%"}}>
            <Link to="/messages">
                <FontAwesomeIcon icon={faMessage} style={style} />
            </Link>
            </div>
            <small style={{fontSize:"9px",fontWeight:"bold",marginLeft:"-14px"}}>Messages</small>


          
        </div>
        <div
         className="bottom_icons text-center justify-content-center align-items-center bottom_menu "
        >
          <div className={`bottom_link  d-flex justify-content-center align-items-center ${location.pathname === "/my_notifications" ? "active" : ""}`} style={{width:"80%"}}>
          <Link to="/my_notifications">
                <FontAwesomeIcon icon={faBell} style={style} />

            </Link>
          </div>
          {notificationsCount > 0 && localStorage.getItem('token') && (
              <div className="notification-badge2">{notificationsCount}</div>
            )}
          <small style={{fontSize:"9px",fontWeight:"bold",marginLeft:"-14px"}}>Notifications</small>

           
        </div>
       
      </div>
    </div>
  );
};

export default BottomBar;

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
const Profile = (props) => {
  const fileInputRef = useRef(null);
  const [isImageModified, setIsImageModified] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    userName: "",
    bio: null,
    profileImg: null,
    email_Rereview:'0',
    email_community_join:'0',
    email_post_comment:'0',
    email_post_like:'0',
    email_review_favorite:'0'
  });
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [saving, setSaving] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false); // State variable for triggering re-render
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changing,setChanging]=useState(false);
  const [likeNotification, setLikeNotification] = useState(false);
    const [commentNotification, setCommentNotification] = useState(false);
    const [favoriteNotification, setFavoriteNotification] = useState(false);

    const handleLikeNotificationToggle = () => {
        setLikeNotification(!likeNotification);
    };

    const handleCommentNotificationToggle = () => {
        setCommentNotification(!commentNotification);
    };

    const handleFavoriteNotificationToggle = () => {
        setFavoriteNotification(!favoriteNotification);
    };

  let navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await fetch(`${props.API_URL}/api/user/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const result = await response.json();

        if (result.status === "success") {
          const fetchedUserData = result.data[0];
          console.log(result.data[0])
          setUserInfo({
            fullName: fetchedUserData.F_name,
            userName: fetchedUserData.L_name,
            bio: fetchedUserData.bio || null,
            profileImg: fetchedUserData.profile_img || null,
            email_Rereview:fetchedUserData.email_Rereview,
            email_community_join:fetchedUserData.email_community_join,
            email_post_comment:fetchedUserData.email_post_comment,
            email_post_like:fetchedUserData.email_post_like,
            email_review_favorite:fetchedUserData.email_review_favorite




          });
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!localStorage.getItem("token")) {
    Swal.fire({
      title: "Sign in required",
      text: " In order to access this page, you will need to create an account or login to your account first",
      imageUrl: "/popup_check.svg",
      imageWidth: 60,
      imageHeight: 60,
      imageAlt: "Custom image",
      showCancelButton: true,
      confirmButtonColor: "#1E81C4",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",

      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/login?redirect=/profile");
      } else {
        navigate("/communities");
      }
    });
  }
    getUserData();
  }, [updateFlag]);

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      if(newPassword!==confirmPassword){
        props.showAlert("Password do not match","error")
        return;
      }
      const response = await fetch(
        `${props.API_URL}/api/user/change_password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ oldPassword, newPassword }),
        }
      );

      const result = await response.json();
      setChanging(true)
      if (result.status === "success") {
        props.showAlert(result.message, result.status);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setChanging(false)
        
      } else {
        props.showAlert(result.message, "error");
      }
     
      } catch (error) {
      props.showAlert("Internal Server Errorr", "error");
    }
    finally{
      setChanging(false)
    }
  };


  const saveProfilenotifications = async (e, type, value) => {
    e.preventDefault();

    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/profile");
        } else {
          navigate("/communities");
        }
      }); 
      return;
    }

    let selectedFile = null;
    if (isImageModified) {
      try {
        const { url } = await fetch(
          `${props.API_URL}/User_Profile/s3Url`
        ).then((res) => res.json());

        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: userInfo.profileImg,
        });

        const imageUrl = url.split("?")[0];
        selectedFile = imageUrl;
      } catch (error) {
        console.error("Error fetching S3 URL:", error);
      }
    }

    const userData = {
      f_name: userInfo.fullName,
      l_name: userInfo.userName,
      bio: userInfo.bio,
      profile_image: selectedFile,
      email_Rereview:userInfo.email_Rereview,
      email_community_join:userInfo.email_community_join,
      email_post_comment:userInfo.email_post_comment,
      email_post_like:userInfo.email_post_like,
      email_review_favorite:userInfo.email_review_favorite,
    };
    if(type==='email_cj'){
      userData['email_community_join']=value
    }
    if(type==='email_pl'){
      userData['email_post_like']=value
    }
    if(type==='email_rr'){
      userData['email_Rereview']=value
    }
    if(type==='email_pc'){
      userData['email_post_comment']=value
    }
    if(type==='email_rf'){
      userData['email_review_favorite']=value
    }


    
    fetch(`${props.API_URL}/api/user/update_profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((json) => {
        setSaving(false);

        if (json.status === "success") {
         
          setIsImageModified(false);
          setUpdateFlag((prevFlag) => !prevFlag);
          setIsEditMode(false);
        } else {
          props.showAlert(json.message, "error");
        }
      })
      .catch((error) => {
        props.showAlert("Internal Server Error", "error");
      });
  };

  const saveProfile = async (e) => {
    e.preventDefault();

    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/profile");
        } else {
          navigate("/communities");
        }
      }); 
      return;
    }

    let selectedFile = null;
    if (isImageModified) {
      try {
        const { url } = await fetch(
          `${props.API_URL}/User_Profile/s3Url`
        ).then((res) => res.json());

        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: userInfo.profileImg,
        });

        const imageUrl = url.split("?")[0];
        selectedFile = imageUrl;
      } catch (error) {
        console.error("Error fetching S3 URL:", error);
      }
    }

    const userData = {
      f_name: userInfo.fullName,
      l_name: userInfo.userName,
      bio: userInfo.bio,
      profile_image: selectedFile,
      email_Rereview:userInfo.email_Rereview,
      email_community_join:userInfo.email_community_join,
      email_post_comment:userInfo.email_post_comment,
      email_post_like:userInfo.email_post_like,
      email_review_favorite:userInfo.email_review_favorite,
    };

    setSaving(true);
    fetch(`${props.API_URL}/api/user/update_profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((json) => {
        setSaving(false);

        if (json.status === "success") {
          props.showAlert(json.message, json.status);
          setIsImageModified(false);
          setUpdateFlag((prevFlag) => !prevFlag);
          setIsEditMode(false);
        } else {
          props.showAlert(json.message, "error");
        }
      })
      .catch((error) => {
        props.showAlert("Internal Server Error", "error");
      });
  };

  const handleEditImage = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUserInfo({ ...userInfo, profileImg: selectedFile });
      setIsImageModified(true);
      const selectedImagePreviewURL = URL.createObjectURL(selectedFile);
      setSelectedImagePreview(selectedImagePreviewURL);
    }
  };


  const deactivateAccount = async () => {
    // Show a confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to deactivate your account?");
  
    if (!isConfirmed) {
      return;
    }
  
    try {
      // Proceed with deactivation
      const response = await fetch(`${props.API_URL}/api/user/deactivate_account`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
    
      const result = await response.json();
  
      if (result.status === "success") {
        console.log("Account deactivated successfully");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        navigate("/signup")
      } else {
        props.showAlert(result.message,"error")
      }
    } catch (error) {
      console.error("Error deactivating account:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Profile' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="profile">
        <div className="profile_box ml-1 mr-1 mt-3">
          <div className="row pl-4 pr-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 mt-2 mb-2">
              <h4 className="profile_head">Profile</h4>
            </div>
            {!isEditMode && (
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 mt-2">
                <button
                  className=" cancel_edit_profile pt-1 pb-1 
                      view-mode"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  Edit Profile
                </button>
              </div>
            )}
          </div>
          <div className="row pl-4 pr-2">
            <div className="d-flex align-items-center">
              <div className="profile-image-container">
                <img
                  src={
                    selectedImagePreview
                      ? selectedImagePreview
                      : userInfo.profileImg || "user.png"
                  }
                  alt=""
                  className={`profile-image ${
                    isEditMode ? "user_profile_pic" : ""
                  }`}
                />
                {isEditMode && (
                  <label htmlFor="fileInput" className="camera-label">
                    <i
                      className="fa fa-camera"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2,
                        display: "block",
                      }}
                    ></i>
                  </label>
                )}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>

              <div style={{ marginLeft: "5px" }}>
                <div>
                  {isEditMode ? (
                    <input
                      type="text"
                      value={userInfo.fullName}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, fullName: e.target.value })
                      }
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #E4E4E4",
                        background: "#F1F1F1",
                        fontSize: "14px",
                      }}
                    />
                  ) : (
                    <div className="full_name">{userInfo?.fullName}</div>
                  )}
                </div>
                <div className="mt-1">
                  {isEditMode ? (
                    <input
                      type="text"
                      value={userInfo.userName}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, userName: e.target.value })
                      }
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #E4E4E4",
                        background: "#F1F1F1",
                        fontSize: "14px",
                      }}
                    />
                  ) : (
                    <div className="user_name ">@{userInfo?.userName}</div>
                  )}
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="row pl-4 pr-2 mt-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-2">
              <div className="d-flex">
                <div style={{ marginRight: "20px" }}>
                  <h5 className="add_bio">
                    {userInfo.bio ? "Bio/About" : "Add Bio/About"}{" "}
                  </h5>
                </div>
                <div>
                  {!userInfo?.bio && (
                    <>
                      {isEditMode ? (
                        ""
                      ) : (
                        <div
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #4267B2",
                            height: "22px",
                            width:"22px",
                            marginTop: "6px",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsEditMode(!isEditMode)}
                        >
                          <h6 style={{ color: "#4267B2" ,textAlign:"center"}}>+</h6>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {isEditMode ? (
                <textarea
                  rows="4"
                  className="form-control"
                  placeholder="Write here"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                    fontSize: "14px",
                  }}
                  value={userInfo.bio}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, bio: e.target.value })
                  }
                ></textarea>
              ) : (
                userInfo?.bio && <div className="bio">{userInfo?.bio}</div>
              )}
            </div>
          </div>
          <div className="row pl-4 pr-2 mt-3">
            {isEditMode && (
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                {isEditMode && (
                  <button className="btn save_bio" onClick={saveProfile}>
                    {saving ? "Saving" : "Save Changes"}
                  </button>
                )}
                <button
                  className={` cancel_edit_profile pt-1 pb-1 ${
                    isEditMode && "edit-mode"
                  }`}
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  {isEditMode && "Cancel"}
                </button>
              </div>
            )}
          </div>
          <div className="row pl-4 pr-2 mt-3">
            <form onSubmit={changePassword}>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <h5 className="change-password">Change Password</h5>
                <input
                  className="password_fields"
                  required
                  type="password"
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
                <input
                  className="password_fields"
                  required
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
                <input
                  className="password_fields"
                  required
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="row pl-3 pr-2 mt-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                  <button type="submit" className="btn  change-password-btn">
                    {saving ? "Changing  password" : "Change password"}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="row pl-4 pr-2 mt-3 mb-1">
          <h4 className="pl-3 email_me_heading">Email me:</h4>
          </div>
          <div className="row pl-4 pr-2  mb-1">
            
            <br/>
            <div className="col-9 ml-1 email_me_para">
                  <p>When someone likes my post.</p>
            </div>
            <div className="col-2">
            <div className="custom-control custom-switch">

<input type="checkbox" className="custom-control-input" checked={userInfo.email_post_like==='1'}  id="customSwitch1" 
onChange={(e) => {
  const newValue = e.target.checked ? '1' : '0';
  setUserInfo(prevUserInfo => ({
    ...prevUserInfo,
    email_post_like: newValue
  }));
 
  saveProfilenotifications(e,'email_pl',newValue);
}}
/>
<label className="custom-control-label" htmlFor="customSwitch1"></label>

          </div>

</div>
          
          </div>
          <div className="row pl-4 pr-2  mb-1">
            
            <br/>
            <div className="col-9 ml-1 email_me_para">
                  <p>When someone comments on my post.</p>
            </div>
            <div className="col-2">
            <div className="custom-control custom-switch">

<input type="checkbox" className="custom-control-input" checked={userInfo.email_post_comment==='1'}  id="customSwitch2" 
onChange={(e) => {
  const newValue = e.target.checked ? '1' : '0';
  setUserInfo(prevUserInfo => ({
    ...prevUserInfo,
    email_post_comment: newValue
  }));
 
  saveProfilenotifications(e,'email_pc',newValue);
}}
/>
<label className="custom-control-label" htmlFor="customSwitch2"></label>

          </div>

</div>
          
          </div>
          <div className="row pl-4 pr-2  mb-1">
            
            <br/>
            <div className="col-9 ml-1 email_me_para">
                  <p>When someone bookmarks my review.</p>
            </div>
            <div className="col-2">
            <div className="custom-control custom-switch">

<input type="checkbox" className="custom-control-input" checked={userInfo.email_review_favorite==='1'}  id="customSwitch3"
onChange={(e) => {
  const newValue = e.target.checked ? '1' : '0';
  setUserInfo(prevUserInfo => ({
    ...prevUserInfo,
    email_review_favorite: newValue
  }));
 
  saveProfilenotifications(e,'email_rf',newValue);
}}
 />
<label className="custom-control-label" htmlFor="customSwitch3"></label>

          </div>

</div>
          
          </div>
          <div className="row pl-4 pr-2  mb-1">
            
            <br/>
            <div className="col-9 ml-1 email_me_para">
                  <p>When someone adds a comment on my review.</p>
            </div>
            <div className="col-2">
            <div className="custom-control custom-switch">

<input type="checkbox" className="custom-control-input" checked={userInfo.email_Rereview==='1'}  id="customSwitch4" 
onChange={(e) => {
  const newValue = e.target.checked ? '1' : '0';
  setUserInfo(prevUserInfo => ({
    ...prevUserInfo,
    email_Rereview: newValue
  }));
 
  saveProfilenotifications(e,'email_rr',newValue);
}}/>
<label className="custom-control-label" htmlFor="customSwitch4"></label>

          </div>

</div>
          
          </div>
          <div className="row pl-4 pr-2  mb-1">
            
            <br/>
            <div className="col-9 ml-1 email_me_para">
                  <p>When someone joins my community.</p>
            </div>
            
            <div className="col-2">
            <div className="custom-control custom-switch">

<input type="checkbox" className="custom-control-input" checked={userInfo.email_community_join==='1'}  id="customSwitch5" 
onChange={(e) => {
  const newValue = e.target.checked ? '1' : '0';
  setUserInfo(prevUserInfo => ({
    ...prevUserInfo,
    email_community_join: newValue
  }));
 
  saveProfilenotifications(e,'email_cj',newValue);
}}/>
<label className="custom-control-label" htmlFor="customSwitch5"></label>

          </div>

</div>
          
          </div>
          
          <div className="row pl-4 pr-2  mt-5">
            <div className="col-8">
              <h4 className="deactivate_account">Deactivate Account</h4>
            </div>
            <div className="col-4 mt-4">
            <button className="deactivate_account_btn" onClick={deactivateAccount}>
                Deactivate

              </button>
            </div>

          </div>
         
         
          <div className="row pl-4 mb-5 pr-2">
            <div className="col-8">
              <h4 className="delete_account">Delete Account</h4>
            </div>
            <div className="col-4 mt-4">
            <button className="delete_account_btn" onClick={deactivateAccount}>
                Delete 

            </button>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default Profile;

import React, {useEffect, useState} from 'react'

const Conversation = ({ chatData, currentUser, online,API_URL }) => {
    const [userData, setUserData] = useState(null);
  
    useEffect(() => {
        

        const otherUserId =
        chatData?.member1Id.toString() === currentUser?.toString()
          ? chatData?.member2Id.toString()
          : chatData?.member1Id.toString();    
  
      const getUserData = async () => {
        try {
          const response = await fetch(`${API_URL}/api/user/info/${otherUserId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
          });
  
          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }
  
          const result = await response.json();
  
          if (result.status === "success") {
            const fetchedUserData = result.data[0];
            setUserData(fetchedUserData);
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      if (otherUserId) {
        getUserData();
      }
    }, [chatData, currentUser]);
  
    return (
        <>
          {userData && (
            <div className="follower conversation pl-3">
              <div className='d-flex'>
                <div className='user_profile_messages_div'>

                
                <img
                  src={ userData?.profile || "user.png"}
                  alt="Profile"
                  className="followerImage"
                  
                />
                {online ?
                 (<div className="online-dot"></div>)
                 :(<div className="offline-dot">
                  </div> )}
                  </div>
                <div className="contact_name mt-2" >
                  <span>{userData?.L_name}</span>

                </div>
              </div>
            </div>
          )}
          <hr style={{ width: "100%", border: "0.1px solid #ececec" }} />
        </>
      );
      
  }
  
  export default Conversation;
  


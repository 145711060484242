import React, { useState, useEffect } from "react";
import TopNavbar from "./TopNavbar";
import { Link } from "react-router-dom";

const TOS = (props) => {
  const currentYear = new Date().getFullYear();
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  
  const currentMonthIndex = new Date().getMonth();
  const currentMonthName = months[currentMonthIndex];
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Terms of service' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}>
      <div
        className="container pt-4 mb-2 "
        
      >
        <div
          className="ml-1 mr-1"
          style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            background: "#FFF",
          }}
        >
          <div className="row mb-3 mx-0 px-0 mt-4 ">
            <div className="col-12 p-5">
              <h6 className="manorlane_tos_heading">
                ManorLane Terms of Service
              </h6>

              <p className="manorlane_tos_para ">
                Last updated: {currentMonthName} {currentYear} 
                
                </p>
                <p className="manorlane_tos_para ">
                
                Welcome to ManorLane! These Terms of
                Service ("Terms") govern your use of the ManorLane platform,
                including the website, mobile applications, and any related
                services provided by ManorLane, LLC (referred to as "we," "our,"
                or "us"). By using ManorLane, you (“Users” or “User”) agree to
                these Terms, so please read them carefully.
              </p>
              <h6 className="manorlane_tos_sub_heading">1. User Agreement:</h6>

              <p className="manorlane_tos_para">
                By accessing or using ManorLane, you agree to be bound by these
                Terms. If you do not agree with these Terms, please do not use
                our services.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                2. User Responsibilities:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. ManorLane is a platform for collecting reviews on
                landlord-owned properties, Homeowner's Associations (HOA), and
                Property Management Companies. You are responsible for the
                content you post, and you agree not to post misleading, false,
                or malicious information. <br /><br />
                b. You are responsible for any reviews or posts or photos you
                submit. ManorLane is not responsible for the accuracy, legality,
                or safety of such content. <br /><br />
                c. By using our website or mobile application, you agree to post
                photos that are not prohibited.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                3. Prohibition of Offensive Photos:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. Users of this website ("Users") are strictly prohibited from
                uploading, displaying, or distributing offensive photos.
                Offensive photos are those that contain, but are not limited to,
                explicit or graphic content that is violent, sexually explicit,
                discriminatory, harassing, defamatory, or otherwise harmful or
                offensive to others. <br /> <br />
                b. We reserve the right to determine the suitability of photos
                and may remove any offensive photos without prior notice. We may
                also suspend or terminate the account of any User found in
                violation of this clause. <br /> <br />
                c. Users are responsible for the content they upload and share
                on this website and shall indemnify the website against any
                claims, liabilities, or damages arising from their breach of
                this clause. Prohibition of Offensive Language Clause:
              </p>
              <h6 className="manorlane_tos_sub_heading">
                4. Prohibition of Offensive Language:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. Users are expected to engage in respectful and courteous
                communication while using this website. Offensive language
                includes, but is not limited to, profanity, hate speech,
                discriminatory language, threats, and any content that may harm,
                insult, or harass other Users. <br /> <br />
                b. Users must not use offensive language in comments, posts,
                messages, or any other form of communication on the website. We
                reserve the right to moderate and remove offensive language, and
                Users found in violation of this clause may face warnings,
                suspension, or account termination. <br /> <br />
                c. Users are responsible for the content they generate or
                contribute to the website, and they agree to indemnify the
                website against any legal claims, disputes, or damages resulting
                from their breach of this clause. <br /> <br />

                </p>
                <p className="manorlane_tos_para ">
                By using this website, Users acknowledge and agree to abide by
                the above-stated clauses concerning the prohibition of offensive
                photos and offensive language. Failure to comply with these
                clauses may result in account actions and legal consequences as
                deemed necessary by the website.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                5. Privacy and Anonymity:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. All posts on ManorLane are anonymous but public. While we
                strive to protect your privacy, remember that anything you post
                can be viewed by others.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                6. Copyright and Intellectual Property:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. By posting content on ManorLane, you grant ManorLane a
                non-exclusive, royalty-free, perpetual, and worldwide license to
                use, reproduce, modify, adapt, publish, translate, create
                derivative works from, distribute, and display such content.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                7. Disclaimers and Liability:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. ManorLane does not endorse or guarantee the accuracy of any
                user-generated content. b. We are not liable for any
                consequences resulting from reviews or interactions on
                ManorLane, including but not limited to disputes, damages, or
                losses
              </p>
              <h6 className="manorlane_tos_sub_heading">8. Termination:</h6>

              <p className="manorlane_tos_para pl-4">
                a. We reserve the right to terminate your access to ManorLane at
                our discretion, with or without notice, if you violate these
                Terms.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                9. Changes to the Terms:
              </h6>

              <p className="manorlane_tos_para pl-4">
                a. ManorLane may update these Terms from time to time. We will
                notify you of any changes. Your continued use of the platform
                after such updates constitutes your agreement to the revised
                Terms.
              </p>
              <h6 className="manorlane_tos_sub_heading">10. Miscellaneous:</h6>

              <p className="manorlane_tos_para pl-4">
                a. These Terms constitute the entire agreement between you and
                ManorLane regarding the use of our platform. <br /> <br />
                b. If any part of these Terms is found to be unenforceable, that
                part will be limited to the minimum extent necessary, and the
                remaining Terms will remain in full force and effect.
              </p>

              <hr />

              <h6 className="manorlane_tos_sub_heading">Copyright Notice:</h6>

              <p className="manorlane_tos_para">
                All content and materials on the ManorLane website and mobile
                applications are protected by copyright law. ManorLane and its
                licensors retain all rights to these materials, including but
                not limited to text, graphics, logos, images, audio, video, and
                software.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                Ownership of Content:
              </h6>

              <p className="manorlane_tos_para pl-4">
                1. The content on ManorLane, including all user-generated
                content, is the property of ManorLane or its users, as
                applicable. <br />
                2. All rights not expressly granted are reserved by ManorLane.
              </p>
              <h6 className="manorlane_tos_sub_heading">Use of Content:</h6>

              <p className="manorlane_tos_para pl-4">
                1. You may view, download, and print content from ManorLane for
                personal, non-commercial use only. 2. You may not modify,
                reproduce, distribute, publicly display, or create derivative
                works from any content without prior written permission from
                Manorlane.
              </p>
              <h6 className="manorlane_tos_sub_heading">
                Copyright Infringement:
              </h6>

              <p className="manorlane_tos_para ">
                ManorLane respects the intellectual property rights of others.
                If you believe that any content on the platform infringes your
                copyright, please contact us at [Contact Email Address] with the
                following information:
                
                </p> <br />
                <p  className="manorlane_tos_para pl-4">
                - A description of the copyrighted work you believe has been
                infringed. <br /><br />
                - The location of the allegedly infringing material on
                ManorLane. <br /><br />
                - Your contact information. <br /> <br />
                - A statement by you that you have a good-faith belief that the
                use is not authorized by the copyright owner, its agent, or the
                law. <br /> <br />
                - A statement by you, made under penalty of perjury, that the
                information in your notice is accurate and that you are the
                copyright owner or authorized to act on the copyright owner’s
                behalf. <br />

                </p>
                <h6 className="manorlane_tos_sub_heading">
                Contact Information
              </h6>
                 <p className="manorlane_tos_para ">
                  For copyright-related and terms inquiries, please {" "}
                <Link
                  to="/contact"
                  style={{
                    color: "#1E81C4",
                    fontFamily: "'Lato',sans-serif",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "206.682%",
                    textDecoration: "underline",
                  }}
                >
                  Contact us.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
        <br/>
</div>
     
    </>
  );
};

export default TOS;

import  { useEffect } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

export default function Alert(props) {
  useEffect(() => {
    if (props.alert) {
      Swal.fire({
        title: `${props.alert.type.toUpperCase()}:`,
        text: props.alert.msg,
        icon: props.alert.type,
        showConfirmButton: true,
        heightAuto: false,
        confirmButtonColor: "#1E81C4",
        timer: 8000,
        customClass: {
          popup: 'custom-popup-class', 
          title: 'custom-title-class',
          text: 'custom-text-class',
        },
       
      }
      
      );
    }
  }, [props.alert]);

  return null; 
}






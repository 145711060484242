import React,{useEffect} from "react";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useNotifications } from './NotificationsContext'; 
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const Notifications = (props) => {
  const { notifications,markNotificationAsRead } = useNotifications();
  let navigate=useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to access this page, you will need to create an account or login to your account first",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/my_notifications");
        } else {
          navigate("/communities");
        }
      });
    }
  },[])
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Notifications' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  const Details=((id,type,n_id)=>{

    if(type=="re_review"){
      navigate( `/review_detail/${id}`);

    }
    else if(type=="bookmark_review"){
      navigate( `/review_detail/${id}`);

    }
    else if (type==="like_post" || type==="comment_post"){
      navigate( `/post/${id}`);
    }
    else if(type==='community_join'){
      navigate( `/community_page/${id}`);
    }
    markNotificationAsRead(n_id)
  })
  return (
    <>
      <div className="mt-4">
        <div className=" notifications_comp coomunities_top_div ml-1 mr-1 pt-4"
         style={{
          borderRadius: "10px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        }}
        >
          <b className="notificatios_heading pl-4 pr-2"> Notifications </b>
          <div className="all_notifications">

          
          <div className="row pl-4 pr-4 ">
          {(notifications && notifications.length>0)
          ?
(notifications.map(notification => (
              
              <div key={notification.id} className="col-12 ml-2  d-flex notification-item" style={{cursor:"pointer"}} >
              <div className="col-1 p-0">
              <div className="user_profile_messages_div2 mt-1">
              <img  src={notification.profile_img || "user.png"}/>

              </div>

              </div>
                <div className="col-lg-10 col-md-10 col-9 mt-1" onClick={()=>Details(notification.action_id,notification.action_type,notification.notification_id)}>
                <b style={{fontSize:"16px"}}>{notification.Text.split(" ")[0]} {" "}</b>
                    {notification.Text.substring(
                      notification.Text.indexOf(" ") + 1
                    )}....
                  {/* You can display other information from the notification object as needed */}
                </div>
                <div className="col-lg-1 col-md-1 col-2">
                
                  <Tippy
                      interactive={true}
                      arrow={true}
                      content={
                        <div id="tooltip_mark_as_read">
                          <small >
                           Mark as read
                          </small>
                        </div>
                      }
                    >
                     <FontAwesomeIcon
                    icon={faEnvelopeOpen}
                    onClick={() => markNotificationAsRead(notification.notification_id)}
                    style={{ marginLeft: "10px", cursor: "pointer",position:"absolute" }}
                  />
                    </Tippy>
                </div>
                
              </div>
            ))):(
              <div className="d-flex justify-content-center align-items-center text-center" style={{marginTop:"30%"}}>
              <div>
              <img src="no-notification.svg" style={{width:"60px"}}/>
              <br/>
              <b>No notifications found</b>
                <p>
                This is where you'll see notifications for likes on your comments, comments on your posts, invitations to communities, etc.
                </p>
              </div>
              
              </div>

            )
          }
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;

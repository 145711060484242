import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import jwt_decode from "jwt-decode";
import GoogleLoginButton from './GoogleLoginButton';

import "../App.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ReCAPTCHA from "react-google-recaptcha";

function Signup(props) {
  let navigate = useNavigate();
  const [CaptchaDone, setCaptchaDone] = useState(false);
  const captcha_key=process.env.REACT_APP_CAPTCHA_KEY

  const [credentials, setCredentials] = useState({
    fname: "",
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });
  const [processing, setProcessing]=useState(false)
  // useEffect(() => {
  //   if (typeof document !== 'undefined') {
  //     loadFacebookSDK(document, 'script', 'facebook-jssdk');
  //     initFacebook();
  //   }
  // }, []);
  const handleCaptchaVerify = (response) => {
    setCaptchaDone(true);
  };

 

//   const loadFacebookSDK = async (d, s, id) => {
//     return new Promise(resolve => {
//       if (d.getElementById(id)) {
//         resolve();
//       }
//       const js = d.createElement(s);
//       js.id = id;
//       js.src = 'https://connect.facebook.net/en_US/all.js';
//       js.onload = resolve;
//       d.getElementsByTagName('head')[0].appendChild(js);
//     });
//   };

//   const initFacebook = async () => {
//     return new Promise(resolve => {
//       window.fbAsyncInit = function () {
//         window.FB.init({
//           appId: '1392264044764806', // You will need to change this
//           cookie: true, // This is important, it's not enabled by default
//           version: 'v2.5',
//           xfbml: true,
//         });
//         resolve();
//       };
//     });
//   };

//   const logInWithFacebook = () => {
//     window.FB.login(response => {
//         if (response.authResponse) {
//             console.log(response);
//             window.FB.api('/me', { fields: 'id, first_name, last_name, email, picture' }, userInfoResponse => {
//                 console.log('User Information:', userInfoResponse);
//                 const facebookresponse = userInfoResponse;
                
//                 // Call handlefacebookOAuth here where facebookresponse is defined
//                 handlefacebookOAuth(facebookresponse);
//             });
//         } else {
//             console.log('Authorization failed.');
//         }
//     }, { scope: 'email,public_profile' });
// };
// const handlefacebookOAuth = async (data) => {
  
//   try {
//     const response = await fetch(`${props.API_URL}/api/user/signup_login_google`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         full_name: data.first_name,
//         username: data.last_name,
//         email: data.email,
//         password: data.id,
//       }),
//     });

//     const json = await response.json();

//     if (json.status === "success") {
//       localStorage.setItem("token", json.token);
//       const userId = json.userId.user;
//       localStorage.setItem("userId", userId.toString());
//       navigate("/my_reviews");
//     } else {
//       props.showAlert(json.message, "error");
//     }
//   } catch (error) {
//     props.showAlert("Internal Server Error", "error");
//   }
// };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (credentials.password !== credentials.cpassword) {
      props.showAlert("Confirm password doesn't match", "error");
      return;
    }
  
    setProcessing(true);
  
    try {
      const response = await fetch(`${props.API_URL}/api/user/create_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          full_name: credentials.fname,
          username: credentials.name,
          email: credentials.email,
          password: credentials.password,
        }),
      });
  
      const json = await response.json();
      setProcessing(false);

      if (json.status === "success") {
        props.showAlert(json.message, json.status);
        navigate("/login");
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    } finally {
      setProcessing(false);
    }
  };
  
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Signup' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="row ml-1 mr-1" style={{ width: "100%" }} id="login_row">
        <div
          className="col-lg-7 col-md-7 col-sm-12 col-xs-12  d-flex flex-column align-items-center "
          style={{ width: "100%" }}
        >
          <div className="mt-lg-5">
            <Link className="nav-link ml-lg-4 ml-md-4" to="/">
              {" "}
              <img
                src="/footer.png"
                className="img-fluid"
                style={{ width: "350px", cursor: "pointer", marginTop: "30px" }}
                alt=""
              />
            </Link>
          </div>
          <div className="instructions ml-lg-5  mr-lg-5 ml-md-5 mr-md-5 ml-1 mr-1">
            <div className="ml-5 mr-5 mt-5 mb-5 signup_page_instructions">
              <h5 style={{ fontWeight: "bold", paddingTop: "10px" }}>
                If you have an account:
              </h5>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle.png"  alt="" /> Track
                and view your Reviews
              </li>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle.png"  alt="" /> Comment
                and Bookmark other users’ Reviews
              </li>
              <li style={{  listStyle: "none"}}>
                <img src="/si_Check_circle.png" alt="" />{" "}
                Bookmark Learning articles
              </li>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle.png"  alt="" /> Flag
                Reviews
              </li>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle.png"  alt="" /> Join
                public Communities
              </li>
              <li style={{ listStyle: "none" }}>
                <img src="/si_Check_circle.png" alt="" /> Create
                and join private Communities
              </li>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle.png"  alt="" /> Send
                and receive message
              </li>
              <br />
             <b>Accounts are always free. Always confidential.</b>  <br />
              <br />
              <h5 style={{ fontWeight: "bold" }}>
                No account? Still access these features:
              </h5>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle2.png"  alt="" /> Write
                and search Reviews
              </li>
              <li style={{  listStyle: "none" }}>
                <img src="/si_Check_circle2.png"  alt="" />{" "}
                Learning Blog
              </li>
              <li style={{ listStyle: "none" }}>
                <img src="/si_Check_circle2.png"  alt="" /> Browse
                public Communities
              </li>
            </div>
          </div>


          <br />
          <br />
          <Link to="/">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url('/signup_bg.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                opacity: "0.2",
              }}
            />
          </Link>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div className="signup_form ml-lg-5 mr-lg-5 ml-md-5 mr-md-5 mt-lg-5  p-4 rounded">
            <br />
            <form onSubmit={handleSubmit}>
              <h4 className="signup_heading">Sign up</h4>
              <br />
              <div className="form-group">
                <label className="signup_form_label" htmlFor="name">
                  Full name (optional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  
                  id="fname"
                  value={credentials.fname}
                  onChange={onChange}
                  name="fname"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name" className="signup_form_label">
                  Create a username (this will be displayed publicly)
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  id="name"
                  value={credentials.name}
                  onChange={onChange}
                  name="name"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="signup_form_label"
                >
                  Email (your email will always remain anonymous){" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={credentials.email}
                  required
                  onChange={onChange}
                  id="exampleInputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleInputPassword1"
                  className="signup_form_label"
                >
                  Password
                </label>
                <input
                  type="password"
                  value={credentials.password}
                  required
                  onChange={onChange}
                  name="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleInputPassword2"
                  className="signup_form_label"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  value={credentials.cpassword}
                  required
                  onChange={onChange}
                  name="cpassword"
                  className="form-control"
                  id="exampleInputPassword2"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E4E4E4",
                    background: "#F1F1F1",
                  }}
                />
              </div>
              <div className="row mb-2 mr-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6 ">
              <ReCAPTCHA
                sitekey={captcha_key}
                onChange={handleCaptchaVerify}
              />
            </div>
          </div>

              <button type="submit" disabled={processing || !CaptchaDone} className="signup_btn btn pl-4 pr-4">
                {processing?"Processing....":"Sign up"}
              </button>
            </form>

            <br />

            <div className=" pt-3">
              <small className="text-muted">
                <p className="by_signing_agree">
                  By signing up, you agree to our  {" "}
                  <Link
                    to="/terms_of_services"
                    style={{
                      color: "#5858FA",
                      textDecoration: "underline",
                      fontFamily:"'Lato',sans-serif"
                    }}
                  >
                   
                    Terms of Service
                  </Link>{" "}
                  and {" "}
                  <Link
                    to="/privacy_policy"
                    style={{
                      color: "#5858FA",
                      textDecoration: "underline",
                      fontFamily:"'Lato',sans-serif"
                    }}
                  >
                    
                    Privacy Policy
                  </Link>{" "}
                  and confirm that you are at least 18 years old.
                </p>
                <span className="ask_account">Already have an account?</span>

                <Link className="login_link ml-1" to="/login">
                  Log in.
                </Link>
              </small>
              <br />
              <hr />
              <p>or</p>
              {/* <div className="col-12 pt-3">
              <button className="facebook-login-button d-flex align-items-center justify-content-center" onClick={logInWithFacebook}>
        <img src="facebook.png" style={{width: "18px",marginRight:"6px"}}/>
        Continue with Facebook
      </button>
                <div>
                 
                 
                </div>
              </div>

              <br /> */}

              <div className="col-12 pb-4">
                <GoogleOAuthProvider clientId="102818848537-ge0s7qpe3cb8219ik61hls07qd2p1tsu.apps.googleusercontent.com">
                 
                  <GoogleLoginButton showAlert={props.showAlert} />
                </GoogleOAuthProvider>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;

import React, {useEffect} from "react";
import TopNavbar from "./TopNavbar";
import { Link } from "react-router-dom";



const About = (props) => {
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'About' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}>
      <div
        className="container pt-4 mb-2 "
      >
        <div className="about_div ml-1 mr-1 mt-4" >
          <div className="row mb-2 px-0 mx-0">
            <div className="col-12 p-5">
              <h6 className="about_manorlane">About ManorLane</h6>
              <p className="about_manorlane_para">
                When I was 13 years old, some friends and I built a makeshift
                fort in the woods. We “constructed” it out of found plywood,
                moldy carpet, and some rusty nails we found at an abandoned
                construction site.
                <br />
                <br />
                

                The fort was where found solace away from our hostile homelives;
                a place where a 13-year-old kid in the 80’s could drink, smoke,
                or listen to whatever they pleased. We lovingly called this
                place “The Manor”. The Manor was our castle, our kingdom, our
                domain where we were the lords. At The Manor, we made the rules,
                lived free, and escaped the perceived tyranny of our domestic
                lives.
                <br />
                <br />
                Having been a lifelong renter, I have seen my fair share of both
                good and bad living environments, landlords and neighbors. My
                mother, a senior who lived under a faceless HOA for decades saw
                virtually no return on her monthly expense.
                <br />
                <br />

                ManorLane is a dream of mine. To create a forum for full
                transparency and accountability, where it should exist first and
                foremost, in the home. Home: our biggest expense of money, time,
                and emotion. We can read reviews about restaurants before we
                dine, we can crowdsource a set of headphones or TV, yet, with
                our homes, we go in virtually blind.
                <br />
                <br />
                ManorLane is here to provide renters, HOA members and anyone
                living at the mercy of other people’s governance, a platform for
                information, transparency, accountability and community.
                <br />
                <br />
                We hope you share your lived experiences from the past and
                present, and learn from others on the site. Let’s create a level
                playing field together so we can all live like nobility, and
                become the lords of our manor.
               
              </p>
              <br />
              <h5 className="why_manorlane_created">Why was ManorLane created?</h5>
              <p className=" about_manorlane_para ">
              ManorLane is a platform for community, accountability, and education. We aspire to democratize a demographic that, until today, has been largely disenfranchised and powerless.
              <br />
              <br />
              There are over 140 million renters in the United States. It's shocking that renters still have no official platform to share their experiences or find support. Likewise, these same renters, when searching new places to live, have no way of educating themselves about the quality of a unit, building or landlord before moving in.
              <br />
              <br />
              HOA and property management members experience the same reality, frustrated with deceitful business practices and politics, feeling their monthly fees are wasted, leaving their voices unheard.
              <br />
              <br />
              We aim to improve the circumstances for the silent majority of Americans who live at the mercy of the system that has yet to give them an expression of power and agency.
              <br />
              <br />
              Have more questions? Check out our <Link to="/faq">FAQ</Link>  page.
              <br />
               <br  />
                D.C.
                <br />
                ManorLane Founder



              </p>
            </div>
          </div>
        </div>
      
      </div>
      <br/>
      </div>
      
    </>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";

const MyBookmarkedReviews = ({ loading, reviews }) => {
  return (
    <div>
      {(Array.isArray(reviews) && reviews.length > 0) || loading ? (
        reviews.map((review, index) => (
          <div className="row ">
 <div className="col-12 mt-1 mb-2" key={index}  >
            <div className="review-box">
              <div className="icon">
                {review.Name === "Landlord" ? (
                  <img
                    className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                    alt=""
                    src="/landlord.png"
                  />
                ) : review.Name === "HOA" ? (
                  <img
                    className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                    alt=""
                    src="/c1.png"
                  />
                ) : (
                  // Default image if none of the conditions match
                  <img
                    className="group-icon img-fluid mb-lg-0 mb-md-0 mb-4"
                    alt=""
                    src="/pm.png"
                  />
                )}
                <div className="heading_review">
                  <Link
                    to={`/review_detail/${review.ID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div>
                      <p className="review_property_address">
                        {review?.Address}
                      </p>

                      <p className="property_review_city">{review?.CNAME}</p>
                      <p className="proprty_review_management_type">
                        {review?.Name}
                      </p>
                    </div>
                  </Link>

                  <div>
                    {[...Array(review.rating)].map((_, index) => (
                      <img
                        key={index}
                        src="/gold.png"
                        width={"20px"}
                        className="mr-2 mb-2 img-fluid"
                        alt="Star"
                      />
                    ))}
                  </div>

                  <p style={{ fontSize: "11px" }}>
                    Avg, rating, Click address for more details
                  </p>
                </div>
              </div>
              <h6 className="property_review_text ml-4">
                {review?.Review_text}
              </h6>
              <Link
                    to={`/review_detail/${review.ID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btn btn-sm btn-primary ml-4 " style={{fontSize:"small"}}>See details</button>
                    </Link>
            </div>
          </div>
            </div>
         
        ))
      ) : (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ width: "100%" }}
        >
          <h5
            className="mt-3"
            style={{
              fontFamily: "'Lato',sans-serif",
              fontSize: "16px",
              fontWeight: "600",
              minHeight: "500px",
            }}
          >
            No reviews available
          </h5>
        </div>
      )}
    </div>
  );
};

export default MyBookmarkedReviews;

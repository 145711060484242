import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as solidbookmark } from "@fortawesome/free-solid-svg-icons";
import { faFlag as solidFlag } from "@fortawesome/free-solid-svg-icons";
import { faFlag as regularFlag } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as regularbookmark } from "@fortawesome/free-regular-svg-icons";
import { format } from "timeago.js";
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faStar  } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
const Results = (props) => {
  const [allresults, setallResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [posting, setPosting] = useState(false);
  const [reReviews, setReReviews] = useState({});
  const [display, setDisplay] = useState({});
  const [comments, setComments] = useState([]);
  const commentInputRef = useRef(null);
  const initialRender = useRef(true);
  const ref = useRef(null);
  const [favorites, setFavorites] = useState([]);
  const [reports, setreports] = useState([]);

  const refClose = useRef(null);
  const [credentials, setCredentials] = useState({ name: "", email: "" });
  let navigate = useNavigate();
  const [favoritesLoading, setFavoritesLoading] = useState(true);
  const refReport = useRef(null);
  const refCloseReport = useRef(null);
  const [review_id, setUpdate_id] = useState("");
  const [reportcredentials, setreportCredentials] = useState({
    reason: "",
    details: "",
  });



  const style={
    fontSize:"30px",
    color:"#000"
  }


  function addReview(ID, Address, CNAME, SNAME) {
    const reviewData ={
      "status":"success",
     "data": [
     {
      
      ID,
      Address,
      CNAME,
      SNAME,
      
    }
  ]
  };
  
    
  
    localStorage.removeItem("review_data");
    localStorage.setItem("review_data", JSON.stringify(reviewData));
    navigate("/add_review")
  
  }


  const reportPopup = (id) => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/results");
        } 
      }); 

    } else {
      refReport.current.click();
      setUpdate_id(id);
    }
  };


  const handlereport = async (reviewId) => {
    try {
      setSending(true);

      const response = await fetch(
        `${props.API_URL}/api/report/report_review/${reviewId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            details: reportcredentials.details,
            reason:reportcredentials.reason
          }),
        }
      );
      const json = await response.json();
      setSending(false);
      setreportCredentials({details:"", reason:""})
      refCloseReport.current.click();
      if (json.status === "success") {
          setreports([...reports, reviewId]);
          props.showAlert(json.message, json.status);
        
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };


  const addToFavorites = async (reviewId,reviewText) => {
    try {
      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/review_detail/"+reviewId);
          } 
        }); 

        return;
      }
      const response = await fetch(
        `${props.API_URL}/api/favorite/add_review_bookmark`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            reviewId: reviewId,
            reviewText:reviewText
          }),
        }
      );
      console.log(reviewId);
      const json = await response.json();

      if (json.status === "success") {
        if (!favorites.includes(reviewId)) {
          setFavorites([...favorites, reviewId]);
          console.log("favorites are", favorites);
        } else {
          setFavorites(favorites.filter((id) => id !== reviewId));
        }
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };

  const fetchCommentsForReview = async (reviewId) => {
    try {
      const response = await fetch(
        `${props.API_URL}/api/property_review/fetch_re_review/${reviewId}`
      );
      const data = await response.json();

      if (data.status === "success") {
        setReReviews((prevReReviews) => ({
          ...prevReReviews,
          [reviewId]: data.data,
        }));

        setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [reviewId]: !prevDisplay[reviewId],
        }));
      } else {
        return [];
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
      return [];
    }
  };

  const handleCommentKeyPress = async (e, RID, index, Main_Review) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (!localStorage.getItem("token")) {
        Swal.fire({
          title: "Sign in required",
          text: " In order to perform this action, you will need to create an account or login to your account first",
          imageUrl: "/popup_check.svg",
          imageWidth: 60,
          imageHeight: 60,
          imageAlt: "Custom image",
          showCancelButton: true,
          confirmButtonColor: "#1E81C4",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel",
  
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/login?redirect=/review_detail/"+RID);
          }
        }); 

        return;
      }

      if (comments[index] === "") {
        props.showAlert("Invalid input.", "error");
        return;
      }

      setPosting(true);

      try {
        const response = await fetch(
          `${props.API_URL}/api/property_review/re_review/${RID}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
            body: JSON.stringify({
              Review_text: comments[index],
              Main_Review:Main_Review
            }),
          }
        );

        const json = await response.json();
        setPosting(false);

        if (json.status === "success") {
          setDisplay((prevDisplay) => ({
            ...prevDisplay,
            [RID]: "none",
          }));
         await fetchCommentsForReview(RID)
         setDisplay((prevDisplay) => ({
          ...prevDisplay,
          [RID]: !prevDisplay[RID] ? "block" : "none",
        }));
          
        } else {
          props.showAlert(json.message, "error");
        }

        const updatedComments = [...comments];
        updatedComments[index] = "";
        setComments(updatedComments);
      } catch (error) {
        setPosting(false);
        props.showAlert("Internal Server Error", "error");
      }
    }
  };

  const handleCommentChange = (e, index) => {
    const updatedComments = [...comments];
    updatedComments[index] = e.target.value;
    setComments(updatedComments);
  };

  const popup = () => {
    const authToken = localStorage.getItem("token");
    if (!authToken) {
      Swal.fire({
        title: "Sign in required",
        text: " In order to perform this action, you will need to create an account or login to your account first",
        imageUrl: "/popup_check.svg",
        imageWidth: 60,
        imageHeight: 60,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonColor: "#1E81C4",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",

        confirmButtonText: "OK",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/login?redirect=/results");
        } 
      }); 

    } else {
      ref.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email } = credentials;

    if (name === "" || email === "") {
      props.showAlert("Please Fill fields first.", "warning");
      return;
    }

    setSending(true);

    try {
      const response = await fetch(`${props.API_URL}/api/contact/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          name,
          email,
        }),
      });

      const json = await response.json();
      setSending(false);
      refClose.current.click();

      if (json.status === "success") {
        props.showAlert(json.message, json.status);
      } else {
        props.showAlert(json.message, "error");
      }
    } catch (error) {
      setSending(false);
      props.showAlert("Internal Server Error", "error");
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const reportchange = (e) => {
    setreportCredentials({
      ...reportcredentials,
      [e.target.name]: e.target.value,
    });
  };
  const handleDropdownSelection = (selectedReason) => {
    setreportCredentials({ ...reportcredentials, reason: selectedReason });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (localStorage.getItem("token")) {
          const reportedResponse = await fetch(
            `${props.API_URL}/api/report/fetch_reported_reviews`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem("token"),
              },
            }
          );

          const reportedData = await reportedResponse.json();

          if (reportedData.status === "success") {
            const reviewIds = reportedData.data.map((item) => item.Review_id);
            setreports(reviewIds);
          } else {
            props.showAlert(reportedData.message, "error");
          }
        }

        
        
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
      }
      try {
        if (localStorage.getItem("token")) {
          const favoritesResponse = await fetch(
            `${props.API_URL}/api/favorite/fetch_bookmarked_reviews`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem("token"),
              },
            }
          );

          const favoritesData = await favoritesResponse.json();

          if (favoritesData.status === "success") {
            const reviewIds = favoritesData.data.map((item) => item.Review_id);
            setFavorites(reviewIds);
          } else {
            props.showAlert(favoritesData.message, "error");
          }
        }

        const storedData = localStorage.getItem("results");

        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setallResults(parsedData.data);
          setLoading(false);
        }
      } catch (error) {
        props.showAlert("Internal Server Error", "error");
      } finally {
        setFavoritesLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [allresults]);
  useEffect(() => {
    // Fetch meta data from your API
    fetch(`${props.API_URL}/api/meta/fetch_meta_data`)
    .then(response => response.json())
    .then(data => {
     const metaData = data.data[0];

     if(metaData){
       // Update title tag
       if(metaData.title){
         document.title = metaData.title + '|' + 'Results' || 'Default Title';

       }
         // Update meta description tag
     const metaDescriptionTag = document.querySelector('meta[name="description"]');
     if (metaDescriptionTag) {
       metaDescriptionTag.setAttribute('content', metaData.description || 'Default description');
     }

     // Update meta keywords tag
     const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
     if (metaKeywordsTag) {
       metaKeywordsTag.setAttribute('content', metaData.keywords || 'default, keywords');
     }

     // Update canonical URL tag
     const canonicalLinkTag = document.querySelector('link[rel="canonical"]');
     if (canonicalLinkTag) {
       canonicalLinkTag.setAttribute('href', metaData.conical || 'https://manorlane.co');
     }
     }

    })
    .catch(error => {
      console.error('Error fetching meta data:', error);
    });



   


 }, []);
  return (
    <>
      <div className="search_results_tab pb-5">
        <div className="search_results_top_div ml-1 mr-1 mt-4"  style={{
            borderRadius: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}>
          <div className="row pl-4 pr-2 ">
            <div className="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9 mt-4 mb-2">
              <h4 className="search_results">Search Results</h4>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mt-3 mb-2">
              <img src="/noun_robot.png" width={"80%"} alt="" />
            </div>
          </div>
          <div className="row pl-4 pr-2">
            <div className="col-lg-1 col-md-1 d-lg-block d-md-block d-none  mb-2">
            <FontAwesomeIcon icon={ faStar } style={style} />      
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12  mb-2">
              <p className="search_results_intro1">
                Reviews are displayed by address, property type, average star
                rating, and description. To see more details for each rating,
                click on the address.
              </p>
              <p className="search_results_intro2">
                You can also like, comment, and bookmark any review for future
                reference.
              </p>
            </div>
          </div>
        </div>
        <div className="review-box ml-1 mr-1 mt-4">
          <div className="row ">
            <div className=" col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 d-flex justify-content-center align-items-center mt-3 mb-2 ">
              <button
                className="invite_friend_btn pt-2 pb-2 pl-4 pr-4 "
                onClick={popup}
              >
                <img src="/invite.png" alt="" width={"25vw"} /> +
              </button>
            </div>
            <div className=" col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 mt-2 mb-1 invite_desc">
              <p className="search_results_intro3">
                Know someone who lives here or wants to share something about
                this property? Who doesn’t love getting invited to stuff?
              </p>
            </div>
          </div>
        </div>

        {loading ? (
          <div
            className="spinner-border "
            role="status"
            style={{ marginLeft: "45%" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : Array.isArray(allresults) ? (
          allresults.map((review, index) => (
            <div className="row pl-1 pr-1" key={index}>
              <div className="col-12  mt-3">
                <div className="review-box">
                  <div className="icon">
                    <img
                      className="group-icon img-fluid"
                      alt=""
                      src="/hoa-icon-1.svg"
                    />
                    <div className="heading_review pt-2">
                    <Link to={`/review_detail/${review.RID}`} style={{ textDecoration: 'none' }}>

                      <div className="property_address_div">


                    
                    <p className="review_property_address" >
                        {review?.Address}
                      </p>
                    <p className="property_review_city">{review?.CNAME}</p>

                      <p className="proprty_review_management_type">{review?.Name}</p>

                      </div>
                      </Link>

                      <div>
                        {[...Array(review.rating)].map((_, index) => (
                          <img
                            key={index}
                            src="/gold.png"
                            width={"20px"}
                            className="mr-2 mb-2 img-fluid"
                            alt="Star"
                          />
                        ))}
                      </div>
                      <p style={{ fontSize: "10px" }}>
                        Avg, rating, Click address for more details
                      </p>
                    </div>
                    <FontAwesomeIcon
                    style={{marginTop:"-70px", cursor:"pointer"}}
                      icon={!reports.includes(review.RID)? regularFlag: solidFlag}
                      onClick={() => reports.includes(review.RID) ? null: reportPopup(review.RID)}
                    />
                   
                  </div>
                  <h6 className="property_review_text ml-4">
                    {review?.Review_text} <br />
                  </h6>

                  <div className="row d-flex">
                    <div className="icons col-1" style={{marginLeft:"40px"}}>
                      <FontAwesomeIcon
                        icon={
                          favoritesLoading || !favorites.includes(review.RID)
                            ? regularbookmark
                            : solidbookmark
                        }
                        className="love-icon"
                        onClick={() => addToFavorites(review.RID,review.Review_text)}
                        style={{
                          cursor: "pointer",
                          color: favorites.includes(review.RID)
                            ? "black"
                            : "black",
                        }}
                      />
                    </div>
                      <div className="col-6">

                    <p
                      onClick={() => fetchCommentsForReview(review.RID)}
                      style={{ cursor: "pointer", right: "0" }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faComment} /> Comments
                    </p>
                    </div>
                    <div className="col-3">
                      <button className="btn btn-primary" style={{marginRight:"0px",marginLeft:"auto",float:"inline-end",backgroundColor:"#1E81C4"}}
                      onClick={() => addReview(review.ID,review.Address,review.CNAME,review.SNAME)}
                      >Add review</button>
                    </div>
                    </div>
                    <hr />
                    <div
                      className="ml-4"
                      style={{
                        display: display[review.RID] ? "block" : "none",
                      }}
                    >
                       {Array.isArray(reReviews[review.RID]) ? (
                        reReviews[review.RID].slice(0, 5).map((re_review, index) => (
                          <div  key={index}>
                             <div className="d-flex">
                              <div className="user_profile_comments">
                              <img
                                src={re_review?.profile}
                               
                                alt=""
                              />
                              </div>
                              <div>

                              
                              <b style={{ marginLeft: "10px" }}>
                                {re_review?.username}:
                              </b>
                              <br />
                            
                            <p style={{ marginLeft: "10px" }}>
                              {re_review?.Review_text}
                            </p>
                            <p style={{fontSize:"10px", marginTop:"-16px", paddingLeft:"10px"}}>{format(re_review.Created_at)}</p>


                            </div>
                            </div>
                            
                          </div>                         
                        ))
                       
                      ) 
                     
                      : (
                        <p>No comments</p>
                      )}
                    </div>
                  
                  <form action="" className="mt-1">
                    <input
                      ref={commentInputRef}
                      type="text"
                      className="leave_comment ml-4"
                      placeholder="Leave a comment"
                      required
                      minLength={3}
                      style={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "'Inter',sans-serif",
                        fontSize: "11px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "101.023%",
                      }}
                      value={comments[index] || ""}
                      onChange={(e) => handleCommentChange(e, index)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          const inputValue = e.target.value.trim();
                          if (inputValue) {
                            handleCommentKeyPress(e, review.RID, index ,review.Review_text);
                          }
                        }
                      }}
                    />
                  </form>
                  <div>
                    <small className="ml-3" style={{ color: "green" }}>
                      {posting ? "Posting..." : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No properties available</p>
        )}
      </div>

      <button
        type="button"
        ref={ref}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal5"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModal5"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel5">
                Invite Friend
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    minLength={3}
                    value={credentials.name}
                    onChange={onChange}
                    placeholder="Enter the name of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize:"12px"
        
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="etitle" className="modal_label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                    placeholder="Enter the email address of the person you're inviting"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      background: "#F1F1F1",
                      fontSize:"12px"
        
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refClose}
                className="btn cancel_button_popup"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn submit_button_popup"
              >
                {sending ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        ref={refReport}
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModalreport"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="exampleModalreport"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabelreport"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabelreport">
                Report Review
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="form-group">
                  <label htmlFor="reportReason" className="modal_label">Select a Reason</label>
                  <div className="dropdown">
                    <select
                      className="custom-select"
                      id="reportReasonDropdown"
                      value={reportcredentials.reason}
                      required
                      onChange={(e) => handleDropdownSelection(e.target.value)}
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #E4E4E4",
                        fontSize:"14px"
          
                      }}
                    >
                      <option value="" disabled>
                        Select a reason
                      </option>
                      <option value="fake">Fake review</option>
                      <option value="inappropriate_language">
                        Review contains inappropriate language
                      </option>
                      <option value="sensitive_info">
                        This review contains sensitive information
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="reportDetails" className="modal_label">Details</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="reportDetails"
                    name="details"
                    required
                    minLength={5}
                    value={reportcredentials.details}
                    onChange={reportchange}
                    placeholder="Write Something"
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #E4E4E4",
                      fontSize:"14px"
      
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                ref={refCloseReport}
                className="btn cancel_button_popup"
                data-dismiss="modal"

              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => handlereport(review_id)}
                className="btn submit_button_popup"
              >
                {sending ? "Processing..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Results;

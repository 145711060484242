import "../App.css";
import Sidebar from "./SideBar";
import TopBar from "./TopNavbar";
import RighBar from "./RightSidebar";
import Addreview from "./AddReview";
const AddReviewTab = (props) => {
  return (
    <>
      <TopBar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}
      >
        <div className="row p-0 m-0" style={{ width: "100%" }}>
          <div className="col-lg-3 col-md-3 m-0 p-0" style={{ width: "100%" }}>
            <Sidebar API_URL={props.API_URL}  showAlert={props.showAlert} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 p-0 m-0">
            <Addreview API_URL={props.API_URL} showAlert={props.showAlert}  />
          </div>
          <div className="col-lg-3 col-md-3 ">
            <RighBar API_URL={props.API_URL} showAlert={props.showAlert} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReviewTab;

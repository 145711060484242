import React from 'react';

class MailchimpForm extends React.Component {
  render() {
    const mailchimpFormHTML = `
      
    <div id="mc_embed_shell">
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
      #mc_embed_signup{background:rgb(234, 234, 253); false;clear:left; font:14px Helvetica,Arial,sans-serif;border:1px solid #d4d4d4 }
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
  <form action="https://manorlane.us8.list-manage.com/subscribe/post?u=18039fd6886fc737e2e78d6b7&amp;id=1088ec2927&amp;f_id=00997ae0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll"><h6 style="font-weight:700;padding-top:20px;color:#007bff">Subscribe to our Newsletter</h6>
          <div class="indicates-required" style="color:#007bff" ><span class="asterisk">*</span> indicates required</div>
          <div class="mc-field-group"><label for="mce-EMAIL" style="color:#007bff">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
      <div id="mce-responses" class="clear foot">
          <div class="response" id="mce-error-response" style="display: none;"></div>
          <div class="response" id="mce-success-response" style="display: none;"></div>
      </div>
  <div aria-hidden="true" style="position: absolute; left: -5000px;">
      /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
      <input type="text" name="b_18039fd6886fc737e2e78d6b7_1088ec2927" tabindex="-1" value="">
  </div>
      <div class="optionalParent">
          <div class="clear foot">
              <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" style="background-color:#93278F" value="Subscribe">
              <p style="margin: 0px auto;"><a href="http://eepurl.com/iMI2F6" title="Mailchimp - email marketing made easy and fun"><span style="display: inline-block; background-color: transparent; border-radius: 4px;"><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a></p>
          </div>
      </div>
  </div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>
    `;

    return (
      <div dangerouslySetInnerHTML={{ __html: mailchimpFormHTML }} />
    );
  }
}

export default MailchimpForm;




import React, {useState} from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import Background1 from '../img/1.png';
import Background2 from '../img/2.png';
import { Link } from "react-router-dom";



const RightSidebar = (props) => {
  const [credentials, setCredentials] = useState({ searchItem: null });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  let navigate = useNavigate();
  function generateURL() {
    const baseURL = 'https://manorlane.co/learn/';
    const token = localStorage.getItem("token"); 
    
    if (localStorage.getItem("token")) {
        // If the user is logged in, append the token as a URL parameter
        return `${baseURL}?token=${token}`;
    } else {
        // If the user is not logged in, simply return the base URL without the token parameter
        return baseURL;
    }
}
  const search = async (e) => {
    e.preventDefault();
  
    if (!credentials.searchItem) {
      props.showAlert("Invalid Input", "error");
      return;
    }
  
    try {
      const response = await fetch(
        `${props.API_URL}/api/search/search_property`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            property: credentials.searchItem,
          }),
        }
      );
  
      const json = await response.json();
  
      if (json.status === "success") {
        localStorage.removeItem("results");
        localStorage.setItem("results", JSON.stringify(json));
        console.log(json)
        navigate("/results");
      } else {
        navigate("/no_results");
      }
    } catch (error) {
      props.showAlert("Internal Server Error", "error");
    }
  };
  
  
  return (
    <div
      className="container-fluid pb-1 mt-3 ml-3 d-none d-md-block d-lg-block"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#FFF",
      }}
    >
      <div className="row">
        <div className="row mt-3 ">
          <div className="col-12">
            <div className="search-box ">
              <div className="input-wrapper ml-lg-1">
                
                <input
                  className="search_input pt-4 pb-4 mb-2"
                  type="text"
                  id="input"
                  name="searchItem"
                  minLength={3}
                  required
                  value={credentials.searchItem}
                  onChange={onChange}
                  placeholder="Search properties and reviews"
                  onKeyDown={(e) => {
                    console.log('Event:', e);
                    if (e.key === "Enter") {
                      e.preventDefault();
                      const inputValue = e.target.value.trim();
                      if (inputValue) {
                        search(e);
                      }
                    }
                  }}
                  
                />
                <img className="search-icon" src="/vector2.svg" alt="" onClick={search} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 side-1" style={{ backgroundImage: `url(${Background1})`, backgroundSize: 'cover' }}>
          <a href={generateURL()} target="blank">
          <h5 className="right_sidebar_links" style={{marginTop:"72%"}}>Resource Articles</h5>

          </a>
    
    </div>
      </div>
      <br />
      <div className="row mb-3">
      <div className="col-12 side-2 pb-2" style={{ backgroundImage: `url(${Background2})`, backgroundSize: 'cover' }}>
        <Link to="/communities">
          <h5 className="right_sidebar_links " style={{marginTop:"76%"}}>Start and Join <br /> Community</h5>

        </Link>
    </div>
      </div>
    </div>
  );
};

export default RightSidebar;

import React from 'react'
import TopNavbar from './TopNavbar'
import SideBar from './SideBar'
import ReviewDetail from './ReviewDetail'
import RightSidebar from './RightSidebar'

const ReviewDetailTab = (props) => {
  return (
    <>
      <TopNavbar API_URL={props.API_URL}  showAlert={props.showAlert} />
      <div
        className="container-fluid pt-4 pb-4"
        style={{ backgroundColor: "rgba(217, 217, 217, 0.10)" }}
      >
        <div className="row p-0 m-0" style={{ width: "100%" }}>
          <div className="col-lg-3 col-md-3 m-0 p-0" style={{ width: "100%" }}>
            <SideBar API_URL={props.API_URL} showAlert={props.showAlert} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 p-0 m-0">
            <ReviewDetail API_URL={props.API_URL} showAlert={props.showAlert}  />
          </div>
          <div className="col-lg-3 col-md-3 ">
            <RightSidebar API_URL={props.API_URL} showAlert={props.showAlert} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewDetailTab
